import React from 'react'
import CustomDrawer from '../../../layouts/drawer/CustomDrawer'
import {Helmet} from "react-helmet";
import SelectedCandidatesContent from './SelectedCandidatesContent';

const SelectedCandidates = () => {
  return (
    <>
      <Helmet>
        <title>Onboard Hub | Applicant List</title>
      </Helmet>
      <CustomDrawer content={<SelectedCandidatesContent />} title='Applicant List' />
    </>
  );
};

export default SelectedCandidates;
