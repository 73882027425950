import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import ButtonField from "../../../components/Button";
import Typography from "@mui/material/Typography";
import axios, { AxiosError } from "axios";
import { Applicant } from "./ApplicantContent";
import Dropzone from "react-dropzone";
import cloudLogo from "../../../assets/icons/cloud.png";
import DescriptionIcon from "@mui/icons-material/Description";
import LinearProgress, {
  LinearProgressProps,
} from "@mui/material/LinearProgress";
import dayjs from "dayjs";
import CustomModal from "../../../components/Modal";

interface EditApplicantProps {
  applicant: Applicant | null;
}

interface JobData {
  prfId: string;
  jobId: string;
  jobTitle: string;
}

function LinearProgressWithLabel(
  props: LinearProgressProps & { value: number }
) {
  return (
    <Box sx={{ display: "flex", alignItems: "center", ml: 2, mr: 2 }}>
      <Box sx={{ width: "100%" }}>
        <LinearProgress
          variant="determinate"
          {...props}
          sx={{
            height: 10, // Set the height to 10
            backgroundColor: "#E5E5E5", // Set the background color
            "& .MuiLinearProgress-bar": {
              backgroundColor: "#7901A3", // Set the progress bar color
            },
            "& .MuiLinearProgress-dashed": {
              display: "none", // Hide the dashed background (optional)
            },
            borderRadius: "10px",
            mr: 1,
          }}
        />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary">{`${Math.round(
          props.value
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

export default function JobOfferApplicant({ applicant }: EditApplicantProps) {
  const apiKeyRecruitment = process.env.REACT_APP_RECRUITMENT_API;
  const [formData, setFormData] = useState({
    file: null as File | null,
    applicantId: applicant?.applicantId || "",
    prfId: applicant?.prfId || "",
    jobOfferId: applicant?.jobOfferId || "",
    jobId: applicant?.jobId || "",
    deadline: applicant
      ? dayjs(applicant.deadline).toDate()
      : (null as string | null),
  });

  const [progress, setProgress] = React.useState<number | null>(null);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState({ message: "" });
  const [isLoading, setIsLoading] = useState(false);

  const [jobOfferTriggered, setJobOfferTriggered] = useState(false);

  const handleJobOffer = async () => {
    setJobOfferTriggered(true);
    setProgress(0);
    setIsLoading(true);

    const token = localStorage.getItem("accessToken");
    try {
      const formDataToSend = new FormData();
      formDataToSend.append("file", formData.file!);

      formDataToSend.append("applicantId", formData.applicantId);
      formDataToSend.append("prfId", formData.prfId);
      formDataToSend.append("jobOfferId", formData.jobOfferId);
      formDataToSend.append("jobId", formData.jobId);

      formDataToSend.append(
        "deadline",
        formData.deadline ? String(formData.deadline) : ""
      );
 
      const response = await axios.post(
        `${apiKeyRecruitment}/api/recruitment/send-requirement`,
        formDataToSend,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );
      
      if (response.status === 200) {
        setProgress(100);
        setIsModalOpen(true);
      } else {
        setProgress(null);
      }
    } catch (error) {
      console.log(error);
      if (axios.isAxiosError(error)) {
        const axiosError: AxiosError = error;
        setProgress(100);

        if (axiosError.response) {
          const status = axiosError.response.status;

          if (status === 401) {
            setErrorMessage({
              message: "Unauthorized. Please provide a valid Bearer Token.",
            });
          } else if (status === 404) {
            setErrorMessage({
              message: "User not found.",
            });
          } else if (status === 409) {
            setErrorMessage({
              message:
                "Record already exist. / Job offer is not yet accepted. You cannot proceed to send the requirement.",
            });
          } else if (status === 413) {
            setErrorMessage({
              message: "File uploaded is too large.",
            });
          } else if (status === 415) {
            setErrorMessage({
              message: "Invalid file type. Please upload a PDF file.",
            });
          } else if (status === 422) {
            setErrorMessage({
              message:
                "Unprocessable Entity. Required fields are missing or file-related errors.",
            });
          } else if (status === 500) {
            setErrorMessage({
              message: "Internal Server Error.",
            });
          }
        }
      } else {
        console.error("Internal Server Error", error);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const onDrop = (acceptedFiles: File[]) => {
    const file = acceptedFiles[0];

    setFormData({
      ...formData,
      file: file,
    });
  };

  const handleCancel = () => {
    setFormData({
      ...formData,
      file: null,
    });
  };

  React.useEffect(() => {
    const timer = setInterval(() => {
      if (progress !== null && progress < 100) {
        setProgress((prevProgress) =>
          prevProgress !== null ? prevProgress + 10 : 0
        );
      }
    }, 800);

    return () => {
      clearInterval(timer);
    };
  }, [progress]);

  return (
    <>
      <Dropzone onDrop={onDrop}>
        {({ getRootProps, getInputProps }) => (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              margin: "auto",
            }}
          >
            <Typography
              variant="h4"
              sx={{
                color: "#000",
                fontFamily: 'Montserrat, sans-serif',
                marginBottom: "10px",
                fontWeight: "800",
              }}
            >
              File Upload
            </Typography>
            <Box
              {...getRootProps()}
              sx={{
                width: "446px",
                height: "267px",
                border: "2px dashed #888",
                borderRadius: "10px",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                cursor: "pointer",
                backgroundColor: "#f0e4fc",
                margin: "10px", // Add margin to create space around the entire box
              }}
            >
              <input {...getInputProps()} />
              {/* Image icon inside the drop zone */}
              <img
                src={cloudLogo}
                alt="Cloud Icon"
                style={{ width: "50px", height: "50px", marginBottom: "10px" }}
              />
              <Typography
                sx={{
                  color: "#7901A3",
                  fontFamily: 'Montserrat, sans-serif',
                  marginBottom: "10px", // Add margin to create space between image and text
                }}
              >
                Drag and drop to upload
              </Typography>

              <Box
                sx={{
                  width: "300px",
                  height: "50px",
                  backgroundColor: "#7901A3",
                  borderRadius: "10px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  cursor: "pointer",
                  marginTop: "10px",
                }}
              >
                <Typography
                  sx={{
                    color: "#FFF",
                    fontFamily: 'Montserrat, sans-serif',
                  }}
                >
                  Upload
                </Typography>
              </Box>
            </Box>

            {formData.file && (
              <>
                <Box
                  sx={{
                    width: "446px",
                  }}
                >
                  <Typography
                    sx={{
                      color: "#000",
                      fontFamily: 'Montserrat, sans-serif',
                      fontWeight: "800",
                      textAlign: "left",
                    }}
                  >
                    Uploaded Files
                  </Typography>
                  <Box
                    sx={{
                      width: "446px",
                      height: jobOfferTriggered ? "auto" : "50px", // Adjust height dynamically
                      transition: "height 0.5s", // Add transition for smoother animation
                      backgroundColor: "#E5E5E5",
                      borderRadius: "10px",

                      textAlign: "center", // Center the content inside the box
                    }}
                  >
                    <Box
                      display="flex"
                      alignItems="center"
                      sx={{
                        marginTop: "10px",
                        marginLeft: "10px",
                        paddingRight: "10px",
                        paddingTop: "10px",
                      }}
                    >
                      <DescriptionIcon
                        sx={{ marginRight: 1, color: "#A8A8A8" }}
                      />
                      <Typography
                        sx={{ color: "#000" }}
                      >{`${formData.file.name}`}</Typography>
                      <Box
                        sx={{ marginLeft: "auto", cursor: "pointer" }}
                        onClick={() => handleCancel()}
                      >
                        <Typography
                          sx={{
                            color: "#0094FF",
                            fontFamily: 'Montserrat, sans-serif',
                            fontWeight: "600",
                          }}
                        >
                          Cancel
                        </Typography>
                      </Box>
                    </Box>
                    {jobOfferTriggered && (
                      <Box sx={{ width: "100%", mb: 1, mt: 1 }}>
                        <LinearProgressWithLabel value={progress || 0} />
                      </Box>
                    )}
                  </Box>

                  <Box
                    display="flex"
                    justifyContent="center" // Center the buttons
                    sx={{ margin: "15px" }}
                  >
                    <ButtonField
                      variant="contained"
                      onClick={() => handleJobOffer()}
                      sx={{
                        width: "216px",
                        marginRight: "10px",
                        backgroundColor: "#7901A3",
                        "&:hover": {
                          backgroundColor: "#7901A3",
                        },
                      }}
                    >
                      Submit
                    </ButtonField>
                    <ButtonField
                      variant="outlined"
                      color="secondary"
                      onClick={() => handleCancel()}
                      sx={{ width: "216px" }}
                    >
                      Cancel
                    </ButtonField>
                  </Box>
                </Box>

                {errorMessage.message && (
                  <Typography
                    variant="body2"
                    sx={{
                      fontFamily: 'Montserrat, sans-serif',
                      color: "#d32f2f",
                      ml: 1,
                      mt: 1,
                    }}
                  >
                    {errorMessage.message}
                  </Typography>
                )}
              </>
            )}
          </Box>
        )}
      </Dropzone>
      <CustomModal
        open={isModalOpen}
        onClose={() => {
          setIsModalOpen(false);
          window.location.reload(); // Reload the page
        }}
        title={"Successfully Sent"}
        message={"The applicant's job offer was successfully sent."}
        success={true}
      />
    </>
  );
}
