import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import axios, { AxiosError } from "axios";
import { useNavigate } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import { Helmet } from "react-helmet";
import { styled } from "@mui/system";
import bestOfBoth from "../../assets/images/bestOfBoth.png";
import people from "../../assets/images/people.png";
import awards from "../../assets/images/awards.png";
import Header from "../../components/Header";
import agcLogo from "../../assets/images/agcLogo.png";
import CustomModal from "../../components/Modal";
import backgroundImage from "../../assets/images/bgLogin.png";
import onboardingLogo from "../../assets/images/onBoardingLogo.png";
const ContainerWithHeader = styled("div")`
  position: relative;
`;

const BackgroundContainer = styled(Box)`
  height: 100vh;
  background: url(${backgroundImage}) center/cover no-repeat;
  display: flex;
  align-items: center;
`;

const VerifyOtp: React.FC = () => {
  const apiKey = process.env.REACT_APP_USER_SERVICE_API;
  const emailAdd = localStorage.getItem("emailAddress");
  const navigate = useNavigate();
  const codePattern = /^[0-9]{6}$/;

  const [code, setCode] = useState("");
  // Error Message
  const [codeError, setCodeError] = useState("");
  const [errorMessage, setErrorMessage] = useState({ message: "" });
  //Loader
  const [isLoading, setIsLoading] = useState(false);
  //Timer
  const [remainingTime, setRemainingTime] = useState(300);
  const [showResendCode, setShowResendCode] = useState(false);
  //Modal
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    const timer = setInterval(() => {
      if (remainingTime > 0) {
        setRemainingTime(remainingTime - 1);
      } else {
        clearInterval(timer);
        setShowResendCode(true);
      }
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, [remainingTime]);

  const resetTimer = async () => {
    try {
      const response = await axios.post(
        `${apiKey}/api/user-service/send-code`,
        {
          emailAddress: emailAdd,
        }
      );

      if (response.status === 200) {
        setRemainingTime(300);
        setShowResendCode(false);
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const axiosError: AxiosError = error;

        if (axiosError.response) {
          const status = axiosError.response.status;

          if (status === 404) {
            setErrorMessage({
              message: "User was not found.",
            });
          } else if (status === 422) {
            setErrorMessage({
              message:
                "Unprocessable Entity. If you didn't follow the required fields or added an excess field",
            });
          } else if (status === 500) {
            setErrorMessage({
              message: "Internal Server Error. An unexpected error occurred.",
            });
          } else {
            console.error(
              "Internal Server Error. An unexpected error occurred.",
              axiosError
            );
          }
        }
      } else {
        console.error(
          "Internal Server Error. An unexpected error occurred.:",
          error
        );
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleVerify = async () => {
    setCodeError("");
    setIsLoading(true);

    if (!code) {
      setCodeError("Code is required");
      setIsLoading(false);
      return;
    }

    if (!code.match(codePattern)) {
      setCodeError("Invalid code");
      setIsLoading(false);
      return;
    }

    try {
      const response = await axios.post(
        `${apiKey}/api/user-service/verify-code`,
        {
          emailAddress: emailAdd,
          code: code,
        }
      );

      if (response.status === 200) {
        localStorage.setItem("accessToken", response.data.result.token);

        if (response.data.result.isPasswordSet === "not-set")
          navigate("/change-password", { replace: true });
        else {
          if (response.data.result.userType === "Recruiter") {
            localStorage.setItem(
              "RecruiterType",
              response.data.result.userType
            );
            navigate("/recruiter-dashboard", { replace: true });
          } else if (response.data.result.userType === "Selected Applicant") {
            localStorage.setItem(
              "ApplicantType",
              response.data.result.userType
            );
            navigate("/home", { replace: true });
          } else if (response.data.result.userType === "System Admin") {
            localStorage.setItem(
              "SystemAdminType",
              response.data.result.userType
            );
            // navigate("/system-admin-dashboard", { replace: true });
            navigate("/recruiter-list", { replace: true });
          }
          else if (response.data.result.userType === "RECOPS Head") {
            localStorage.setItem(
              "RecOpsHeadType",
              response.data.result.userType
            );
            // navigate("/system-admin-dashboard", { replace: true });
            navigate("/recops-dashboard", { replace: true });
          }
          else if (response.data.result.userType === "Onboarding Team") {
            localStorage.setItem(
              "OnboardingType",
              response.data.result.userType
            );
            // navigate("/system-admin-dashboard", { replace: true });
            navigate("/assigned-applicant", { replace: true });
          }

        }
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const axiosError: AxiosError = error;

        if (axiosError.response) {
          const status = axiosError.response.status;

          if (status === 401) {
            setErrorMessage({
              message: "Unauthorized. The provided code does not match.",
            });
          } else if (status === 404) {
            setErrorMessage({
              message: "The user was not found.",
            });
          } else if (status === 410) {
            setErrorMessage({
              message: "Gone. The code has expired.",
            });
          } else if (status === 422) {
            setErrorMessage({
              message:
                "Unprocessable Entity. If you didn't follow the required fields or added an excess field",
            });
          } else if (status === 429) {
            setErrorMessage({
              message:
                "Too Many Requests. The maximum code verification attempts exceeded.",
            });
            setIsModalOpen(true);
          } else if (status === 500) {
            setErrorMessage({
              message: "Internal Server Error. An unexpected error occurred.",
            });
          } else {
            console.error(
              "Internal Server Error. An unexpected error occurred:",
              axiosError
            );
          }
        }
      } else {
        console.error(
          "Internal Server Error. An unexpected error occurred.",
          error
        );
      }
    } finally {
      setIsLoading(false);
    }
  };

 return (
    <>
      <Helmet>
        <title>Onboard Hub | Verify OTP</title>
      </Helmet>

      <React.Fragment>
        <CssBaseline />

        <ContainerWithHeader>
          <Header />

          <BackgroundContainer>
            <Grid container sx={{}}>
              {/* Left Column (Login Form) */}
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Paper
                  variant="outlined"
                  sx={{
                    p: { md: 2 },
                    borderRadius: { md: "20px" },
                    boxShadow: 3,
                  }}
                >
                  <Box
                    component="form"
                    sx={{
                      my: { xs: 12, sm: 0, md: 4 },
                      mx: 4,
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                        <img src={onboardingLogo} alt="onboardingLogo"  style={{width: '120px'}}/>

                   <Typography
            component="h2"
            variant="subtitle1"
            sx={{
              fontFamily: "Montserrat, sans-serif",
              color: "#666666",
              fontWeight: "400",
              textAlign: "center",
              marginTop: '5px'
            }}
          >
            You will receive a One-Time Password (OTP) <br /> on your
            registered email address.
          </Typography>
  
            <TextField
              value={code}
              margin="normal"
              required
              fullWidth
              id="email"
              label="Enter the code"
              name="email"
       
              autoFocus
              error={!!codeError}
              helperText={codeError}
              onChange={(e) => {
                const numericValue = e.target.value.replace(/\D/g, "");
                const limitedValue = numericValue.slice(0, 6);
                setCode(limitedValue);
                setCodeError("");
                setErrorMessage({ message: "" });
              }}
            />

            <Grid container>
              <Grid item xs>
                {errorMessage.message && (
                  <Typography
                    variant="body2"
                    sx={{
                      fontFamily: "Montserrat, sans-serif",
                      color: "#d32f2f",
                    }}
                  >
                    {errorMessage.message}
                  </Typography>
                )}
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs>
                {remainingTime > 0 && (
                  <Typography
                    variant="subtitle1"
                    sx={{
                      fontFamily: "Montserrat, sans-serif",
                      color: "#666666",
                      fontWeight: 500,
                    }}
                  >
                    {`Resend code in ${Math.floor(remainingTime / 60)}:${(
                      "0" +
                      (remainingTime % 60)
                    ).slice(-2)}`}
                  </Typography>
                )}
                {showResendCode && (
                  <Typography
                    variant="subtitle1"
                    sx={{
                      fontFamily: "Montserrat, sans-serif",
                      color: "#666666",
                      textAlign: "center",
                    }}
                  >
                    Didn't receive a code?{" "}
                    <span
                      style={{
                        color: "#2074d4",
                        cursor: "pointer", 
                        textDecoration: "underline",
                      }}
                      onClick={resetTimer} 
                    >
                      Resend Code
                    </span>
                  </Typography>
                )}
              </Grid>
            </Grid>
            <Button
              fullWidth
              variant="contained"
              sx={{
                mt: 3,
                mb: 2,
                backgroundColor: "#3D005D",
                color: "#FFF",
                "&:hover": {
                  backgroundColor: "#2A003E",
                },
              }}
              onClick={handleVerify}
              disabled={isLoading} 
            >
              {isLoading ? (
                <CircularProgress size={24} sx={{ color: "#FFF" }} />
              ) : (
                "Verify"
              )}
            </Button>
                  </Box>
                </Paper>
              </Grid>

              <CustomModal
          open={isModalOpen}
          onClose={() => navigate("/")}
          title={"Too Many Requests"}
          message={"The maximum code verification attempts exceeded"}
          success={false}
        />
            </Grid>
          </BackgroundContainer>
        </ContainerWithHeader>
      </React.Fragment>
    </>
  );
};
export default VerifyOtp;
