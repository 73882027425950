import React, { useState } from "react";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import axios, { AxiosError } from "axios";
import { useNavigate } from "react-router-dom";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { IconButton, InputAdornment } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { Helmet } from "react-helmet";
import { styled } from "@mui/system";
import bestOfBoth from "../../assets/images/bestOfBoth.png";
import people from "../../assets/images/people.png";
import awards from "../../assets/images/awards.png";
import Header from "../../components/Header";
import agcLogo from "../../assets/images/agcLogo.png";
import backgroundImage from "../../assets/images/bgLogin.png";
import onboardingLogo from "../../assets/images/onBoardingLogo.png";

const ContainerWithHeader = styled("div")`
  position: relative;

`;

const BackgroundContainer = styled(Box)`
height: 100vh;
  background: url(${backgroundImage}) center/cover no-repeat;
  display: flex;
  align-items: center;
`;
const ChangePassword: React.FC = () => {
  const apiKeyUser = process.env.REACT_APP_USER_SERVICE_API;
  const navigate = useNavigate();

  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("")
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [errorMessage, setErrorMessage] = useState({ message: "" });
  const [passwordError, setPasswordError] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const togglePasswordVisibilityConfirm = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };
  const handleVerify = async () => {
  
    const token = localStorage.getItem('accessToken')
    try {
      const response = await axios.patch(
        `${apiKeyUser}/api/user-service/change-password`,
        {
          password: password,
          confirmPassword: confirmPassword,
        },
        {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
      );
      if (response.status === 200) {
        setErrorMessage({ message: "You are now logged in!" });
        {
        if (response.data.userType === "Recruiter") {
          localStorage.setItem(
            "RecruiterType",
            response.data.userType
          );
          // navigate("/recruiter-dashboard", { replace: true });
          navigate("/applicant-list", { replace: true });
        } else if (response.data.userType === "Selected Applicant") {
          localStorage.setItem(
            "ApplicantType",
            response.data.userType
          );
          // navigate("/applicant-dashboard", { replace: true });
          navigate("/home", { replace: true });
        } else if (response.data.userType === "System Admin") {
          localStorage.setItem(
            "SystemAdminType",
            response.data.userType
          );
          // navigate("/system-admin-dashboard", { replace: true });
          navigate("/recruiter-list", { replace: true });
        }
        else if (response.data.userType === "RECOPS Head") {
          localStorage.setItem(
            "RecOpsHeadType",
            response.data.userType
          );
          // navigate("/system-admin-dashboard", { replace: true });
          navigate("/requirement-list", { replace: true });
        }
        else if (response.data.userType === "Onboarding Team") {
          localStorage.setItem(
            "OnboardingType",
            response.data.userType
          );
          navigate("/assigned-applicant", { replace: true });
        }
      }
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const axiosError: AxiosError = error;

        if (axiosError.response) {
          const status = axiosError.response.status;

          if (status === 401) {
            setErrorMessage({
              message: "Unauthorized. Please provide a valid Bearer Token.",
            });
          } else if (status === 422) {
            setErrorMessage({
              message:
                "Passwords do not match.",
            });
          } else if (status === 500) {
            setErrorMessage({
              message: "Internal Server Error. An unexpected error occurred.",
            });
          } 
        }
      } else {
        console.error(
          "Internal Server Error. An unexpected error occurred.",
          error
        );
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Helmet>
        <title>Onboard Hub | Change Password</title>
      </Helmet>

      <React.Fragment>
        <CssBaseline />

        <ContainerWithHeader>
          <Header />

          <BackgroundContainer>
            <Grid container sx={{ height: "100%" }}>
              {/* Left Column (Login Form) */}
              <Grid
                item
                xs={12}
                sm={6}
                md={6}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Paper
                  variant="outlined"
                  sx={{
                    p: { md: 2 },
                    borderRadius: { md: "20px" },
                    boxShadow: 3,
                  }}
                >
                  <Box
                    component="form"
                    sx={{
                      my: { xs: 12, sm: 0, md: 4 },
                      mx: 4,
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                            <img src={onboardingLogo} alt="onboardingLogo"  style={{width: '120px'}}/>
                    <Typography
            component="h2"
            variant="subtitle1"
            sx={{
              fontFamily: "Montserrat, sans-serif",
              color: "#666666",
              fontWeight: "400",
              textAlign: "center",
            }}
          >
            You will receive the Password on your <br/> registered email address.
          </Typography>

          <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type={showPassword ? "text" : "password"}
              value={password}
              onChange={(e) => {
                setPassword(e.target.value);
                setPasswordError("");
                setErrorMessage({ message: "" });
              }}
              id="password"
              error={!!passwordError}
              helperText={passwordError}
              className="custom-text-input"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={togglePasswordVisibility} edge="end">
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              inputProps={{ maxLength: 64 }}
            />
                  <TextField
              margin="normal"
              required
              fullWidth
              name="Confirm Password"
              label="Confirm Password"
              type={showConfirmPassword ? "text" : "password"}
              value={confirmPassword}
              onChange={(e) => {
                setConfirmPassword(e.target.value);
                setConfirmPasswordError("");
                setErrorMessage({ message: "" });
              }}
              id="confirmPassword"
  
              error={!!confirmPasswordError}
              helperText={confirmPasswordError}
              className="custom-text-input"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={togglePasswordVisibilityConfirm} edge="end">
                      {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              inputProps={{ maxLength: 64 }}
            />
            <Grid container>
              <Grid item xs>
                {errorMessage.message && (
                  <Typography
                    variant="body2"
                    sx={{
                      fontFamily: "Montserrat, sans-serif",
                      color: "#d32f2f",
                    }}
                  >
                    {errorMessage.message}
                  </Typography>
                )}
              </Grid>
            </Grid>
            <Button
              fullWidth
              variant="contained"
              sx={{
                mt: 3,
                mb: 2,
                backgroundColor: "#3D005D",
                color: "#FFF",
                "&:hover": {
                  backgroundColor: "#2A003E",
                },
              }}
              onClick={handleVerify}
              disabled={isLoading} // Disable the button while loading
            >
              {isLoading ? (
                <CircularProgress size={24} sx={{ color: "#FFF" }}  />
              ) : (
                "Submit"
              )}
            </Button>

            <Typography
                    variant="subtitle1"
                    sx={{
                      fontFamily: "Montserrat, sans-serif",
                      color: "#666666",
                      textAlign: "center",
                    }}
                  >
                    Already have an account? Click {' '}
                    <span
                      onClick={() => navigate("/")}
                      style={{
                        color: "#2074d4",
                        cursor: "pointer", 
                        textDecoration: "underline",
                        fontFamily: "Montserrat, sans-serif",
                      }}
     
                    >
                    Here
                    </span>
                    <span
                      style={{
                        fontFamily: "Montserrat, sans-serif",
                      color: "#666666",
                      textAlign: "center",
                      }}
     
                    >
                     {' '}to Sign In!
                    </span>
                  </Typography>
    
                 
                  </Box>
                </Paper>
              </Grid>

         
            </Grid>
          </BackgroundContainer>
        </ContainerWithHeader>
      </React.Fragment>
    </>
  );
};
export default ChangePassword;
