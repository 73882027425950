import React from 'react'
import CustomDrawer from '../../../layouts/drawer/CustomDrawer'
import RequirementDetailsContent from './RequirementDetailsContent'
import {Helmet} from "react-helmet";

const RequirementDetails: React.FC = () => {

  return (
    <>
      <Helmet>
        <title>Onboard Hub | Applicant Requirement Progress</title>
      </Helmet>
      <CustomDrawer content={<RequirementDetailsContent  />} title='Applicant Requirement Progress' />
    </>
  );
};

export default RequirementDetails;
