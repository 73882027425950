import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import TextInput from "../../../components/TextInput";
import InputAdornment from "@mui/material/InputAdornment";
import ButtonField from "../../../components/Button";
import Typography from "@mui/material/Typography";
import axios, { AxiosError } from "axios";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import dayjs from "dayjs";
import CircularProgress from "@mui/material/CircularProgress";
import CustomModal from "../../../components/Modal";
import { v4 as uuidv4 } from "uuid";

interface DeclineOfferProps {
  jobOfferId: string | null;
}

export default function DeclineOffer({ jobOfferId }: DeclineOfferProps) {
  const apiKey = process.env.REACT_APP_RECRUITMENT_API;
  const [withdrawStatusList, setWithdrawStatusList] = useState<string[]>([]);
  const [formData, setFormData] = useState({
    reason: "",
    jobOfferDate: null as string | null,
  });

  const [formErrors, setFormErrors] = useState({
    reason: "",
    jobOfferDate: "",
  });

  const [errorMessage, setErrorMessage] = useState({ message: "" });
  const [isLoading, setIsLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleSelectChange = (id: string) => (e: SelectChangeEvent<string>) => {
    const value = e.target.value;

    setFormData((prevData) => ({ ...prevData, [id]: value }));
    setFormErrors((prevErrors) => ({ ...prevErrors, [id]: "" }));
    setIsLoading(false);
  };

  const handleSubmit = async () => {

    const requiredFields: (keyof typeof formData)[] = [
        "reason",
      ];
  
      const emptyFields: string[] = [];
      for (const field of requiredFields) {
        if (!formData[field]) {
          emptyFields.push(field);
        }
      }
  
      if (emptyFields.length > 0) {
        const errorMessages: Record<string, string> = {};
        emptyFields.forEach((field) => {
          errorMessages[field] = "This field is required";
        });
        setFormErrors((prevErrors) => ({ ...prevErrors, ...errorMessages }));
        setIsLoading(false);
        return;
      }

    const token = localStorage.getItem("accessToken");
    try {
      const response = await axios.patch(
        `${apiKey}/api/applicant/respond-offer`,
        {
          jobOfferId: jobOfferId,
          offerStatus: "Declined",
          withdrawReason: formData.reason,
          nextJobOfferDate: formData.jobOfferDate,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        window.location.reload();
      }
    } catch (error) {
      console.error(error);
      if (axios.isAxiosError(error)) {
        const axiosError: AxiosError = error;

        if (axiosError.response) {
          const status = axiosError.response.status;
          if (status === 401) {
            setErrorMessage({
              message: "Unauthorized. Please provide a valid Bearer Token.",
            });
          } else if (status === 410) {
            setErrorMessage({
            message: "Job offer expired. Please contact the recruiter for a new offer."
            });
          } else if (status === 422) {
            setErrorMessage({
            message: "Unprocessable Entity. \"addressee\" is not allowed."
            });
          } else if (status === 500) {
            setErrorMessage({
              message: "Internal Server Error. An unexpected error occurred.",
            });
          }
        }
      } else {
        console.error(
          "Internal Server Error. An unexpected error occurred.",
          error
        );
      }
    } finally {
      //   setLoadingState(false);
    }
  };
  useEffect(() => {
    const fetchWithdrawStatus = async () => {
      const token = localStorage.getItem("accessToken");
      try {
        const response = await axios.get(
          `${apiKey}/api/recruitment/withdraw-status`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        const withdrawSegments = response.data.result.map(
          (segment: any) => segment.withdrawStatus
        );
        setWithdrawStatusList(withdrawSegments);
      } catch (error) {
        console.error("Error fetching user information:", error);
      }
    };
    fetchWithdrawStatus();
  }, []);

  return (
    <Box
      component="form"
      sx={{
        "& .MuiTextField-root": {},
      }}
      noValidate
      autoComplete="off"
    >
      <Grid item xs={12} md={6}>
        <FormControl variant="outlined" sx={{ mt: 2, width: "100%" }}>
          <InputLabel id="reason-label">Reason</InputLabel>
          <Select
            label="Reason"
            labelId="reason-label"
            id="outlined-required"
            value={formData.reason}
            onChange={handleSelectChange("reason")}
            error={!!formErrors.reason}
            inputProps={{
              maxLength: 256,
            }}
            fullWidth
          >
            {withdrawStatusList.map((segment) => (
              <MenuItem key={segment} value={segment}>
                {segment}
              </MenuItem>
            ))}
          </Select>
          <Typography
            variant="caption"
            sx={{
              color: "#d32f2f",
              ml: 2,
              mt: 0.3,
            }}
          >
            {formErrors.reason}
          </Typography>
        </FormControl>
      </Grid>
      <Grid item xs={12} md={4}>
        <Typography sx={{mt: 2, fontFamily: 'Montserrat, sans-serif',
              color: "#000",
            }}>
        Would you like to talk about your proposal with me? <span style={{fontSize: '14px',color: "#666666"}}>(Optional)</span>
        </Typography>
          <FormControl variant="outlined" sx={{ mt: 2, width: "100%" }}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="Next Availability"
                value={formData.jobOfferDate}
                slotProps={{ textField: { variant: "outlined" } }}
                onChange={(newValue) => {
                  if (newValue !== null && newValue !== "") {
                    const formattedDate = dayjs(newValue).format("YYYY-MM-DD");
                    setFormData({ ...formData, jobOfferDate: formattedDate });
                  } else {
                    setFormData({ ...formData, jobOfferDate: newValue });
                  }
                }}
              />
            </LocalizationProvider>
            <Typography
              variant="caption"
              sx={{
                color: "#d32f2f",
                ml: 2,
                mt: 0.2,
              }}
            >
              {formErrors.jobOfferDate}
            </Typography>
          </FormControl>
        </Grid>
  

      {errorMessage.message && (
        <Typography
          variant="body2"
          sx={{
            fontFamily: 'Montserrat, sans-serif',
            color: "#d32f2f",
            ml: 1,
            mt: 1,
          }}
        >
          {errorMessage.message}
        </Typography>
      )}
      <ButtonField
        variant="contained"
        sx={{
          width: "100%",
          mt: 3,
          mb: 2,
          backgroundColor: isLoading ? "#666666" : "#7901A3",
          "&:hover": {
            backgroundColor: "#7901A3",
          },
        }}
        disabled={isLoading}
        onClick={handleSubmit}
      >
        {isLoading ? (
          <CircularProgress size={25} style={{ color: "white" }} />
        ) : (
          "Submit"
        )}
      </ButtonField>
      <CustomModal
        open={isModalOpen}
        onClose={() => {
          setIsModalOpen(false);
          window.location.reload(); // Reload the page
        }}
        title={"Successfully Updated"}
        message={"The Recruiter will be notify your response."}
        success={true}
      />
    </Box>
  );
}
