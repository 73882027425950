import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import TextInput from "../../../components/TextInput";
import InputAdornment from "@mui/material/InputAdornment";
import ButtonField from "../../../components/Button";
import Typography from "@mui/material/Typography";
import axios, { AxiosError } from "axios";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import dayjs from "dayjs";
import CircularProgress from "@mui/material/CircularProgress";
import CustomModal from "../../../components/Modal";
import { Applicant } from './ApplicantContent';


interface EditApplicantProps {
  applicant: Applicant | null;
}

interface JobData {
  prfId: string;
  jobId: string;
  jobTitle: string;
}

export default function EditApplicant({ applicant }: EditApplicantProps) {
  const apiKey = process.env.REACT_APP_USER_SERVICE_API;
  const apiKeyRecruitment = process.env.REACT_APP_RECRUITMENT_API;

  const [jobTitlesList, setJobTitlesList] = useState<string[]>([]);
  const [jobDataList, setJobDataList] = useState<JobData[]>([]);
  const [applicantStatusList, setApplicantStatusList] = useState<string[]>([]);

  const [formData, setFormData] = useState({
    firstName: applicant?.firstName || "",
    middleName: applicant?.middleName || "",
    lastName: applicant?.lastName || "",
    emailAddress: applicant?.applicantEmail || "",
    contactNumber: applicant?.contactNumber || "",
    startDate: applicant ? dayjs(applicant.startDate).toDate() : null as string | null,
    deadline: applicant ? dayjs(applicant.deadline).toDate() : null as string | null,
    subsidiary: applicant?.subsidiary || "",
    talentSegment: applicant?.talentSegment || "",
    applicantId: applicant?.applicantId || "",
    applicantStatus: applicant?.applicantStatus || "",
    prfId: applicant?.prfId || "",
    jobId: applicant?.jobId || "",
    jobTitle: applicant?.jobTitle || "",
    withdrawStatus: applicant?.withdrawStatus || "",
    jobOfferId: applicant?.jobOfferId || "",
  });
  

  const [formErrors, setFormErrors] = useState({
    firstName: "",
    middleName: "",
    lastName: "",
    emailAddress: "",
    contactNumber: "",
    jobId: "",
    jobTitle: "",
    startDate: "",
    subsidiary: "",
    talentSegment: "",
    applicantStatus: "",
    prfId: "",
    deadline: "",
  });

  const [errorMessage, setErrorMessage] = useState({ message: "" });
  const [isLoading, setIsLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  
  
  
  useEffect(() => { 
    fetchReferenceList();
    applicantStatusMap();

    if (formData.contactNumber.startsWith("+63")) {
      const updatedContactNumber = formData.contactNumber.substring(3);
      setFormData((prevData) => ({ ...prevData, contactNumber: updatedContactNumber }));
    }
  }, [formData.contactNumber]);
  
  const fetchReferenceList = async () => {
    try {
      const response = await axios.post<JobData[]>(
       `${apiKeyRecruitment}/api/recruitment/ref-list`
      );
  
      setJobDataList(response.data);
  
      const jobTitles = response.data.map((item: JobData) => `${item.jobTitle}`);
      setJobTitlesList(jobTitles);
    } catch (error) {
      console.error('Error fetching reference list:', error);
    }
  };

  const applicantStatusMap = async () => {
    const token = localStorage.getItem("accessToken");
    try {
      const response = await axios.get( 
        `${apiKeyRecruitment}/api/recruitment/applicant-status`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
        
      const applicantStatus = response.data.result.map((segment: any) => segment.applicantStatus);
      setApplicantStatusList(applicantStatus);


    } catch (error) {
      console.error("Error fetching user information:", error);
    }
  }
  const setJobIdAndTitle = (selectedJobTitle: string) => {
    const selectedJobData = jobDataList.find((jobData) => jobData.jobTitle === selectedJobTitle);
  
    if (selectedJobData) {
      setFormData((prevData) => ({
        ...prevData,
        jobId: selectedJobData.jobId,
        jobTitle: selectedJobTitle,
      }));
    }
  };
  
    
  const handleSelectChange = (id: string) => (e: SelectChangeEvent<string>) => {
    const value = e.target.value;
  
    if (id === "jobTitle") {
      setJobIdAndTitle(value);
    } else {
      setFormData((prevData) => ({ ...prevData, [id]: value }));
    }
  
    setFormErrors((prevErrors) => ({ ...prevErrors, [id]: "" }));
    setIsLoading(false);
  };
  
  
  
  const handleInputChange =
    (id: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
      let value = e.target.value;

      if (["firstName", "middleName", "lastName"].includes(id)) {
    const nameRegex = /^[A-Za-z ]+$/
        if (value !== "" && !nameRegex.test(value)) {
          setFormErrors((prevErrors) => ({
            ...prevErrors,
            [id]: "Only letters are allowed",
          }));
          return;
        }
      }

      if (id === "contactNumber") {
        value = value.replace(/\D/g, "").replace(/^63/, "");
        setFormData((prevData) => ({ ...prevData, [id]: value }));
      }

      setFormData((prevData) => ({ ...prevData, [id]: value }));
      setFormErrors((prevErrors) => ({ ...prevErrors, [id]: "" }));
      setIsLoading(false);
    };

  const handleAddApplicant = async () => {
    setIsLoading(true);

    const requiredFields: (keyof typeof formData)[] = [
      "firstName",
      "lastName",
      "emailAddress",
      "contactNumber",
      "startDate",
      "subsidiary",
      "talentSegment",
      "deadline",
      // "applicantStatus"
    ];

    const emptyFields: string[] = [];
    for (const field of requiredFields) {
      if (!formData[field]) {
        emptyFields.push(field);
      }
    }

    if (emptyFields.length > 0) {
      const errorMessages: Record<string, string> = {};
      emptyFields.forEach((field) => {
        errorMessages[field] = "This field is required";
      });
      setFormErrors((prevErrors) => ({ ...prevErrors, ...errorMessages }));
      setIsLoading(false);
      return;
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const contactNumberRegex = /^\d{10}$/;

    if (!emailRegex.test(formData.emailAddress)) {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        emailAddress: "Invalid email format",
      }));
      setIsLoading(false);
      return;
    }

    if (!contactNumberRegex.test(formData.contactNumber)) {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        contactNumber: "Invalid contact number format",
      }));
      setIsLoading(false);
      return;
    }

    const token = localStorage.getItem("accessToken");
    const formattedContactNumber = `+63${formData.contactNumber}`;

    try {
      // First API request
      const firstApiRequestData = {
        applicantId: formData.applicantId,
        firstName: formData.firstName,
        middleName: formData.middleName.trim() !== "" ? formData.middleName : undefined,
        lastName: formData.lastName,
        emailAddress: formData.emailAddress,
        contactNumber: formattedContactNumber,
      };

      const response = await axios.patch(
        `${apiKey}/api/user-service/update-applicant`,
        firstApiRequestData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        // Second API request
        const secondApiRequestData = {
          jobOfferId: formData.jobOfferId,
          // jobId: formData.jobId,
          applicantId: formData.applicantId,
          prfId: formData.prfId,
          jobTitle: formData.jobTitle,
          deadline: formData.deadline,
          talentSegment: formData.talentSegment,
          subsidiary: formData.subsidiary,
          applicantEmail: formData.emailAddress,
          firstName: formData.firstName,
          middleName: formData.middleName.trim() !== "" ? formData.middleName : undefined,
          lastName: formData.lastName,
          withdrawStatus: formData.withdrawStatus.trim() !== "" ? formData.withdrawStatus : undefined,
          startDate: formData.startDate,
          applicantStatus: formData.applicantStatus,
          contactNumber: formattedContactNumber,
        };

        const offerJobResponse = await axios.patch(
          `${apiKeyRecruitment}/api/recruitment/update-offer-job`,
          secondApiRequestData,
          {
            headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
          }
        );

        if (offerJobResponse.status === 200) {
          setIsModalOpen(true);
        }
      }
    } catch (error) {
      // console.log(error);
      if (axios.isAxiosError(error)) {
        const axiosError: AxiosError = error;

        if (axiosError.response) {
          const status = axiosError.response.status;

          if (status === 401) {
            setErrorMessage({
              message: "Unauthorized. Please provide a valid Bearer Token.",
            });
          } else if (status === 409) {
            setErrorMessage({
              message: "User already exists with the provided email address.",
            });
          } else if (status === 422) {
            setErrorMessage({
              message: 'Unprocessable Entity. "addressee" is not allowed.',
            });
          } else if (status === 500) {
            setErrorMessage({
              message: "Internal Server Error.",
            });
          }
        }
      } else {
        console.error("Internal Server Error", error);
      }
    } finally {
      setIsLoading(false);
    }
  };

  
  return (
    <Box
      component="form"
      sx={{
        "& .MuiTextField-root": {},
      }}
      noValidate
      autoComplete="off"
    >
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <TextInput
       
            id="outlined-required"
            label="PRF ID"
            value={formData.prfId}
            onChange={handleInputChange("prfId")}
            error={!!formErrors.prfId}
            helperText={formErrors.prfId}
            inputProps={{
              maxLength: 256,
            }}
            fullWidth
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
          <TextInput
            required
            id="outlined-required"
            label="First Name"
            value={formData.firstName}
            onChange={handleInputChange("firstName")}
            error={!!formErrors.firstName}
            helperText={formErrors.firstName}
            inputProps={{
              maxLength: 256,
            }}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextInput
            id="outlined"
            label="Middle Name"
            value={formData.middleName}
            onChange={handleInputChange("middleName")}
            error={!!formErrors.middleName}
            helperText={formErrors.middleName}
            inputProps={{
              maxLength: 256,
            }}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextInput
            required
            id="outlined-required"
            label="Last Name"
            value={formData.lastName}
            onChange={handleInputChange("lastName")}
            error={!!formErrors.lastName}
            helperText={formErrors.lastName}
            inputProps={{
              maxLength: 256,
            }}
            fullWidth
          />
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <TextInput
            required
            id="outlined-required"
            label="Email Address"
            value={formData.emailAddress}
            onChange={handleInputChange("emailAddress")}
            error={!!formErrors.emailAddress}
            helperText={formErrors.emailAddress}
            inputProps={{
              maxLength: 256,
            }}
            fullWidth

          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextInput
            required
            id="outlined-required"
            label="Contact Number"
            value={formData.contactNumber}
            onChange={handleInputChange("contactNumber")}
            error={!!formErrors.contactNumber}
            helperText={formErrors.contactNumber}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">+63</InputAdornment>
              ),
            }}
            inputProps={{
              maxLength: 10,
            }}
            fullWidth
          />
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
        <TextInput
            required
            id="outlined-required"
            label="Position"
            value={formData.jobTitle}
            onChange={handleInputChange("jobTitle")}
            error={!!formErrors.jobTitle}
            helperText={formErrors.jobTitle}
            inputProps={{
              maxLength: 256,
            }}
            fullWidth
          />
          {/* <FormControl variant="outlined" sx={{ mt: 2, width: "100%" }}>
            <InputLabel id="jobTitle-label">Position</InputLabel>
            <Select
              label="Position"
              labelId="jobTitle-label"
              id="outlined-required"
              value={formData.jobTitle}
              onChange={handleSelectChange("jobTitle")}
              error={!!formErrors.jobTitle}
              inputProps={{
                maxLength: 256,
              }}
              fullWidth
            >
              {jobTitlesList.map((title) => (
                <MenuItem key={title} value={title}>
                  {title}
                </MenuItem>
              ))}
            </Select>
            <Typography
              variant="caption"
              sx={{
                color: "#d32f2f",
                ml: 2,
                mt: 0.3,
              }}
            >
              {formErrors.jobTitle}
            </Typography>
          </FormControl> */}
        </Grid>
        <Grid item xs={12} md={4}>
          <FormControl variant="outlined" sx={{ width: "100%", mt: 2 }}>
            <InputLabel id="subsidiary-label">Subsidiary</InputLabel>
            <Select
              label="Subsidiary"
              labelId="subsidiary-label"
              id="outlined-required"
              value={formData.subsidiary}
              onChange={handleSelectChange("subsidiary")}
              error={!!formErrors.subsidiary}
              inputProps={{
                maxLength: 256,
              }}
              fullWidth
            >
              <MenuItem value="asticom">ASTICOM</MenuItem>
              <MenuItem value="absi">ABSI</MenuItem>
              <MenuItem value="finsi">FINSI</MenuItem>
              <MenuItem value="hcx">HCX</MenuItem>
              <MenuItem value="acquiro">ACQUIRO</MenuItem>
            </Select>
            <Typography
              variant="caption"
              sx={{
                color: "#d32f2f",
                ml: 2,
                mt: 0.3,
              }}
            >
              {formErrors.subsidiary}
            </Typography>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={4}>
          <FormControl variant="outlined" sx={{ width: "100%", mt: 2 }}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="Start Date"
                value={dayjs(formData.startDate)}
                slotProps={{ textField: { variant: "outlined" } }}
                onChange={(newValue) => {
                  if (dayjs.isDayjs(newValue)) {
                    const formattedDate = dayjs(newValue).format("YYYY-MM-DD");
                    setFormData({ ...formData, startDate: formattedDate });
                  } else {
                    setFormData({ ...formData, startDate: null });
                  }
                }}
              />
            </LocalizationProvider>
            <Typography
              variant="caption"
              sx={{
                color: "#d32f2f",
                ml: 2,
                mt: 0.2,
              }}
            >
              {formErrors.startDate}
            </Typography>
          </FormControl>
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <FormControl variant="outlined" sx={{ width: "100%", mt: 2 }}>
            <InputLabel id="subsidiary-label">Applicant Status</InputLabel>
            <Select
              label="Applicant Status"
              labelId="subsidiary-label"
              id="outlined-required"
              value={formData.applicantStatus}
              onChange={handleSelectChange("applicantStatus")}
              error={!!formErrors.applicantStatus}
              inputProps={{
                maxLength: 256,
              }}
              fullWidth
            >
    {applicantStatusList.map((segment) => (
          <MenuItem key={segment} value={segment}>
            {segment}
          </MenuItem>
        ))}
            </Select>
            <Typography
              variant="caption"
              sx={{
                color: "#d32f2f",
                ml: 2,
                mt: 0.3,
              }}
            >
              {formErrors.applicantStatus}
            </Typography>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={6}>
          <TextInput
            required
            id="outlined-required"
            label="Talent Segment"
            value={formData.talentSegment}
            onChange={handleInputChange("talentSegment")}
            error={!!formErrors.talentSegment}
            helperText={formErrors.talentSegment}
            inputProps={{
              maxLength: 256,
            }}
            fullWidth
          />
        </Grid>
       
      </Grid>
      <Grid container spacing={2}>
      <Grid item xs={12} md={6}>
          <FormControl variant="outlined" sx={{ mt: 2, width: "100%" }}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="Deadline"
                value={dayjs(formData.deadline)}
                slotProps={{ textField: { variant: "outlined" } }}
                onChange={(newValue) => {
                  if (dayjs.isDayjs(newValue)) {
                    const formattedDate = dayjs(newValue).format("YYYY-MM-DD");
                    setFormData({ ...formData, deadline: formattedDate });
                  } else {
                    setFormData({ ...formData, deadline: null });
                  }
                }}
              />
            </LocalizationProvider>
            <Typography
              variant="caption"
              sx={{
                color: "#d32f2f",
                ml: 2,
                mt: 0.2,
              }}
            >
              {formErrors.deadline}
            </Typography>
          </FormControl>
        </Grid>
        </Grid>
      {errorMessage.message && (
        <Typography
          variant="body2"
          sx={{
            fontFamily: "Montserrat, sans-serif",
            color: "#d32f2f",
            ml: 1,
            mt: 1,
          }}
        >
          {errorMessage.message}
        </Typography>
      )}
      <ButtonField
        variant="contained"
        sx={{
          width: "30ch",
          mt: 3,
          mb: 2,
          backgroundColor: isLoading ? "#666666" : '#7901A3',
          "&:hover": {
            backgroundColor: "#7901A3",
          }
        }}

        onClick={handleAddApplicant}
        disabled={isLoading}
      >
        {isLoading ? (
          <CircularProgress size={25} style={{ color: "white" }} />
        ) : (
          "Update"
        )}
      </ButtonField>
      <CustomModal
        open={isModalOpen}
        onClose={() => {
          setIsModalOpen(false);
          window.location.reload(); // Reload the page
        }}
        title={"Successfully Updated"}
        message={"The applicant's information has been successfully updated."}
        success={true}
      />
    </Box>
  );
}