import React from 'react'
import CustomDrawer from '../../../layouts/drawer/CustomDrawer'
import ApplicantManagementContent from './ApplicantContent'
import {Helmet} from "react-helmet";

const ApplicantList = () => {
  return (
    <>
      <Helmet>
        <title>Onboard Hub | Applicant List</title>
      </Helmet>
      <CustomDrawer content={<ApplicantManagementContent />} title='Applicant List' />
    </>
  );
};

export default ApplicantList;
