import React from 'react';
import { Container, Typography, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const ComingSoon: React.FC = () => {
  const navigate = useNavigate();

  const handleBack = () => {
    // Go back to the previous page
    navigate(-1);
  };

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
      }}
    >
      <Container sx={{ textAlign: 'center' }}>
        <Typography variant="h2" component="div" gutterBottom>
          Page Not Found
        </Typography>
        <Typography variant="h5" gutterBottom>
        The page you are looking for does not exist.
        </Typography>

        <Button variant="contained" color="primary" onClick={handleBack} sx={{mt: 2, width: "25ch"}}>
          Back
        </Button>
      </Container>
    </div>
  );
};

export default ComingSoon;
