// CustomDrawer.tsx
import * as React from "react";
import { useEffect, useState } from "react";
import { styled, useTheme, Theme, CSSObject } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import DashboardIcon from "@mui/icons-material/Dashboard";
import PeopleIcon from "@mui/icons-material/People";
import { Link } from "react-router-dom";
import AccountMenu from "./AccountMenu";
import HomeIcon from "@mui/icons-material/Home";
import AssignmentIcon from "@mui/icons-material/Assignment";
import WorkHistoryIcon from "@mui/icons-material/WorkHistory";
import backgroundDrawer from "../../assets/images/bgDrawer.png";
import onBoardingLogo from "../../assets/images/onBoardingLogo.png";
import agcLogo from "../../assets/images/agcLogo.png";
import ArticleOutlinedIcon from "@mui/icons-material/ArticleOutlined";
import TaskOutlinedIcon from "@mui/icons-material/TaskOutlined";
import axios from "axios";
import bodyImage from "../../assets/images/bgBody.png";
import agcLogoColored from "../../assets/images/agcLogoColored.png";
import PersonIcon from "@mui/icons-material/Person";

const drawerWidth = 240;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",

  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  backgroundColor: "#FFF", // Set the background color to white
  boxShadow: "none", // Remove the box shadow
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

interface CustomDrawerProps {
  content: React.ReactNode;
  title: string;
}
interface UserProfile {
  firstName: string;
  emailAddress: string;
}

const CustomDrawer: React.FC<CustomDrawerProps> = ({ content, title }) => {
  const apiKeyUser = process.env.REACT_APP_USER_SERVICE_API;
  const theme = useTheme();
  const [profileDetails, setProfileDetails] = useState<UserProfile | null>(
    null
  );

  const [open, setOpen] = React.useState(() => {
    const storedOpen = localStorage.getItem("drawerOpen");
    return storedOpen ? JSON.parse(storedOpen) : true;
  });

  React.useEffect(() => {
    localStorage.setItem("drawerOpen", JSON.stringify(open));

    fetchUserProfile();
  }, [open]);

  const fetchUserProfile = async () => {
    try {
      const token = localStorage.getItem("accessToken");

      const response = await axios.get(
        `${apiKeyUser}/api/user-service/my-profile`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const userProfile = response.data.result;
      setProfileDetails(userProfile);
    } catch (error) {
      console.error("Error fetching user profile:", error);
      // Handle errors here
    }
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  // Retrieve user type from local storage
  const userType =
    localStorage.getItem("RecruiterType") ||
    localStorage.getItem("ApplicantType") ||
    localStorage.getItem("SystemAdminType") ||
    localStorage.getItem("OnboardingType") ||
    localStorage.getItem("RecOpsHeadType");

  const modifiedUserType =
    userType === "Selected Applicant" ? "Applicant" : userType;
  // Define menu items based on user type
  const menuItems = [
    {
      text: "Dashboard",
      icon: <DashboardIcon />,
      path: "/recruiter-dashboard",
      visible: userType === "Recruiter",
    },
    {
      text: "Home",
      icon: <HomeIcon />,
      path: "/home",
      visible: userType === "Selected Applicant",
    },
    {
      text: "Dashboard",
      icon: <DashboardIcon />,
      // path: "/system-admin-dashboard",
      path: "/recops-dashboard",
      visible: userType === "RECOPS Head",
    },
    {
      text: "Dashboard",
      icon: <DashboardIcon />,
      path: "/onboarding-dashboard",
      visible: userType === "Onboarding Team",
    },

    {
      text: "Requirement List",
      icon: <AssignmentIcon />,
      path: "/requirement-list",
      visible: userType === "RECOPS Head",
    },
    {
      text: "Internal Requirement",
      icon: <AssignmentIcon />,
      path: "/internal-requirement",
      visible: userType === "RECOPS Head",
    },
    {
      text: "Selected Candidates",
      icon: <TaskOutlinedIcon />,
      path: "/applicant-list",
      visible: userType === "Recruiter",
    },
    {
      text: "Selected Candidates",
      icon: <TaskOutlinedIcon />,
      path: "/selected-applicant-list",
      visible: userType === "RECOPS Head",
    },

    {
      text: "Requirement Progress",
      icon: <ArticleOutlinedIcon />,
      path: "/requirement-progress",
      visible: userType === "Recruiter" || userType === "RECOPS Head",
    },
    {
      text: "Internal User List",
      icon: <PeopleIcon />,
      path: "/recruiter-list",
      visible: userType === "System Admin",
    },
    {
      text: "Profile",
      icon: <PersonIcon />,
      path: "/profile",
      visible: userType === "Selected Applicant",
    },
    {
      text: "My Requirements",
      icon: <AssignmentIcon />,
      path: "/my-requirements",
      visible: userType === "Selected Applicant",
    },

    {
      text: "Assigned Applicant",
      icon: <AssignmentIcon />,
      path: "/assigned-applicant",
      visible: userType === "Onboarding Team",
    },
  ];

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar position="fixed" open={open} color="inherit">
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: 5,
              ...(open && { display: "none" }),
            }}
          >
            <MenuIcon />
          </IconButton>
          <Box sx={{ flexGrow: 1 }} /> {/* </Box> */}
          <AccountMenu />
          <div style={{ marginLeft: "10px" }}>
            <Typography
              variant="body1"
              noWrap
              component="div"
              sx={{
                fontFamily: "Montserrat, sans-serif",
                textAlign: "left",
                fontWeight: "800",
                color: "#7901A3",
              }}
            >
              Hi {profileDetails?.firstName},
            </Typography>
            <Typography
              noWrap
              component="div"
              sx={{
                fontFamily: "Montserrat, sans-serif",
                textAlign: "center",
                fontWeight: "600",
                fontSize: 11,
              }}
            >
              {profileDetails?.emailAddress}
            </Typography>
          </div>
        </Toolbar>
      </AppBar>
      <Drawer variant="permanent" open={open}>
        <DrawerHeader sx={{ backgroundColor: "#FFF" }}>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "rtl" ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon sx={{ color: "#3D005D" }} />
            )}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <div
          style={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              marginTop: "30px",
            }}
          >
            <img
              src={onBoardingLogo}
              alt="onBoardingLogo"
              style={{ width: "50%", maxWidth: "200px" }}
            />

            {open && (
              <Typography
                variant="body1"
                sx={{
                  fontFamily: "Montserrat, sans-serif",
                  textAlign: "center",
                  fontWeight: "800",
                  mt: 2,
                  mb: 1,
                  color: "#000",
                }}
              >
                {modifiedUserType} Portal
              </Typography>
            )}

            <List>
              {menuItems.map(
                (item, index) =>
                  item.visible !== false && (
                    <ListItem
                      key={item.text}
                      disablePadding
                      sx={{
                        display: "block",
                        "&:hover": {
                          backgroundColor: theme.palette.action.hover,
                        },
                        ...(window.location.pathname === item.path && {
                          backgroundColor: theme.palette.action.selected,
                          "&:hover": {
                            backgroundColor: theme.palette.action.selected,
                          },
                          "& .MuiListItemIcon-root": {
                            color: "#7901A3", // Selected icon color
                          },
                          "&::before": {
                            content: '""',
                            display: "block",
                            position: "absolute",
                            top: 0,
                            left: 0,
                            height: "100%",
                            width: 5,
                            backgroundColor: "#7901A3", // Box color
                          },
                          color: "#7901A3",
                        }),
                      }}
                    >
                      <ListItemButton
                        component={Link}
                        to={item.path}
                        onClick={
                          item.path === window.location.pathname
                            ? handleDrawerClose
                            : undefined
                        }
                        sx={{
                          minHeight: 48,
                          justifyContent: open ? "initial" : "center",
                          px: 2.5,
                        }}
                      >
                        <ListItemIcon
                          sx={{
                            minWidth: 0,
                            mr: open ? 3 : "auto",
                            justifyContent: "center",
                            color: "inherit",
                          }}
                        >
                          {item.icon}
                        </ListItemIcon>
                        <ListItemText
                          primary={item.text}
                          sx={{
                            opacity: open ? 1 : 0,
                            color: "inherit",
                            fontFamily: "Montserrat, sans-serif",
                            fontWeight: "800",
                          }}
                        />
                      </ListItemButton>
                    </ListItem>
                  )
              )}
            </List>
          </div>

          <Divider />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              marginTop: "30px",
            }}
          >
            <img
              src={agcLogoColored}
              alt="agcLogoColored"
              style={{ width: "80%", maxWidth: "200px", marginBottom: "30px" }}
            />
          </div>
        </div>
      </Drawer>

      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          backgroundImage: `url('${bodyImage}')`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          height: "100vh",
          overflowY: "auto",
          alignItems: "center",
          "@media (max-width: 600px)": {
            // Adjust styles for small screens (e.g., mobile)
            height: "auto",
            padding: 2,
            backgroundImage: "none",
          },
        }}
      >
        <DrawerHeader />
        <div style={{}}>{content}</div>
      </Box>
    </Box>
  );
};

export default CustomDrawer;
