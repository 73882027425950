import React, { useEffect, useState } from "react";
import axios, { AxiosError } from "axios";
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
  Typography,
  Button,
  MenuItem,
  TableCell,
  Stack,
  Dialog,
  DialogTitle,
  DialogContent,
  InputLabel,
  FormControl,
  DialogActions,
  TextField,
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { useLocation } from "react-router-dom";
import moment from "moment";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import DownloadIcon from "@mui/icons-material/Download";
import StyledMenu from "../../../components/TableStyle/StyledMenu";
import StyledTableCell from "../../../components/TableStyle/StyledTableCell";
import StyledTableRow from "../../../components/TableStyle/StyledTableRow";
import { saveAs } from "file-saver";
import Dot from "../../../components/TableStyle/Dot";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import UploadRequirement from "../../Applicant/ApplicationRequirements/UploadRequirement";
import CustomModal from "../../../components/CustomModal";
import { useNavigate } from "react-router-dom";
import { Document, Page, pdfjs } from "react-pdf";
import "react-pdf/dist/esm/Page/TextLayer.css";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import { styled } from "@mui/material/styles";
import Chip from "@mui/material/Chip";
import { Add as AddIcon } from "@mui/icons-material";
import AddInternalRequirements from "../../Recruiter/RequirementProgress/AddInternalRequirements";
import RequirementModal from "../../Applicant/JobOffer/RequirementModal";
import DeleteIcon from "@mui/icons-material/Delete";
import Loader from "../../../components/Loader";
import BpiRequirement from "../../Recruiter/RequirementProgress/BpiRequirement";
import VisibilityIcon from '@mui/icons-material/Visibility';

export interface Applicant {
  applicantRequirementId: string;
  requirement: string;
  prioritization: string;
  deadline: string;
  dateCompleted: string;
  documentStatus: string;
  jobId: string;
  applicantId: string;
  prfId: string;
  jobOfferId: string;
  userType: string;
  templatePath: string;
  uploadedDocument: string;
  s3_path: string;
  completionPercentage: number;
}

const cellTextStyle = {
  fontFamily: "Montserrat, sans-serif",
  fontWeight: "400",
  fontSize: "13px",
  whiteSpace: "normal",
};

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 13,
  borderRadius: 18,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 18,
    backgroundColor: theme.palette.mode === "light" ? "#1a90ff" : "#308fe8",
  },
}));

const RequirementViewContent = () => {
  pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

  const apiKey = process.env.REACT_APP_RECRUITMENT_API;
  const navigate = useNavigate();
  const location = useLocation();
  const { applicant } = location.state;

  const [data, setData] = useState<Applicant[]>([]);

  const columns = [
    { id: "no", label: "No." },
    { id: "requirement", label: "Requirement" },
    { id: "deadline", label: "Deadline" },
    { id: "dateCompleted", label: "Date Completed" },
    { id: "documentStatus", label: "Document Status" },
    { id: "actions", label: "Actions" },
  ];
  const [loading, setLoading] = useState(false);
  const [loadingDownloadFile, setLoadingdDownloadFile] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [isUploadRequirementModal, setIsUploadRequirementModal] =
    useState(false);
  const [addInternalRequirement, setAddInternalRequirement] = useState(false);

  const open = Boolean(anchorEl);
  const [openDialog, setOpenDialog] = useState(false);
  const [formData, setFormData] = useState({
    documentStatus: "",
    returnedReason: "",
    s3_path: "",
  });

  const [formErrors, setFormErrors] = useState({
    documentStatus: "",
    returnedReason: "",
  });

  const [errorMessage, setErrorMessage] = useState({ message: "" });
  const [selectedApplicant, setSelectedApplicant] = useState<Applicant | null>(
    null
  );

  const [completionPercentage, setCompletionPercentage] = useState<number>(0);
  const [applicantRequirements, setApplicantRequirements] = useState("");
  const [addBpiRequirement, setAddBpiRequirement] = useState(false);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    setLoading(true);
    try {
      const accessToken = localStorage.getItem("accessToken");
      const response = await axios.post(
        `${apiKey}/api/recruitment/applicant-requirements`,
        {
          jobOfferId: applicant.jobOfferId,
          applicantId: applicant.applicantId,
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      if (response.status === 200) {
        setCompletionPercentage(response.data.percentage);
        const applicantRequirements = response.data.result;
        
        setData(applicantRequirements);
        setApplicantRequirements(applicantRequirements[0]);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleFileDownloadFile = async (isTemplate: boolean, data: any) => {
    setLoadingdDownloadFile(true);
    const accessToken = localStorage.getItem("accessToken");
    try {
      const response = await axios.get(`${apiKey}/api/applicant/document-file`, {
        params: {
          downloadType: isTemplate ? "template" : "file",
          applicantRequirementId: selectedApplicant?.applicantRequirementId,
        },
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        responseType: "arraybuffer",
      });
      if (response.status === 200) {
        const contentType = response.headers["content-type"];

        if (contentType === "application/pdf") {
          const file = new Blob([response.data], { type: contentType });
          const fileURL = URL.createObjectURL(file);
          window.open(fileURL, "_blank");
        } else {
          const blob = new Blob([response.data], { type: contentType });

          let fileName = selectedApplicant?.s3_path;
          if (contentType === "application/msword") {
            fileName += ".doc";
          } else if (
            contentType ===
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
          ) {
            fileName += ".docx";
          } else if (
            contentType ===
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          ) {
            fileName += ".xlsx";
          } else if (contentType === "application/vnd.ms-powerpoint") {
            fileName += ".ppt";
          } else if (
            contentType ===
            "application/vnd.openxmlformats-officedocument.presentationml.presentation"
          ) {
            fileName += ".pptx";
          } else {
            fileName += "." + contentType.split("/")[1];
          }

          if (contentType === "application/msword") {
            const fileURL = URL.createObjectURL(blob);
            window.open(fileURL, "_blank");
          } else {
            saveAs(blob, fileName);
          }
        }
        setLoadingdDownloadFile(false);
      }
    } catch (error) {
      console.error("Error downloading file:", error);
      setLoadingdDownloadFile(false);
    }
  };
  const HandleDocumentValidation = async () => {
    try {
      const accessToken = localStorage.getItem("accessToken");
      const response = await axios.post(
        `${apiKey}/api/recruitment/validate-requirement`,
        {
          documentStatus: formData.documentStatus,
          applicantRequirementId: selectedApplicant?.applicantRequirementId,
          returnedReason: formData.returnedReason,
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      if (response.status === 200) {
        handleDialogClose();
        window.location.reload();
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      if (axios.isAxiosError(error)) {
        const axiosError: AxiosError = error;

        if (axiosError.response) {
          const status = axiosError.response.status;
          if (status === 401) {
            setErrorMessage({
              message: "Unauthorized. Please provide a valid Bearer Token.",
            });
          } else if (status === 422) {
            setErrorMessage({
              message: 'Unprocessable Entity. "addressee" is not allowed.',
            });
          } else if (status === 500) {
            setErrorMessage({
              message: "Internal Server Error. An unexpected error occurred.",
            });
          }
        }
      }
    } finally {
      setLoading(false);
    }
  };

  const DocumentStatus: React.FC<{ status: string }> = ({ status }) => {
    let color;
    let title;

    const trimmedStatus = status.trim().toLowerCase();

    switch (trimmedStatus) {
      case "0":
      case "pending":
        color = "warning";
        title = "Pending";
        break;
      case "1":
      case "validated":
        color = "success";
        title = "Validated";
        break;
      case "2":
      case "return contract":
        color = "error";
        title = "Return Document";
        break;
      case "for validation":
        color = "primary";
        title = "For Validation";
        break;
      case "for candidate review":
        color = "secondary";
        title = "For Candidate Review";
        break;
      case "uploaded":
        color = "primary";
        title = "Uploaded";
        break;
      default:
        color = "primary";
        title = "None";
    }

    return (
      <Stack direction="row" spacing={1} alignItems="center">
        <Dot color={color} />
        <Typography
          sx={{
            fontSize: "13px",
            fontFamily: "Montserrat, sans-serif",

            fontWeight: "400",
          }}
        >
          {title}
        </Typography>
      </Stack>
    );
  };

  const HandleDeleteSpecialRequirement = async () => {
    try {
      const accessToken = localStorage.getItem("accessToken");

      // Ensure selectedApplicant is defined before proceeding
      if (!selectedApplicant) {
        console.error("No selected applicant.");
        return;
      }

      const response = await axios.delete(
        `${apiKey}/api/recruitment/delete-requirement`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json",
          },
          data: {
            requirementId: selectedApplicant.applicantRequirementId,
          },
        }
      );

      if (response.status === 200) {
        // Successfully deleted, you can handle the success as needed
        window.location.reload();
      }
    } catch (error) {
      console.error("Error deleting requirement:", error);

      if (axios.isAxiosError(error)) {
        const axiosError: AxiosError = error;

        if (axiosError.response) {
          const status = axiosError.response.status;
          if (status === 401) {
            setErrorMessage({
              message: "Unauthorized. Please provide a valid Bearer Token.",
            });
          } else if (status === 422) {
            setErrorMessage({
              message: 'Unprocessable Entity. "addressee" is not allowed.',
            });
          } else if (status === 500) {
            setErrorMessage({
              message: "Internal Server Error. An unexpected error occurred.",
            });
          }
        }
      }
    } finally {
      setLoading(false);
    }
  };

  const handleClick = (
    event: React.MouseEvent<HTMLElement>,
    applicant: Applicant
  ) => {
    setAnchorEl(event.currentTarget);
    setSelectedApplicant(applicant);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDialogOpen = () => {
    setOpenDialog(true);
  };

  const handleOpenAddInternalRequirement = () => {
    setAddInternalRequirement(true);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  const handleCloseUploadRequirementModal = () => {
    setIsUploadRequirementModal(false);
  };
  const handleCloseAddInternalRequirement = () => {
    setAddInternalRequirement(false);
  };

  const handleOpenBpiRequirement = () => {
    setAddBpiRequirement(true);
    };

    const handleCloseBPIRequirementModal = () => {
      setAddBpiRequirement(false);
    };

  const handleSelectChange = (id: string) => (e: SelectChangeEvent<string>) => {
    const value = e.target.value;

    setFormData((prevData) => ({ ...prevData, [id]: value }));
    setFormErrors((prevErrors) => ({ ...prevErrors, [id]: "" }));
  };

  const handleInputChange =
    (id: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
      let value = e.target.value;

      setFormData((prevData) => ({ ...prevData, [id]: value }));
      setFormErrors((prevErrors) => ({ ...prevErrors, [id]: "" }));
    };

  const ovalButtonColor = (overallStatus: string) => {
    switch (overallStatus) {
      case "Ongoing":
        return "#FF9900";
      case "Completed":
        return "#00CA8E";
      case "Pending":
        return "#2074d4";
      default:
        return "#2074d4";
    }
  };

  const handleOpenEIS = () => {
    const applicantId = selectedApplicant?.applicantId;
  
    if (applicantId) {
      navigate(`/employee-information-sheet/${applicantId}`);
    }
    
  };
  

  return (
    <div>
      <div style={{ display: "flex", alignItems: "center" }}>
        <Typography
          variant="h4"
          sx={{
            fontFamily: "Montserrat, sans-serif",
            fontWeight: "700",
            marginBottom: 3,
            marginTop: 1,
            marginRight: 2, // Adjust the right margin as needed
          }}
        >
          {applicant.applicantLastName}, {applicant.applicantFirstName}
        </Typography>
        <Chip
          label={applicant.overallStatus}
          sx={{
            backgroundColor: ovalButtonColor(applicant.overallStatus),
            color: "#ffffff",
            borderRadius: "20px",
            fontWeight: "700",
            fontSize: "16px",
            p: 1,
            mb: 2,
          }}
          variant="filled"
        />
      </div>

      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Button
          startIcon={<ArrowBackIcon />}
          variant="contained"
          onClick={() => navigate("/assigned-applicant", { replace: true })}
          sx={{
            marginBottom: 2,
            backgroundColor: "#7901A3",
            "&:hover": {
              backgroundColor: "#7901A3",
            },
          }}
        >
          Back
        </Button>
        <Button
          startIcon={<AddIcon />}
          variant="contained"
          onClick={handleOpenAddInternalRequirement}
          sx={{
            marginBottom: 2,
            backgroundColor: "#7901A3",
            "&:hover": {
              backgroundColor: "#7901A3",
            },
          }}
        >
          Add Internal Requirements
        </Button>
      </div>
      <div style={{ marginBottom: 10 }}>
        <Typography
          variant="body1"
          sx={{
            fontFamily: "Montserrat, sans-serif",
            fontWeight: "500",
            mb: 1,
          }}
        >
          Progress: {completionPercentage}%
        </Typography>
        <BorderLinearProgress
          variant="determinate"
          value={completionPercentage}
          sx={{ width: "100%" }}
        />
      </div>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <TableContainer component={Paper}>
          <Table size="small" sx={{ minWidth: 650 }}>
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <StyledTableCell
                    key={column.id}
                    sx={{
                      fontFamily: "Montserrat, sans-serif",
                      fontWeight: "800",
                    }}
                  >
                    {column.label}
                  </StyledTableCell>
                ))}
              </TableRow>
            </TableHead>

            <TableBody>
              {loading ? (
                <TableRow>
                  <TableCell colSpan={columns.length} align="center">
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        height: "100%",
                      }}
                    >
                      <Loader />
                    </Box>
                  </TableCell>
                </TableRow>
              ) : data.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={columns.length} align="center">
                    <Typography
                      variant="h5"
                      sx={{ fontFamily: "Montserrat, sans-serif" }}
                    >
                      No data available in the table
                    </Typography>
                  </TableCell>
                </TableRow>
              ) : (
                data.map((row, index) => (
                  <StyledTableRow
                    key={row.applicantRequirementId}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell sx={cellTextStyle}>{index + 1}</TableCell>

                    <TableCell sx={cellTextStyle}>{row.requirement}</TableCell>

                    <TableCell sx={cellTextStyle}>
                      {moment(row.deadline).format("MMM DD, YYYY")}
                    </TableCell>
                    <TableCell sx={cellTextStyle}>
                      {moment(row.dateCompleted).isValid()
                        ? moment(row.dateCompleted).format("MMM DD, YYYY")
                        : ""}
                    </TableCell>
                    <TableCell sx={cellTextStyle}>
                      <DocumentStatus status={row.documentStatus} />
                    </TableCell>
                    <TableCell>
                      <div>
                        <Button
                          id="demo-customized-button"
                          aria-controls={
                            open ? "demo-customized-menu" : undefined
                          }
                          aria-haspopup="true"
                          aria-expanded={open ? "true" : undefined}
                          disableElevation
                          onClick={(event) => handleClick(event, row)}
                          disabled={
                            row.s3_path !== "special_requirement" &&
                            row.documentStatus === "Pending" &&
                            row.requirement !== "BPI Account Document" &&
                            row.requirement !== "Online Employee Information Sheet" 
                          }
                        >
                          <MoreVertIcon
                            color={
                              row.s3_path === "special_requirement"
                                ? "primary"
                                : "inherit"
                            }
                          />
                        </Button>
                        <StyledMenu
                          id="demo-customized-menu"
                          MenuListProps={{
                            "aria-labelledby": "demo-customized-button",
                          }}
                          anchorEl={anchorEl}
                          open={open}
                          onClose={handleClose}
                        >
                          <MenuItem onClick={handleDialogOpen} disableRipple>
                            <ModeEditOutlineOutlinedIcon
                              sx={{ marginRight: 1 }}
                            />
                            Validate
                          </MenuItem>

                          {(selectedApplicant?.uploadedDocument || selectedApplicant?.templatePath) && (
                            <MenuItem
                              onClick={() => handleFileDownloadFile(!!!selectedApplicant?.uploadedDocument, selectedApplicant?.uploadedDocument)}
                              disableRipple
                            >
                              {loadingDownloadFile ? (
                                <CircularProgress
                                  size={24}
                                  sx={{ marginRight: 1 }}
                                  color="primary"
                                />
                              ) : (
                                <DownloadIcon sx={{ marginRight: 1 }} />
                              )}
                              Download File
                            </MenuItem>
                          )}
                          {selectedApplicant?.s3_path ===
                            "special_requirement" && (
                            <MenuItem
                              onClick={HandleDeleteSpecialRequirement}
                              disableRipple
                            >
                              {loadingDownloadFile ? (
                                <CircularProgress
                                  size={24}
                                  sx={{ marginRight: 1 }}
                                  color="primary"
                                />
                              ) : (
                                <DeleteIcon sx={{ marginRight: 1 }} />
                              )}
                              Delete
                            </MenuItem>
                          )}
                          {selectedApplicant?.requirement ===
                            "BPI Account Document" && (
                            <MenuItem
                              onClick={handleOpenBpiRequirement}
                              disableRipple
                            >
                              {loadingDownloadFile ? (
                                <CircularProgress
                                  size={24}
                                  sx={{ marginRight: 1 }}
                                  color="primary"
                                />
                              ) : (
                                <DownloadIcon sx={{ marginRight: 1 }} />
                              )}
                              Upload Document
                            </MenuItem>
                          )}
                             {selectedApplicant?.requirement ===
                            "Online Employee Information Sheet" && (
                            <MenuItem
                             onClick={handleOpenEIS}
                              disableRipple
                            >
                          
                                <VisibilityIcon sx={{ marginRight: 1 }} />
                          
                             View EIS
                            </MenuItem>
                          )}
                        </StyledMenu>
                      </div>
                    </TableCell>
                 
                  </StyledTableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <Dialog
        open={openDialog}
        onClose={handleDialogClose}
        fullWidth
        maxWidth="xs"
      >
        <DialogContent>
          <Box noValidate component="form">
            <FormControl fullWidth sx={{ mt: 1 }}>
              <Typography
                variant="body1"
                sx={{
                  fontFamily: "Montserrat, sans-serif",
                  color: "#000",
                  fontWeight: 600,
                }}
              >
                Document Status<span style={{ color: "red" }}>*</span>
              </Typography>
              <Select
                labelId="select-label"
                id="select"
                value={formData.documentStatus}
                onChange={handleSelectChange("documentStatus")}
                fullWidth
                sx={{ mt: 1 }}
              >
                <MenuItem value="Validated">Validated</MenuItem>
                <MenuItem value="Return Contract">Return Document</MenuItem>
              </Select>
            </FormControl>
            {formData.documentStatus === "Return Contract" && (
              <TextField
                fullWidth
                label="Returned Reason"
                value={formData.returnedReason}
                onChange={handleInputChange("returnedReason")}
                margin="normal"
                variant="standard"
              />
            )}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            onClick={HandleDocumentValidation}
            sx={{
              backgroundColor: "#7901A3",
              "&:hover": {
                backgroundColor: "#7901A3",
              },
            }}
          >
            Submit
          </Button>
          <Button
            variant="contained"
            onClick={handleDialogClose}
            sx={{
              backgroundColor: "#9e9e9e",
              "&:hover": {
                backgroundColor: "#9e9e9e",
              },
            }}
          >
            Cancel
          </Button>
        </DialogActions>

        <DialogActions sx={{ display: "flex", justifyContent: "center" }}>
          {errorMessage.message && (
            <Typography
              variant="body2"
              sx={{
                fontFamily: "Montserrat, sans-serif",
                color: "#d32f2f",

                mt: 1,
                mb: 1,
              }}
            >
              {errorMessage.message}
            </Typography>
          )}
        </DialogActions>
      </Dialog>

      <CustomModal
        isOpen={isUploadRequirementModal}
        handleClose={handleCloseUploadRequirementModal}
        content={<UploadRequirement applicant={selectedApplicant} />}
      />
       <CustomModal
        isOpen={addBpiRequirement}
        handleClose={handleCloseBPIRequirementModal}
        content={<BpiRequirement applicant={selectedApplicant} />}
      />
      <RequirementModal
        isOpen={addInternalRequirement}
        handleClose={handleCloseAddInternalRequirement}
        title="Add Internal Requirements Form"
        content={
          <AddInternalRequirements
            applicantRequirements={[applicantRequirements]}
          />
        }
      />
    </div>
  );
};

export default RequirementViewContent;
