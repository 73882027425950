import React, { useState, ChangeEvent, useEffect } from "react";
import {
  Button,
  Grid,
  Typography,
  TextField,
  InputAdornment,
} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import { SelectChangeEvent } from "@mui/material/Select";
import axios, { AxiosError } from "axios";
import Autocomplete from "@mui/material/Autocomplete";
import DeleteIcon from "@mui/icons-material/Delete";
import { Add as AddIcon } from "@mui/icons-material";
import CustomModal from "../../../components/Modal";


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: "auto",
    },
  },
};

interface FormData {
  firstName: string;
  lastName: string;
  middleName: string;
  address: string;
  emailAddress: string;
  contactNumber: string;
  dateOfBirth: string;
  placeOfBirth: string;
  bloodType: string;
  civilStatus: string;
  secondaryContactNumber: string;
  streetName: string;
  houseNo: string;
  region: Region | string;
  province: Region | string;
  municipality: Region | string;
  barangay: Region | string;
  age: string;
  gender: string;
  religion: string;
  tin: string;
  sss: string;
  philhealth: string;
  pagibig: string;
  bpiAccount: string;
  emergencyContacts: {
    contactPersonName: string;
    emergencyContactNumber: string;
    relationship: string;
    address: string;
  }[];
  educationalBackground: {
    school: string;
    course: string;
    dateGraduated: string;
  }[];
  priorWorkExp: {
    companyName: string;
    industry: string;
    startDate: string;
    endDate: string;
    position: string;
    salary: string;
    reasonOfLeaving: string;
  }[];
  characterReference: {
    name: string;
    workPosition: string;
    emailAddress: string;
    company: string;
    relation: string;
    contactDetail: string;
  }[];
  nameOfRelatives: {
    name: string;
  }[];
  otherReligion: string;
  vaccineCard: string;
  suffix: string;
  zipCode: string;
  firstJab: string;
  secondJab: string;
  firstBooster: string;
  secondBooster: string;
  shirtSize: string;
  dataPrivacy: string;
  completeDeliveryAddress: string;
  highestEducation: string;
  reasonOfJoining: string;
  previousMonthlySalary: string;
  positionApplyingFor: string;

  recommendAsticom: string;
}

interface Region {
  name: string;
  reg_code: string;
  prov_code: string;
  mun_code: string;
}
type Province = Region;
type Municipality = Region;
type Barangay = Region;

const ProfileContent: React.FC = () => {
  const apiKey = process.env.REACT_APP_RECRUITMENT_API;
  const apiKeyUser = process.env.REACT_APP_USER_SERVICE_API;
  const [formData, setFormData] = useState<FormData>({
    firstName: "",
    middleName: "",
    lastName: "",
    suffix: "",
    dateOfBirth: "",
    placeOfBirth: "",
    bloodType: "",
    civilStatus: "",
    secondaryContactNumber: "",
    address: "",
    streetName: "",
    houseNo: "",
    barangay: "",
    municipality: "",
    province: "",
    region: "",
    age: "",
    gender: "",
    religion: "",
    tin: "",
    sss: "",
    philhealth: "",
    pagibig: "",
    bpiAccount: "",
    emergencyContacts: [
      {
        contactPersonName: "",
        emergencyContactNumber: "",
        relationship: "",
        address: "",
      },
    ],
    educationalBackground: [
      {
        school: "",
        course: "",
        dateGraduated: "",
      },
    ],
    priorWorkExp: [
      {
        companyName: "",
        industry: "",
        startDate: "",
        endDate: "",
        position: "",
        salary: "",
        reasonOfLeaving: "",
      },
    ],
    characterReference: [
      {
        name: "",
        workPosition: "",
        emailAddress: "",
        company: "",
        relation: "",
        contactDetail: "",
      },
    ],
    nameOfRelatives: [
      {
        name: "",
      },
    ],
    otherReligion: "",
    vaccineCard: "",
    zipCode: "",
    firstJab: "",
    secondJab: "",
    firstBooster: "",
    secondBooster: "",
    shirtSize: "",
    dataPrivacy: "",
    recommendAsticom: "",
    completeDeliveryAddress: "",
    highestEducation: "",
    emailAddress: "",
    contactNumber: "",
    reasonOfJoining: "",
    previousMonthlySalary: "",
    positionApplyingFor: "",
  });

  const [formErrors, setFormErrors] = useState({
    contactNumber: "",
    secondaryContactNumber: "",

  });
  const [editMode, setEditMode] = useState(false);
  const [showOtherTextField, setShowOtherTextField] = useState(false);
  const [assignedRecruiter, setAssignedRecruiter] = useState("");
  const [birthPlaces, setBirthPlaces] = useState<string[]>([]);

  //Dropdown for Address of User
  const [selectedRegion, setSelectedRegion] = useState<Region | null>(null);
  const [regionData, setRegionData] = useState<Region[]>([]);

  const [selectedProvince, setSelectedProvince] = useState<Region | null>(null);
  const [provinceData, setProvinceData] = useState<Region[]>([]);



  const [selectedMunicipality, setSelectedMunicipality]  = useState<Region | null>(null);
  const [municipalityData, setMunicipalityData] = useState<Region[]>([]);

  const [selectedBarangay, setSelectedBarangay]   = useState<Region | null>(null);

  const [barangayData, setBarangayData] = useState<Region[]>([]);

  const [errorMessage, setErrorMessage] = useState({ message: "" });
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const token = localStorage.getItem("accessToken");
        const response = await axios.get(
          `${apiKeyUser}/api/applicant/my-profile`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        const excludedFields = [
          "applicantId",
          "fullName",
          "createdAt",
          "updatedAt",
          "recruiterName",
        ];


        setAssignedRecruiter(response.data.result.recruiterName);
     
        const cleanedData: FormData = Object.fromEntries(
          Object.entries(response.data.result)
            .filter(([key]) => !excludedFields.includes(key))
            .map(([key, value]) => [key, value === null ? "" : value])
        ) as unknown as FormData;

        
        setFormData(cleanedData);
      } catch (error) {
        console.error("Error fetching profile:", error);
      }
    };

    fetchProfile();
  }, []);

  useEffect(() => {
    const fetchBirthPlace = async () => {
      try {
        const token = localStorage.getItem("accessToken");

        const response = await axios.get(
          `${apiKey}/api/recruitment/birth-location`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const places = (
          response.data.result as { birthLocation: string }[]
        ).map((place) => place.birthLocation);
        setBirthPlaces(["", ...places]);
      } catch (error) {
        console.error("Error fetching profile:", error);
      }
    };

    fetchBirthPlace();
  }, []);

  useEffect(() => {
    const token = localStorage.getItem("accessToken");
    axios
      .get(`${apiKey}/api/recruitment/region`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })

      .then((response) => {
        const regions = response.data.regions;
        setRegionData(regions);

      })
      .catch((error) => {
        console.error("Error fetching region data:", error);
      });
  }, [apiKey]);

  // useEffect(() => {
  //   const token = localStorage.getItem("accessToken");

  //   if (selectedRegion && selectedRegion.name !== "13") {
  //     axios
  //       .get(
  //         `${apiKey}/api/recruitment/province?reg_code=${selectedRegion.reg_code}`,
  //         {
  //           headers: {
  //             Authorization: `Bearer ${token}`,
  //           },
  //         }
  //       )
  //       .then((response) => {
  //         const provinces = response.data.provinces;
  //         setProvinceData(provinces);
  
  //       })
  //       .catch((error) => {
  //         console.error("Error fetching province data:", error);
  //       });
  //   }
  // }, [selectedRegion, apiKey]);

  // useEffect(() => {
  //   const token = localStorage.getItem("accessToken");
  //   if (selectedProvince) {
  //     axios
  //       .get(
  //         `${apiKey}/api/recruitment/municipality?prov_code=${selectedProvince?.prov_code}`,
  //         {
  //           headers: {
  //             Authorization: `Bearer ${token}`,
  //           },
  //         }
  //       )
  //       .then((response) => {
  //         const municipalities = response.data.municipality;
  //         setMunicipalityData(municipalities);

  //       })
  //       .catch((error) => {
  //         console.error("Error fetching municipality data:", error);
  //       });
  //   }
  // }, [selectedProvince, apiKey]);

  // useEffect(() => {
  //   const token = localStorage.getItem("accessToken");
  //   if (selectedMunicipality) {
  //     axios
  //       .get(
  //         `${apiKey}/api/recruitment/barangay?mun_code=${selectedMunicipality?.mun_code}`,
  //         {
  //           headers: {
  //             Authorization: `Bearer ${token}`,
  //           },
  //         }
  //       )
  //       .then((response) => {
  //         const barangays = response.data.barangay;
  //         setBarangayData(barangays);

  //       })
  //       .catch((error) => {
  //         console.error("Error fetching barangay data:", error);
  //       });
  //   }
  // }, [selectedMunicipality, apiKey]);

const handleChange = (
  e:
    | ChangeEvent<HTMLInputElement | { name?: string; value: unknown }>
    | SelectChangeEvent<string>
) => {


  const isNumberOnly = (value: string) => /^[0-9+]*$/.test(value);

  if (e.target.name === "contactNumber" || e.target.name === "secondaryContactNumber") {
    if (!isNumberOnly(e.target.value as string)) {
      setFormErrors(prevErrors => ({
        ...prevErrors,
        [e.target.name as string]: 'Only numbers are allowed.',
      }));
      
      return;
    } else {
      setFormErrors(prevErrors => ({
        ...prevErrors,
        [e.target.name as string]: '',
      }));
    }
  }

  if (e.target.value === "Other") {
    setShowOtherTextField(true);
  } else {
    setShowOtherTextField(false);
  }

  const formatSSS = (inputValue: string) => {
    const cleanedInput = inputValue.replace(/[^0-9]/g, "");
    const formattedSSS =
      cleanedInput.slice(0, 2) +
      " - " +
      cleanedInput.slice(2, 10) +
      " - " +
      cleanedInput.slice(10, 11);
    return formattedSSS;
  };

  const formatTIN = (inputValue: string) => {
    const cleanedInput = inputValue.replace(/[^0-9]/g, "");
    const formattedTIN =
      cleanedInput.slice(0, 3) +
      " - " +
      cleanedInput.slice(3, 6) +
      " - " +
      cleanedInput.slice(6, 9) +
      " - " +
      cleanedInput.slice(9, 13);
    return formattedTIN;
  };

  const formatPagIbig = (inputValue: string) => {
    const cleanedInput = inputValue.replace(/[^0-9]/g, "");
    const formattedPagIbig =
      cleanedInput.slice(0, 4) +
      " - " +
      cleanedInput.slice(4, 8) +
      " - " +
      cleanedInput.slice(8, 12);
    return formattedPagIbig;
  };

  if (e.target.name === "sss") {
    setFormData({
      ...formData,
      [e.target.name as string]: formatSSS(e.target.value as string),
    });
  } else if (e.target.name === "tin") {
    setFormData({
      ...formData,
      [e.target.name as string]: formatTIN(e.target.value as string),
    });
  } else if (e.target.name === "pagibig") {
    setFormData({
      ...formData,
      [e.target.name as string]: formatPagIbig(e.target.value as string),
    });
  } else {
    setFormData({
      ...formData,
      [e.target.name as string]: e.target.value as string,
    });
  }
};


  const handleOtherTextFieldChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      religion: value,
    }));
  };

  const handleSubmit = async () => {
    try {
      const token = localStorage.getItem("accessToken");

      const filteredFormData = Object.entries(formData).reduce(
        (acc, [key, value]) => {
          if (key === "educationalBackground" && value && value.length > 0) {
            acc[key] = [value[0]];
          } else if (value !== "") {
            acc[key] = value;
          }
          return acc;
        },
        {} as {
          [key: string]:
            | string
            | { school: string; course: string; dateGraduated: string }[];
        }
      );

      const requestData = {
        ...filteredFormData,
      };
      
      // console.log('requestData', requestData)
      const response = await axios.patch(
        `${apiKeyUser}/api/applicant/update-profile`,
        requestData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

        if(response.status === 200) {
          setIsModalOpen(true); 
          
        }
    } catch (error) {
      console.error("Error fetching data:", error);
      if (axios.isAxiosError(error)) {
        const axiosError: AxiosError = error;

        if (axiosError.response) {
          const status = axiosError.response.status;
          if (status === 401) {
            setErrorMessage({
              message: "Unauthorized. Please provide a valid Bearer Token.",
            });
          } else if (status === 422) {
            setErrorMessage({
              message: 'Unable to proceed. Please verify that all required information is provided in each field.',
            });
          } else if (status === 500) {
            setErrorMessage({
              message: "Internal Server Error. An unexpected error occurred.",
            });
          }
        }
      }
    }
  };

  const handleEditClick = () => {
    if (editMode) {
      handleSubmit();
    }
    setEditMode(!editMode);
  };


  const handleEmergencyContactChange = (
    index: number,
    field: keyof FormData["emergencyContacts"][0],
    value: string
  ) => {
    setFormData((prev) => ({
      ...prev,
      emergencyContacts: prev.emergencyContacts.map((contact, i) =>
        i === index ? { ...contact, [field]: value } : contact
      ),
    }));
  };

  const addEmergencyContact = () => {
    setFormData((prev) => ({
      ...prev,
      emergencyContacts: [
        ...prev.emergencyContacts,
        {
          contactPersonName: "",
          emergencyContactNumber: "",
          relationship: "",
          address: "",
        },
      ],
    }));
  };

  const deleteEmergencyContact = (index: number) => {
    if (index !== 0) {
      setFormData((prev) => ({
        ...prev,
        emergencyContacts: prev.emergencyContacts.filter((_, i) => i !== index),
      }));
    }
  };

  const handleWorkExpChange = (
    index: number,
    field: keyof FormData["priorWorkExp"][0],
    value: string
  ) => {
    setFormData((prev) => ({
      ...prev,
      priorWorkExp: prev.priorWorkExp.map((workExp, i) =>
        i === index ? { ...workExp, [field]: value } : workExp
      ),
    }));
  };

  const addWorkExp = () => {
    setFormData((prev) => ({
      ...prev,
      priorWorkExp: [
        ...prev.priorWorkExp,
        {
          companyName: "",
          industry: "",
          startDate: "",
          endDate: "",
          position: "",
          salary: "",
          reasonOfLeaving: "",
        },
      ],
    }));
  };

  const deleteWorkExp = (index: number) => {
    setFormData((prev) => ({
      ...prev,
      priorWorkExp: prev.priorWorkExp.filter((_, i) => i !== index),
    }));
  };

  const handleCharacterRefChange = (
    index: number,
    field: keyof FormData["characterReference"][0],
    value: string
  ) => {
    setFormData((prev) => ({
      ...prev,
      characterReference: prev.characterReference.map((workExp, i) =>
        i === index ? { ...workExp, [field]: value } : workExp
      ),
    }));
  };

  const addCharRef = () => {
    setFormData((prev) => ({
      ...prev,
      characterReference: [
        ...prev.characterReference,
        {
          name: "",
          workPosition: "",
          emailAddress: "",
          company: "",
          relation: "",
          contactDetail: "",
        },
      ],
    }));
  };

  const deleteCharRef = (index: number) => {
    setFormData((prev) => ({
      ...prev,
      characterReference: prev.characterReference.filter((_, i) => i !== index),
    }));
  };

  const handleRelativesChange = (
    index: number,
    field: keyof FormData["nameOfRelatives"][0],
    value: string
  ) => {
    setFormData((prev) => ({
      ...prev,
      nameOfRelatives: prev.nameOfRelatives.map((workExp, i) =>
        i === index ? { ...workExp, [field]: value } : workExp
      ),
    }));
  };

  const addRelatives = () => {
    setFormData((prev) => ({
      ...prev,
      nameOfRelatives: [
        ...prev.nameOfRelatives,
        {
          name: "",
        },
      ],
    }));
  };

  const deleteRelatives = (index: number) => {
    setFormData((prev) => ({
      ...prev,
      nameOfRelatives: prev.nameOfRelatives.filter((_, i) => i !== index),
    }));
  };


  function getValidRegionCode(region: Region | string): string {
    const regionCode = regionData.find((r) => r.name === region)?.reg_code;
    return regionCode || ""; 
  }

  function getValidProvinceCode(province: Province | string): string {
    const provinceCode = provinceData.find((p) => p.name === province)?.prov_code;
   return provinceCode || "";
  }

  function getValidMunicipalityCode(
    municipality: Municipality | string
  ): string {
    const municipalityCode = municipalityData.find(
      (p) => p.name === municipality
    )?.mun_code;
    return municipalityCode || "";
  }

  function getValidBarangayCode(barangay: Barangay | string): string {
    const barangayCode = barangayData.find((b) => b.name === barangay)?.mun_code;
   return barangayCode || "";
  }


  const handleRegionChange = (event: SelectChangeEvent<string | Region>) => {
    const selectedRegionCode = event.target.value as string;
    const selectedRegion = regionData.find(
      (region) => region.reg_code === selectedRegionCode
    );

    setSelectedRegion(selectedRegion || null);

    setFormData((prevFormData) => ({
      ...prevFormData,
      region: selectedRegion?.name || "",
    }));
  };
  
  
  const handleProvinceChange = (event: SelectChangeEvent<string | Region>) => {
    const selectedProvinceCode = event.target.value as string;

    const selectedProvince = provinceData.find(
      (province) => province.prov_code === selectedProvinceCode
    );

    setSelectedProvince(selectedProvince || null);
    
    setFormData((prevFormData) => ({
      ...prevFormData,
      province: selectedProvince?.name || "",
    }));
  };
  
  const handleMunicipalityChange = (event: SelectChangeEvent<string | Region>) => {
    const selectedMunicipalityCode = event.target.value as string;

    const selectedMunicipality = municipalityData.find(
      (municipality) => municipality.mun_code === selectedMunicipalityCode
    );

  
    setSelectedMunicipality(selectedMunicipality || null);
    
    setFormData((prevFormData) => ({
      ...prevFormData,
      municipality: selectedMunicipality?.name || "",
    }));
  };
  
  const handleBarangayChange = (event: SelectChangeEvent<string | Region>) => {
    const selectedBarangayCode = event.target.value as string;

    const selectedBarangay = barangayData.find(
      (barangay) => barangay.mun_code === selectedBarangayCode  
    );

    setSelectedBarangay(selectedBarangay || null);

    setFormData((prevFormData) => ({
      ...prevFormData,
      barangay: selectedBarangay?.name || "",
    }));
  };
  

  return (
    <>
      <Typography
        variant="h4"
        sx={{
          fontFamily: "Montserrat, sans-serif",
          fontWeight: 600,
          color: "#000",
          mb: 2,
        }}
      >
        Employee Information Sheet
      </Typography>
      <Grid item xs={12}>
        <Button
          variant="contained"
          onClick={handleEditClick}
          sx={{
            mb: 2,
            backgroundColor: "#7901A3",
            "&:hover": {
              backgroundColor: "#7901A3",
            },
          }}
        >
          {editMode ? "Save" : "Edit Information"}
        </Button>
      </Grid>
      {errorMessage.message && (
        <Typography
          variant="body2"
          sx={{
            fontFamily: "Montserrat, sans-serif",
            color: "#d32f2f",

            mb: 1,
          }}
        >
          {errorMessage.message}
        </Typography>
      )}

      <Typography
        sx={{
          fontSize: "15px",
          fontFamily: "Montserrat, sans-serif",
          fontWeight: 800,
          color: "#000",
          mb: 1,
        }}
      >
        Personal Details
      </Typography>

      <Grid container spacing={2}>
        <Grid item xs={12} sm={3}>
          <Typography
            sx={{
              fontSize: "14px",
              fontFamily: "Montserrat, sans-serif",
              fontWeight: 600,
              color: "#000",
              mb: 0.5,
            }}
          >
            First Name
          </Typography>
          <TextField
            variant="outlined"
            name="firstName"
            value={formData.firstName}
            onChange={handleChange}
            fullWidth
            InputLabelProps={{ shrink: false }}
            disabled={!editMode}
          />
        </Grid>

        <Grid item xs={12} sm={3}>
          <Typography
            sx={{
              fontSize: "14px",
              fontFamily: "Montserrat, sans-serif",
              fontWeight: 600,
              color: "#000",
              mb: 0.5,
            }}
          >
            Middle Name{" "}
            <span
              style={{
                fontSize: "12px",
                fontFamily: "Montserrat, sans-serif",
                fontWeight: 600,
                color: "#808080",
              }}
            >
              (Optional)
            </span>
          </Typography>
          <TextField
            variant="outlined"
            name="middleName"
            value={formData.middleName}
            onChange={handleChange}
            fullWidth
            InputLabelProps={{ shrink: false }}
            disabled={!editMode}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <Typography
            sx={{
              fontSize: "14px",
              fontFamily: "Montserrat, sans-serif",
              fontWeight: 600,
              color: "#000",
              mb: 0.5,
            }}
          >
            Last Name
          </Typography>
          <TextField
            variant="outlined"
            name="lastName"
            value={formData.lastName}
            onChange={handleChange}
            fullWidth
            InputLabelProps={{ shrink: false }}
            disabled={!editMode}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <Typography
            sx={{
              fontSize: "14px",
              fontFamily: "Montserrat, sans-serif",
              fontWeight: 600,
              color: "#000",
              mb: 0.5,
            }}
          >
            Suffix{" "}
            <span
              style={{
                fontSize: "12px",
                fontFamily: "Montserrat, sans-serif",
                fontWeight: 600,
                color: "#808080",
              }}
            >
              (Optional)
            </span>
          </Typography>
          <TextField
            variant="outlined"
            name="suffix"
            value={formData.suffix}
            onChange={handleChange}
            fullWidth
            InputLabelProps={{ shrink: false }}
            disabled={!editMode}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <Typography
            sx={{
              fontSize: "14px",
              fontFamily: "Montserrat, sans-serif",
              fontWeight: 600,
              color: "#000",
              mb: 0.5,
            }}
          >
            Date of Birth
          </Typography>
          <TextField
            variant="outlined"
            type="date"
            name="dateOfBirth"
            value={formData.dateOfBirth}
            onChange={handleChange}
            fullWidth
            InputLabelProps={{ shrink: true }}
            disabled={!editMode}
          />
        </Grid>

        <Grid item xs={12} sm={3}>
          <Typography
            sx={{
              fontSize: "14px",
              fontFamily: "Montserrat, sans-serif",
              fontWeight: 600,
              color: "#000",
              mb: 0.5,
            }}
          >
            Place of Birth
          </Typography>
          <Autocomplete
            options={birthPlaces}
            value={formData.placeOfBirth}
            onChange={(event, value) =>
              setFormData({ ...formData, placeOfBirth: value ?? "" })
            }
            disabled={!editMode}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                name="placeOfBirth"
                fullWidth
                InputLabelProps={{ shrink: false }}
                disabled={!editMode}
              />
            )}
          />
        </Grid>

        {/* Blood Type */}
        <Grid item xs={12} sm={3}>
          <Typography
            sx={{
              fontSize: "14px",
              fontFamily: "Montserrat, sans-serif",
              fontWeight: 600,
              color: "#000",
              mb: 0.5,
            }}
          >
            Blood Type{" "}
            <span
              style={{
                fontSize: "12px",
                fontFamily: "Montserrat, sans-serif",
                fontWeight: 600,
                color: "#808080",
              }}
            >
              (Optional)
            </span>
          </Typography>

          <Select
            variant="outlined"
            name="bloodType"
            value={formData.bloodType || ""}
            onChange={(e) => handleChange(e as SelectChangeEvent<string>)}
            disabled={!editMode}
            fullWidth
            MenuProps={MenuProps}
          >
            <MenuItem value="O">O</MenuItem>
            <MenuItem value="O-">O-</MenuItem>
            <MenuItem value="A">A</MenuItem>
            <MenuItem value="A-">A-</MenuItem>
            <MenuItem value="B">B</MenuItem>
            <MenuItem value="B-">B-</MenuItem>
            <MenuItem value="AB">AB</MenuItem>
            <MenuItem value="AB-">AB-</MenuItem>
            <MenuItem value="Not aware">Not aware</MenuItem>
          </Select>
        </Grid>

        {/* Civil Status */}
        <Grid item xs={12} sm={3}>
          <Typography
            sx={{
              fontSize: "14px",
              fontFamily: "Montserrat, sans-serif",
              fontWeight: 600,
              color: "#000",
              mb: 0.5,
            }}
          >
            Civil Status
          </Typography>
          <FormControl fullWidth>
            <Select
              variant="outlined"
              name="civilStatus"
              value={formData.civilStatus || ""}
              onChange={(e) => handleChange(e as SelectChangeEvent<string>)}
              disabled={!editMode}
            >
              <MenuItem value="single">Single</MenuItem>
              <MenuItem value="married">Married</MenuItem>
              <MenuItem value="widowed">Widowed</MenuItem>
              <MenuItem value="separated">Separated</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={3}>
          <Typography
            sx={{
              fontSize: "14px",
              fontFamily: "Montserrat, sans-serif",
              fontWeight: 600,
              color: "#000",
              mb: 0.5,
            }}
          >
            Contact Number
          </Typography>
          <TextField
            variant="outlined"
            name="contactNumber"
            value={formData.contactNumber}
            onChange={handleChange}
            fullWidth
            InputLabelProps={{ shrink: false }}
            disabled={!editMode}
            inputProps={{
              maxLength: 13,
              startAdornment: (
                <InputAdornment position="start">+63</InputAdornment>
              ),
            }}
          />
          <Typography
            variant="caption"
            sx={{
              color: "#d32f2f",
              ml: 2,
              mt: 0.3,
            }}
          >
            {formErrors.contactNumber}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={3}>
          <Typography
            sx={{
              fontSize: "14px",
              fontFamily: "Montserrat, sans-serif",
              fontWeight: 600,
              color: "#000",
              mb: 0.5,
            }}
          >
            Secondary Contact Number{" "}
            <span
              style={{
                fontSize: "12px",
                fontFamily: "Montserrat, sans-serif",
                fontWeight: 600,
                color: "#808080",
              }}
            >
              (Optional)
            </span>
          </Typography>
          <TextField
            variant="outlined"
            name="secondaryContactNumber"
            value={formData.secondaryContactNumber}
            onChange={handleChange}
            fullWidth
            InputLabelProps={{ shrink: false }}
            disabled={!editMode}
            inputProps={{ maxLength: 13 }}
          />
          <Typography
            variant="caption"
            sx={{
              color: "#d32f2f",
              ml: 2,
              mt: 0.3,
            }}
          >
            {formErrors.secondaryContactNumber}
          </Typography>
        </Grid>

        <Grid item xs={12} sm={3}>
          <Typography
            sx={{
              fontSize: "14px",
              fontFamily: "Montserrat, sans-serif",
              fontWeight: 600,
              color: "#000",
              mb: 0.5,
            }}
          >
            Age
          </Typography>
          <TextField
            variant="outlined"
            name="age"
            value={formData.age}
            onChange={handleChange}
            fullWidth
            InputLabelProps={{ shrink: false }}
            disabled={!editMode}
            inputProps={{
              maxLength: 2,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <Typography
            sx={{
              fontSize: "14px",
              fontFamily: "Montserrat, sans-serif",
              fontWeight: 600,
              color: "#000",
              mb: 0.5,
            }}
          >
            Gender
          </Typography>
          <FormControl fullWidth>
            <Select
              variant="outlined"
              name="gender"
              value={formData.gender || ""}
              onChange={(e) => handleChange(e as SelectChangeEvent<string>)}
              disabled={!editMode}
            >
              <MenuItem value="male">Male</MenuItem>
              <MenuItem value="female">Female</MenuItem>
              <MenuItem value="nottosay">Not to Say</MenuItem>
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={3}>
          <Typography
            sx={{
              fontSize: "14px",
              fontFamily: "Montserrat, sans-serif",
              fontWeight: 600,
              color: "#000",
              mb: 0.5,
            }}
          >
            Religion
          </Typography>
          <Select
            variant="outlined"
            name="religion"
            value={formData.religion || ""}
            onChange={(e) => handleChange(e as SelectChangeEvent<string>)}
            disabled={!editMode}
            fullWidth
            MenuProps={MenuProps}
          >
            <MenuItem value="Buddhism">Buddhism</MenuItem>
            <MenuItem value="Roman Catholic">Roman Catholic</MenuItem>
            <MenuItem value="Christian">Christian</MenuItem>
            <MenuItem value="Hinduism">Hinduism</MenuItem>
            <MenuItem value="Iglesia Filipina Independiente or Aglipayan">
              Iglesia Filipina Independiente or Aglipayan
            </MenuItem>
            <MenuItem value="Iglesia ni Cristo">Iglesia ni Cristo</MenuItem>
            <MenuItem value="Islam">Islam</MenuItem>
            <MenuItem value="Jehovah's Witnesses">Jehovah's Witnesses</MenuItem>
            <MenuItem value="Judaism">Judaism</MenuItem>
            <MenuItem value="Members Church of God International or Ang Dating Daan">
              Members Church of God International or Ang Dating Daan
            </MenuItem>
            <MenuItem value="The Church of Jesus Christ of Latter-day Saints">
              The Church of Jesus Christ of Latter-day Saints
            </MenuItem>
            <MenuItem value="Protestantism">Protestantism</MenuItem>
            <MenuItem value="Other">Other:</MenuItem>
          </Select>

          {showOtherTextField && (
            <TextField
              label="Other Religion"
              variant="outlined"
              name="otherReligion"
              value={formData.otherReligion}
              onChange={handleOtherTextFieldChange}
              fullWidth
              sx={{ mt: 3 }}
            />
          )}
        </Grid>
        <Grid item xs={12} sm={3}>
          <Typography
            sx={{
              fontSize: "14px",
              fontFamily: "Montserrat, sans-serif",
              fontWeight: 600,
              color: "#000",
              mb: 0.5,
            }}
          >
            Region
          </Typography>
          <Select
            value={getValidRegionCode(formData.region)}
            onChange={handleRegionChange}
            fullWidth
            disabled={!editMode}
            variant="outlined"
          >
            {regionData.map((region) => (
              <MenuItem key={region.reg_code} value={region.reg_code}>
                {region.name}
              </MenuItem>
            ))}
          </Select>
        </Grid>

               <Grid item xs={12} sm={3}>
               <Typography
                 sx={{
                   fontSize: "14px",
                   fontFamily: "Montserrat, sans-serif",
                   fontWeight: 600,
                   color: "#000",
                   mb: 0.5,
                 }}
               >
                 Province
               </Typography>
     
               <TextField
                 variant="outlined"
                 name="province"
                 value={formData.province}
                 onChange={handleChange}
                 fullWidth
                 InputLabelProps={{ shrink: true }}
                 disabled={!editMode}
               />
               {/* {!editMode && (
                 <TextField
                   variant="outlined"
                   name="province"
                   value={formData.province}
                   fullWidth
                   disabled
                 />
               )}
               {editMode && (
                 <Select
                   value={getValidProvinceCode(formData.province)}
                   onChange={handleProvinceChange}
                   fullWidth
                   disabled={!editMode}
                   variant="outlined"
                 >
                   {provinceData.map((province) => (
                     <MenuItem key={province.prov_code} value={province.prov_code}>
                       {province.name}
                     </MenuItem>
                   ))}
                 </Select>
               )} */}
             </Grid>


        <Grid item xs={12} sm={3}>
          <Typography
            sx={{
              fontSize: "14px",
              fontFamily: "Montserrat, sans-serif",
              fontWeight: 600,
              color: "#000",
              mb: 0.5,
            }}
          >
            Municipality
          </Typography>
          <TextField
            variant="outlined"
            name="municipality"
            value={formData.municipality}
            onChange={handleChange}
            fullWidth
            InputLabelProps={{ shrink: true }}
            disabled={!editMode}
          />
          {/* {!editMode && (
            <TextField
              variant="outlined"
              name="municipality"
              value={formData.municipality}
              fullWidth
              disabled
            />
          )}
          {editMode && (
            <Select
              value={getValidMunicipalityCode(formData.municipality)}
              onChange={handleMunicipalityChange}
              fullWidth
              disabled={!editMode}
              variant="outlined"
            >
              {municipalityData.map((municipality) => (
                <MenuItem key={municipality.mun_code} value={municipality.mun_code}>
                  {municipality.name}
                </MenuItem>
              ))}
            </Select>
          )}
         */}
        </Grid>
        <Grid item xs={12} sm={3}>
          <Typography
            sx={{
              fontSize: "14px",
              fontFamily: "Montserrat, sans-serif",
              fontWeight: 600,
              color: "#000",
              mb: 0.5,
            }}
          >
            Barangay
          </Typography>
          <TextField
            variant="outlined"
            name="barangay"
            value={formData.barangay}
            onChange={handleChange}
            fullWidth
            InputLabelProps={{ shrink: true }}
            disabled={!editMode}
          />
          {/* {!editMode && (
            <TextField
              variant="outlined"
              name="barangay"
              value={formData.barangay}
              fullWidth
              disabled
            />
          )}
          {editMode && (
            <Select
              value={getValidBarangayCode(formData.barangay)}
              onChange={handleBarangayChange}
              fullWidth
              disabled={!editMode}
              variant="outlined"
            >
              {barangayData.map((barangay, index) => (
                <MenuItem key={index} value={barangay.mun_code}>
                  {barangay.name}
                </MenuItem>
              ))}
            </Select>
          )} */}
        </Grid>
        <Grid item xs={12} sm={3}>
          <Typography
            sx={{
              fontSize: "14px",
              fontFamily: "Montserrat, sans-serif",
              fontWeight: 600,
              color: "#000",
              mb: 0.5,
            }}
          >
            Street Name
          </Typography>
          <TextField
            variant="outlined"
            name="streetName"
            value={formData.streetName}
            onChange={handleChange}
            fullWidth
            InputLabelProps={{ shrink: false }}
            disabled={!editMode}
            inputProps={{ maxLength: 256 }}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <Typography
            sx={{
              fontSize: "14px",
              fontFamily: "Montserrat, sans-serif",
              fontWeight: 600,
              color: "#000",
              mb: 0.5,
            }}
          >
            House Number
          </Typography>
          <TextField
            variant="outlined"
            name="houseNo"
            value={formData.houseNo}
            onChange={handleChange}
            fullWidth
            InputLabelProps={{ shrink: false }}
            disabled={!editMode}
            inputProps={{ maxLength: 256 }}
          />
        </Grid>

        <Grid item xs={12} sm={3}>
          <Typography
            sx={{
              fontSize: "14px",
              fontFamily: "Montserrat, sans-serif",
              fontWeight: 600,
              color: "#000",
              mb: 0.5,
            }}
          >
            Vaccine Card
          </Typography>

          <Select
            variant="outlined"
            name="vaccineCard"
            value={formData.vaccineCard || ""}
            onChange={(e) => handleChange(e as SelectChangeEvent<string>)}
            disabled={!editMode}
            fullWidth
            MenuProps={MenuProps}
          >
            <MenuItem value="I am fully Vaccinated (2nd Jab)">
              I am fully Vaccinated (2nd Jab)
            </MenuItem>
            <MenuItem value="I have had my first dose (1st Jab)">
              I have had my first dose (1st Jab)
            </MenuItem>
            <MenuItem value="I am not yet vaccinated">
              I am not yet vaccinated
            </MenuItem>
          </Select>
        </Grid>
      </Grid>

      <Typography
        sx={{
          fontSize: "15px",
          fontFamily: "Montserrat, sans-serif",
          fontWeight: 800,
          color: "#000",
          mb: 1,
          mt: 2,
        }}
      >
        Government ID Details
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={3}>
          <Typography
            sx={{
              fontSize: "14px",
              fontFamily: "Montserrat, sans-serif",
              fontWeight: 600,
              color: "#000",
              mb: 0.5,
            }}
          >
            TIN
          </Typography>
          <TextField
            variant="outlined"
            name="tin"
            value={formData.tin}
            onChange={handleChange}
            fullWidth
            InputLabelProps={{ shrink: false }}
            disabled={!editMode}
          />
        </Grid>{" "}
        <Grid item xs={12} sm={3}>
          <Typography
            sx={{
              fontSize: "14px",
              fontFamily: "Montserrat, sans-serif",
              fontWeight: 600,
              color: "#000",
              mb: 0.5,
            }}
          >
            SSS
          </Typography>
          <TextField
            variant="outlined"
            name="sss"
            value={formData.sss}
            onChange={handleChange}
            fullWidth
            InputLabelProps={{ shrink: false }}
            disabled={!editMode}
          />
        </Grid>{" "}
        <Grid item xs={12} sm={3}>
          <Typography
            sx={{
              fontSize: "14px",
              fontFamily: "Montserrat, sans-serif",
              fontWeight: 600,
              color: "#000",
              mb: 0.5,
            }}
          >
            PhilHealth
          </Typography>
          <TextField
            variant="outlined"
            name="philhealth"
            value={formData.philhealth}
            onChange={handleChange}
            fullWidth
            InputLabelProps={{ shrink: false }}
            disabled={!editMode}
            inputProps={{ maxLength: 13 }}
          />
        </Grid>{" "}
        <Grid item xs={12} sm={3}>
          <Typography
            sx={{
              fontSize: "14px",
              fontFamily: "Montserrat, sans-serif",
              fontWeight: 600,
              color: "#000",
              mb: 0.5,
            }}
          >
            Pag Ibig ID Number
          </Typography>
          <TextField
            variant="outlined"
            name="pagibig"
            value={formData.pagibig}
            onChange={handleChange}
            fullWidth
            InputLabelProps={{ shrink: false }}
            disabled={!editMode}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <Typography
            sx={{
              fontSize: "14px",
              fontFamily: "Montserrat, sans-serif",
              fontWeight: 600,
              color: "#000",
              mb: 0.5,
            }}
          >
            BPI Account
          </Typography>
          <TextField
            variant="outlined"
            name="bpiAccount"
            value={formData.bpiAccount}
            onChange={handleChange}
            fullWidth
            InputLabelProps={{ shrink: false }}
            disabled={!editMode}
            inputProps={{ maxLength: 16 }}
          />
        </Grid>
      </Grid>

      <Typography
        sx={{
          fontSize: "15px",
          fontFamily: "Montserrat, sans-serif",
          fontWeight: 800,
          color: "#000",
          mb: 1,
          mt: 2,
        }}
      >
        Emergency Contact Details
      </Typography>

      {Array.isArray(formData.emergencyContacts) &&
        formData.emergencyContacts.map((contact, index) => (
          <Grid container spacing={2} key={index} sx={{ mb: 1 }}>
            <Grid item xs={12} sm={3}>
              <Typography
                sx={{
                  fontSize: "14px",
                  fontFamily: "Montserrat, sans-serif",
                  fontWeight: 600,
                  color: "#000",
                  mb: 0.5,
                }}
              >
                Name of Emergency Contact Person
              </Typography>
              <TextField
                variant="outlined"
                value={contact.contactPersonName}
                onChange={(e) =>
                  handleEmergencyContactChange(
                    index,
                    "contactPersonName",
                    e.target.value
                  )
                }
                fullWidth
                disabled={!editMode}
                inputProps={{ maxLength: 256 }}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <Typography
                sx={{
                  fontSize: "14px",
                  fontFamily: "Montserrat, sans-serif",
                  fontWeight: 600,
                  color: "#000",
                  mb: 0.5,
                }}
              >
                Emergency Contact #
              </Typography>
              <TextField
                variant="outlined"
                value={contact.emergencyContactNumber}
                onChange={(e) => {
                  const inputValue = e.target.value.replace(/[^0-9+]/g, "");
                  handleEmergencyContactChange(
                    index,
                    "emergencyContactNumber",
                    inputValue
                  );
                }}
                fullWidth
                disabled={!editMode}
                inputProps={{ maxLength: 13 }}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <Typography
                sx={{
                  fontSize: "14px",
                  fontFamily: "Montserrat, sans-serif",
                  fontWeight: 600,
                  color: "#000",
                  mb: 0.5,
                }}
              >
                Relationship to the contact person
              </Typography>
              <Select
                variant="outlined"
                value={contact.relationship}
                onChange={(e) =>
                  handleEmergencyContactChange(
                    index,
                    "relationship",
                    e.target.value as string
                  )
                }
                fullWidth
                disabled={!editMode}
              >
                <MenuItem value="Parent">Parent</MenuItem>
                <MenuItem value="Siblings">Siblings</MenuItem>
                <MenuItem value="Spouse">Spouse</MenuItem>
                <MenuItem value="Partner">Partner</MenuItem>
                <MenuItem value="Guardian">Guardian</MenuItem>
                <MenuItem value="Offspring">Offspring</MenuItem>
              </Select>
            </Grid>
            <Grid item xs={12} sm={3}>
              <Typography
                sx={{
                  fontSize: "14px",
                  fontFamily: "Montserrat, sans-serif",
                  fontWeight: 600,
                  color: "#000",
                  mb: 0.5,
                }}
              >
                Contact Person Address
              </Typography>
              <TextField
                variant="outlined"
                value={contact.address}
                onChange={(e) =>
                  handleEmergencyContactChange(index, "address", e.target.value)
                }
                fullWidth
                disabled={!editMode}
                inputProps={{ maxLength: 256 }}
              />
            </Grid>

            <Grid item xs={12}>
              {editMode && index !== 0 && (
                <Button
                  variant="contained"
                  startIcon={<DeleteIcon />}
                  onClick={() => deleteEmergencyContact(index)}
                  sx={{
                    backgroundColor: "#C70000",
                    "&:hover": { backgroundColor: "#C70000" },
                  }}
                >
                  Delete
                </Button>
              )}
            </Grid>
          </Grid>
        ))}

      {editMode && (
        <Grid item xs={12}>
          <Button
            variant="outlined"
            color="primary"
            startIcon={<AddIcon />}
            onClick={addEmergencyContact}
          >
            Add Emergency Contact
          </Button>
        </Grid>
      )}

      <Typography
        sx={{
          fontSize: "15px",
          fontFamily: "Montserrat, sans-serif",
          fontWeight: 800,
          color: "#000",
          mb: 1,
          mt: 2,
        }}
      >
        Prior Work Experience (MOST RECENT FOR THE PAST 3 YEARS)
      </Typography>

      {Array.isArray(formData.priorWorkExp) &&
        formData.priorWorkExp.map((workExp, index) => (
          <Grid container spacing={2} key={index} sx={{ mb: 1 }}>
            <Grid item xs={12} sm={3}>
              <Typography
                sx={{
                  fontSize: "14px",
                  fontFamily: "Montserrat, sans-serif",
                  fontWeight: 600,
                  color: "#000",
                  mb: 0.5,
                }}
              >
                Company Name
              </Typography>
              <TextField
                variant="outlined"
                value={workExp.companyName}
                onChange={(e) =>
                  handleWorkExpChange(index, "companyName", e.target.value)
                }
                fullWidth
                disabled={!editMode}
                inputProps={{ maxLength: 256 }}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <Typography
                sx={{
                  fontSize: "14px",
                  fontFamily: "Montserrat, sans-serif",
                  fontWeight: 600,
                  color: "#000",
                  mb: 0.5,
                }}
              >
                Industry
              </Typography>
              <TextField
                variant="outlined"
                value={workExp.industry}
                onChange={(e) =>
                  handleWorkExpChange(index, "industry", e.target.value)
                }
                fullWidth
                disabled={!editMode}
                inputProps={{ maxLength: 256 }}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <Typography
                sx={{
                  fontSize: "14px",
                  fontFamily: "Montserrat, sans-serif",
                  fontWeight: 600,
                  color: "#000",
                  mb: 0.5,
                }}
              >
                Start Date
              </Typography>
              <TextField
                variant="outlined"
                type="month"
                name="startDate"
                value={workExp.startDate}
                onChange={(e) =>
                  handleWorkExpChange(
                    index,
                    "startDate",
                    e.target.value as string
                  )
                }
                fullWidth
                InputLabelProps={{ shrink: true }}
                disabled={!editMode}
                inputProps={{ maxLength: 256 }}
              />
            </Grid>

            <Grid item xs={12} sm={3}>
              <Typography
                sx={{
                  fontSize: "14px",
                  fontFamily: "Montserrat, sans-serif",
                  fontWeight: 600,
                  color: "#000",
                  mb: 0.5,
                }}
              >
                End Date
              </Typography>
              <TextField
                variant="outlined"
                type="month"
                name="endDate"
                value={workExp.endDate}
                onChange={(e) =>
                  handleWorkExpChange(
                    index,
                    "endDate",
                    e.target.value as string
                  )
                }
                fullWidth
                InputLabelProps={{ shrink: true }}
                disabled={!editMode}
                inputProps={{ maxLength: 256, min: workExp.startDate }}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <Typography
                sx={{
                  fontSize: "14px",
                  fontFamily: "Montserrat, sans-serif",
                  fontWeight: 600,
                  color: "#000",
                  mb: 0.5,
                }}
              >
                Position
              </Typography>
              <TextField
                variant="outlined"
                value={workExp.position}
                onChange={(e) =>
                  handleWorkExpChange(index, "position", e.target.value)
                }
                fullWidth
                disabled={!editMode}
                inputProps={{ maxLength: 256 }}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <Typography
                sx={{
                  fontSize: "14px",
                  fontFamily: "Montserrat, sans-serif",
                  fontWeight: 600,
                  color: "#000",
                  mb: 0.5,
                }}
              >
                Salary
              </Typography>
              <TextField
                variant="outlined"
                value={workExp.salary}
                onChange={(e) =>
                  handleWorkExpChange(index, "salary", e.target.value)
                }
                fullWidth
                disabled={!editMode}
                inputProps={{ maxLength: 256 }}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <Typography
                sx={{
                  fontSize: "14px",
                  fontFamily: "Montserrat, sans-serif",
                  fontWeight: 600,
                  color: "#000",
                  mb: 0.5,
                }}
              >
                Reason for Leaving
              </Typography>
              <TextField
                variant="outlined"
                value={workExp.reasonOfLeaving}
                onChange={(e) =>
                  handleWorkExpChange(index, "reasonOfLeaving", e.target.value)
                }
                fullWidth
                disabled={!editMode}
                inputProps={{ maxLength: 256 }}
              />
            </Grid>

            <Grid item xs={12}>
              {editMode && index !== 0 && (
                <Button
                  variant="contained"
                  startIcon={<DeleteIcon />}
                  onClick={() => deleteWorkExp(index)}
                  sx={{
                    backgroundColor: "#C70000",
                    "&:hover": { backgroundColor: "#C70000" },
                  }}
                >
                  Delete
                </Button>
              )}
            </Grid>
          </Grid>
        ))}

      {editMode && (
        <Grid item xs={12}>
          <Button
            variant="outlined"
            color="primary"
            startIcon={<AddIcon />}
            onClick={addWorkExp}
          >
            Add Work Experience
          </Button>
        </Grid>
      )}
      <Typography
        sx={{
          fontSize: "15px",
          fontFamily: "Montserrat, sans-serif",
          fontWeight: 800,
          color: "#000",
          mb: 1,
          mt: 2,
        }}
      >
        Name of Character References
      </Typography>

      {Array.isArray(formData.characterReference) &&
        formData.characterReference.map((charRef, index) => (
          <Grid container spacing={2} key={index} sx={{ mb: 1 }}>
            <Grid item xs={12} sm={3}>
              <Typography
                sx={{
                  fontSize: "14px",
                  fontFamily: "Montserrat, sans-serif",
                  fontWeight: 600,
                  color: "#000",
                  mb: 0.5,
                }}
              >
                Name
              </Typography>
              <TextField
                variant="outlined"
                value={charRef.name}
                onChange={(e) =>
                  handleCharacterRefChange(index, "name", e.target.value)
                }
                fullWidth
                disabled={!editMode}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <Typography
                sx={{
                  fontSize: "14px",
                  fontFamily: "Montserrat, sans-serif",
                  fontWeight: 600,
                  color: "#000",
                  mb: 0.5,
                }}
              >
                Email Address
              </Typography>
              <TextField
                variant="outlined"
                value={charRef.emailAddress}
                onChange={(e) =>
                  handleCharacterRefChange(
                    index,
                    "emailAddress",
                    e.target.value
                  )
                }
                fullWidth
                disabled={!editMode}
                inputProps={{ maxLength: 256 }}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <Typography
                sx={{
                  fontSize: "14px",
                  fontFamily: "Montserrat, sans-serif",
                  fontWeight: 600,
                  color: "#000",
                  mb: 0.5,
                }}
              >
                Work Position
              </Typography>
              <TextField
                variant="outlined"
                value={charRef.workPosition}
                onChange={(e) =>
                  handleCharacterRefChange(
                    index,
                    "workPosition",
                    e.target.value
                  )
                }
                fullWidth
                disabled={!editMode}
                inputProps={{ maxLength: 256 }}
              />
            </Grid>

            <Grid item xs={12} sm={3}>
              <Typography
                sx={{
                  fontSize: "14px",
                  fontFamily: "Montserrat, sans-serif",
                  fontWeight: 600,
                  color: "#000",
                  mb: 0.5,
                }}
              >
                Company
              </Typography>
              <TextField
                variant="outlined"
                value={charRef.company}
                onChange={(e) =>
                  handleCharacterRefChange(index, "company", e.target.value)
                }
                fullWidth
                disabled={!editMode}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <Typography
                sx={{
                  fontSize: "14px",
                  fontFamily: "Montserrat, sans-serif",
                  fontWeight: 600,
                  color: "#000",
                  mb: 0.5,
                }}
              >
                Relation
              </Typography>

              <TextField
                variant="outlined"
                name="relation"
                value={charRef.relation}
                onChange={(e) =>
                  handleCharacterRefChange(
                    index,
                    "relation",
                    e.target.value as string
                  )
                }
                fullWidth
                InputLabelProps={{ shrink: true }}
                disabled={!editMode}
                inputProps={{ maxLength: 256 }}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <Typography
                sx={{
                  fontSize: "14px",
                  fontFamily: "Montserrat, sans-serif",
                  fontWeight: 600,
                  color: "#000",
                  mb: 0.5,
                }}
              >
                Contact Number
              </Typography>
              <TextField
                variant="outlined"
                value={charRef.contactDetail}
                onChange={(e) =>
                  handleCharacterRefChange(
                    index,
                    "contactDetail",
                    e.target.value
                  )
                }
                fullWidth
                disabled={!editMode}
                inputProps={{ maxLength: 13 }}
              />
            </Grid>
            <Grid item xs={12}>
              {editMode && index !== 0 && (
                <Button
                  variant="contained"
                  startIcon={<DeleteIcon />}
                  onClick={() => deleteCharRef(index)}
                  sx={{
                    backgroundColor: "#C70000",
                    "&:hover": { backgroundColor: "#C70000" },
                  }}
                >
                  Delete
                </Button>
              )}
            </Grid>
          </Grid>
        ))}

      {editMode && (
        <Grid item xs={12}>
          <Button
            variant="outlined"
            color="primary"
            startIcon={<AddIcon />}
            onClick={addCharRef}
          >
            Add Character Reference
          </Button>
        </Grid>
      )}

      <Grid item xs={12} sm={3}></Grid>
      <Typography
        sx={{
          fontSize: "15px",
          fontFamily: "Montserrat, sans-serif",
          fontWeight: 800,
          color: "#000",
          mb: 1,
          mt: 2,
        }}
      >
        Educational Background
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={3}>
          <Typography
            sx={{
              fontSize: "14px",
              fontFamily: "Montserrat, sans-serif",
              fontWeight: 600,
              color: "#000",
              mb: 0.5,
            }}
          >
            Highest Educational Attainment
          </Typography>
          <Select
            variant="outlined"
            name="highestEducation"
            value={formData.highestEducation || ""}
            onChange={(e) => handleChange(e as SelectChangeEvent<string>)}
            disabled={!editMode}
            fullWidth
            MenuProps={MenuProps}
          >
            <MenuItem value="High School Graduate">
              High School Graduate
            </MenuItem>
            <MenuItem value="K12 Graduate">K12 Graduate</MenuItem>
            <MenuItem value="Bachelor's Degree Graduate (4 yrs. course graduate)">
              Bachelor's Degree Graduate (4 yrs. course graduate)
            </MenuItem>
            <MenuItem value="Associate's Degree Graduate (2 yrs. course graduate)">
              Associate's Degree Graduate (2 yrs. course graduate)
            </MenuItem>
            <MenuItem value="Master's Degree Graduate ">
              Master's Degree Graduate{" "}
            </MenuItem>
            <MenuItem value="Doctorate Degree Graduate ">
              Doctorate Degree Graduate{" "}
            </MenuItem>
          </Select>
        </Grid>
        <Grid item xs={12} sm={3}>
          <Typography
            sx={{
              fontSize: "14px",
              fontFamily: "Montserrat, sans-serif",
              fontWeight: 600,
              color: "#000",
              mb: 0.5,
            }}
          >
            School
          </Typography>
          <TextField
            variant="outlined"
            name="school"
            value={formData.educationalBackground?.[0]?.school || ""}
            onChange={(e) =>
              setFormData((prev) => ({
                ...prev,
                educationalBackground: [
                  {
                    ...prev.educationalBackground?.[0],
                    school: e.target.value,
                  },
                ],
              }))
            }
            fullWidth
            InputLabelProps={{ shrink: false }}
            disabled={!editMode}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <Typography
            sx={{
              fontSize: "14px",
              fontFamily: "Montserrat, sans-serif",
              fontWeight: 600,
              color: "#000",
              mb: 0.5,
            }}
          >
            Course
          </Typography>
          <TextField
            variant="outlined"
            name="course"
            value={formData.educationalBackground?.[0]?.course || ""}
            onChange={(e) =>
              setFormData((prev) => ({
                ...prev,
                educationalBackground: [
                  {
                    ...prev.educationalBackground?.[0],
                    course: e.target.value,
                  },
                ],
              }))
            }
            fullWidth
            InputLabelProps={{ shrink: false }}
            disabled={!editMode}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <Typography
            sx={{
              fontSize: "14px",
              fontFamily: "Montserrat, sans-serif",
              fontWeight: 600,
              color: "#000",
              mb: 0.5,
            }}
          >
            Year Graduated
          </Typography>
          <TextField
            variant="outlined"
            name="dateGraduated"
            value={formData.educationalBackground?.[0]?.dateGraduated || ""}
            onChange={(e) => {
              const inputValue = e.target.value.replace(/[^0-9]/g, ""); // Allow only numbers
              setFormData((prev) => ({
                ...prev,
                educationalBackground: [
                  {
                    ...prev.educationalBackground?.[0],
                    dateGraduated: inputValue,
                  },
                ],
              }));
            }}
            inputProps={{ maxLength: 4 }}
            fullWidth
            InputLabelProps={{ shrink: true }}
            disabled={!editMode}
          />
        </Grid>
      </Grid>
      <Typography
        sx={{
          fontSize: "15px",
          fontFamily: "Montserrat, sans-serif",
          fontWeight: 800,
          color: "#000",
          mb: 1,
          mt: 2,
        }}
      >
        Additional Details
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={3}>
          <Typography
            sx={{
              fontSize: "14px",
              fontFamily: "Montserrat, sans-serif",
              fontWeight: 600,
              color: "#000",
              mb: 0.5,
            }}
          >
            Reason for Joining
          </Typography>

          <Select
            variant="outlined"
            name="reasonOfJoining"
            value={formData.reasonOfJoining || ""}
            onChange={(e) => handleChange(e as SelectChangeEvent<string>)}
            disabled={!editMode}
            fullWidth
            MenuProps={MenuProps}
          >
            <MenuItem value="CAREER GROWTH">CAREER GROWTH</MenuItem>
            <MenuItem value="CAREER SHIFT/CHANGE IN FUNCTION">
              CAREER SHIFT/CHANGE IN FUNCTION
            </MenuItem>
            <MenuItem value="COMPANY REPUTATION">COMPANY REPUTATION</MenuItem>
            <MenuItem value="LEARNING AND DEVELOPMENT">
              LEARNING AND DEVELOPMENT
            </MenuItem>
            <MenuItem value="PEOPLE AND PRACTICES">
              PEOPLE AND PRACTICES
            </MenuItem>
            <MenuItem value="SALARY AND BENEFITS">SALARY AND BENEFITS</MenuItem>
            <MenuItem value="WORK SCHEDULE">WORK SCHEDULE</MenuItem>
            <MenuItem value="WORK LOCATION">WORK LOCATION</MenuItem>
          </Select>
        </Grid>
        <Grid item xs={12} sm={3}>
          <Typography
            sx={{
              fontSize: "14px",
              fontFamily: "Montserrat, sans-serif",
              fontWeight: 600,
              color: "#000",
              mb: 0.5,
            }}
          >
            Position Applying For
          </Typography>
          <TextField
            variant="outlined"
            name="positionApplyingFor"
            value={formData.positionApplyingFor}
            onChange={handleChange}
            fullWidth
            InputLabelProps={{ shrink: false }}
            disabled={!editMode}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <Typography
            sx={{
              fontSize: "14px",
              fontFamily: "Montserrat, sans-serif",
              fontWeight: 600,
              color: "#000",
              mb: 0.5,
            }}
          >
            Previous Monthly Salary
          </Typography>
          <TextField
            variant="outlined"
            name="previousMonthlySalary"
            value={formData.previousMonthlySalary}
            onChange={handleChange}
            fullWidth
            InputLabelProps={{ shrink: false }}
            disabled={!editMode}
          />
        </Grid>
      </Grid>
      <Grid item xs={12} sm={3}></Grid>
      <Typography
        sx={{
          fontSize: "15px",
          fontFamily: "Montserrat, sans-serif",
          fontWeight: 800,
          color: "#000",
          mb: 1,
          mt: 2,
        }}
      >
        Name of Relatives
      </Typography>

      {Array.isArray(formData.nameOfRelatives) &&
        formData.nameOfRelatives.map((relatives, index) => (
          <Grid container spacing={2} key={index} sx={{ mb: 1 }}>
            <Grid item xs={12} sm={3}>
              <Typography
                sx={{
                  fontSize: "14px",
                  fontFamily: "Montserrat, sans-serif",
                  fontWeight: 600,
                  color: "#000",
                  mb: 0.5,
                }}
              >
                Name of relatives or spouse working with Asticom or any Ayala
                companies or subsidiaries or its clients
              </Typography>
              <TextField
                variant="outlined"
                value={relatives.name}
                onChange={(e) =>
                  handleRelativesChange(index, "name", e.target.value)
                }
                fullWidth
                disabled={!editMode}
              />
            </Grid>
            <Grid item xs={12}>
              {editMode && index !== 0 && (
                <Button
                  variant="contained"
                  startIcon={<DeleteIcon />}
                  onClick={() => deleteRelatives(index)}
                  sx={{
                    backgroundColor: "#C70000",
                    "&:hover": { backgroundColor: "#C70000" },
                  }}
                >
                  Delete
                </Button>
              )}
            </Grid>
          </Grid>
        ))}

      {editMode && (
        <Grid item xs={12}>
          <Button
            variant="outlined"
            color="primary"
            startIcon={<AddIcon />}
            onClick={addRelatives}
          >
            Add Relatives
          </Button>
        </Grid>
      )}
      <Grid item xs={12} sm={3}></Grid>

      <Grid container spacing={2}>
        <Grid item xs={12} sm={3}>
          <Typography
            sx={{
              fontSize: "14px",
              fontFamily: "Montserrat, sans-serif",
              fontWeight: 600,
              color: "#000",
              mb: 0.5,
            }}
          >
            Would you recommend Asticom Technology to your friends and family?
          </Typography>
          <FormControl fullWidth>
            <Select
              variant="outlined"
              name="recommendAsticom"
              value={formData.recommendAsticom || ""}
              onChange={(e) => handleChange(e as SelectChangeEvent<string>)}
              disabled={!editMode}
            >
              <MenuItem value="yes">Yes</MenuItem>
              <MenuItem value="no">No</MenuItem>
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={3}>
          <Typography
            sx={{
              fontSize: "14px",
              fontFamily: "Montserrat, sans-serif",
              fontWeight: 600,
              color: "#000",
              mb: 0.5,
            }}
          >
            Who is your Assigned Recruiter?
          </Typography>
          <FormControl fullWidth>
            <TextField
              variant="outlined"
              name="sss"
              value={assignedRecruiter}
              fullWidth
              InputLabelProps={{ shrink: false }}
              disabled
              sx={{ marginTop: 2.6 }}
            />
          </FormControl>
        </Grid>
      </Grid>
      <Grid item xs={12} sm={3} sx={{ marginBottom: "150px" }}></Grid>
      <CustomModal
        open={isModalOpen}
        onClose={() => {
          setIsModalOpen(false);
          window.location.reload();
        }}
        title={"Successfully Updated"}
        message={"Your profile has been successfully updated."}
        success={true}
      />
    </>
  );
};

export default ProfileContent;
