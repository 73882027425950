import React, { useEffect, useState } from "react";
import axios, { AxiosError } from "axios";
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
  Typography,
  Button,
  MenuItem,
  TableCell,
  Stack,
  Divider,
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import moment from "moment";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import DownloadIcon from "@mui/icons-material/Download";
import StyledMenu from "../../../components/TableStyle/StyledMenu";
import StyledTableCell from "../../../components/TableStyle/StyledTableCell";
import StyledTableRow from "../../../components/TableStyle/StyledTableRow";
import { saveAs } from "file-saver";
import Dot from "../../../components/TableStyle/Dot";
import UploadIcon from "@mui/icons-material/Upload";
import UploadRequirement from "./UploadRequirement";
import CustomModal from "../../../components/CustomModal";
import Loader from "../../../components/Loader";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";

interface Applicant {
  applicantRequirementId: string;
  requirement: string;
  prioritization: string;
  deadline: string;
  dateCompleted: string;
  documentStatus: string;
  jobId: string;
  applicantId: string;
  prfId: string;
  jobOfferId: string;
  userType: string;
  templatePath: string;
  uploadedDocument: string;
  s3_path: string;
  completionPercentage: number;
  withTemplate: string;
}

interface ApiResponse {
  result: Applicant[];
}

const cellTextStyle = {
  fontFamily: "Montserrat, sans-serif",
  fontWeight: "400",
  fontSize: "13px",
  whiteSpace: "normal",
};

const ApplicationContent = () => {
  const apiKey = process.env.REACT_APP_RECRUITMENT_API;
  const [data, setData] = useState<Applicant[]>([]);

  const columns = [
    { id: "no", label: "No." },
    { id: "requirement", label: "Requirement" },
    { id: "deadline", label: "Deadline" },
    { id: "dateCompleted", label: "Date Completed" },
    { id: "documentStatus", label: "Document Status" },
    { id: "actions", label: "Actions" },
  ];
  const [loading, setLoading] = useState(false);
  const [loadingDownloadFile, setLoadingdDownloadFile] = useState(false);
  const [loadingDownloadTemplate, setLoadingdDownloadTemplate] =
    useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [isUploadRequirementModal, setIsUploadRequirementModal] =
    useState(false);

  const open = Boolean(anchorEl);

  const [selectedApplicant, setSelectedApplicant] = useState<Applicant | null>(
    null
  );

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    setLoading(true);
    try {
      const accessToken = localStorage.getItem("accessToken");
      const jobOfferId = localStorage.getItem("jobOfferIdValue");
      const response = await axios.post(
        `${apiKey}/api/applicant/my-requirement`,
        {
          jobOfferId: jobOfferId,
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      const applicantRequirements = response.data.result;
      setData(applicantRequirements);
  
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleFileDownloadFile = async (isTemplate: boolean, data: any) => {
    setLoadingdDownloadFile(true);
    const accessToken = localStorage.getItem("accessToken");
    try {
      const response = await axios.get(`${apiKey}/api/applicant/document-file`, {
        params: {
          downloadType: isTemplate ? "template" : "file",
          applicantRequirementId: selectedApplicant?.applicantRequirementId,
        },
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        responseType: "arraybuffer",
      });
      if (response.status === 200) {
        const contentType = response.headers["content-type"];

        if (contentType === "application/pdf") {
          const file = new Blob([response.data], { type: contentType });
          const fileURL = URL.createObjectURL(file);
          window.open(fileURL, "_blank");
        } else {
          const blob = new Blob([response.data], { type: contentType });

          let fileName = selectedApplicant?.s3_path;
          if (contentType === "application/msword") {
            fileName += ".doc";
          } else if (
            contentType ===
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
          ) {
            fileName += ".docx";
          } else if (
            contentType ===
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          ) {
            fileName += ".xlsx";
          } else if (contentType === "application/vnd.ms-powerpoint") {
            fileName += ".ppt";
          } else if (
            contentType ===
            "application/vnd.openxmlformats-officedocument.presentationml.presentation"
          ) {
            fileName += ".pptx";
          } else {
            fileName += "." + contentType.split("/")[1];
          }

          if (contentType === "application/msword") {
            const fileURL = URL.createObjectURL(blob);
            window.open(fileURL, "_blank");
          } else {
            saveAs(blob, fileName);
          }
        }
        setLoadingdDownloadFile(false);
      }
    } catch (error) {
      console.error("Error downloading file:", error);
      setLoadingdDownloadFile(false);
    }
  };

  const handleDownloadTemplate = async () => {
    setLoadingdDownloadTemplate(true);
    const accessToken = localStorage.getItem("accessToken");
    try {
      const response = await axios.get(`${apiKey}/api/applicant/document-file`, {
        params: {
          downloadType: "template",
          applicantRequirementId: selectedApplicant?.applicantRequirementId,
        },
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        responseType: "arraybuffer",
      });
      if (response.status === 200) {
        const contentType = response.headers["content-type"];

        if (contentType === "application/pdf") {
          const file = new Blob([response.data], { type: contentType });
          const fileURL = URL.createObjectURL(file);
          window.open(fileURL, "_blank");
        } else {
          const blob = new Blob([response.data], { type: contentType });

          let fileName = selectedApplicant?.requirement;
          if (contentType === "application/msword") {
            fileName += ".doc";
          } else if (
            contentType ===
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
          ) {
            fileName += ".docx";
          } else if (
            contentType ===
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          ) {
            fileName += ".xlsx";
          } else if (contentType === "application/vnd.ms-powerpoint") {
            fileName += ".ppt";
          } else if (
            contentType ===
            "application/vnd.openxmlformats-officedocument.presentationml.presentation"
          ) {
            fileName += ".pptx";
          } else {
            fileName += "." + contentType.split("/")[1];
          }

          if (contentType === "application/msword") {
            const fileURL = URL.createObjectURL(blob);
            window.open(fileURL, "_blank");
          } else {
            saveAs(blob, fileName);
          }
        }
        setLoadingdDownloadTemplate(false);
      }
    } catch (error) {
      console.error("Error downloading file:", error);
      setLoadingdDownloadTemplate(false);
    }
  };

  const DocumentStatus: React.FC<{ status: string }> = ({ status }) => {
    let color;
    let title;

    const trimmedStatus = status.trim().toLowerCase();

    switch (trimmedStatus) {
      case "0":
      case "pending":
        color = "warning";
        title = "Pending";
        break;
      case "1":
      case "validated":
        color = "success";
        title = "Validated";
        break;
      case "2":
      case "return contract":
        color = "error";
        title = "Return Document";
        break;
      case "for validation":
        color = "primary";
        title = "For Validation";
        break;
      case "uploaded":
        color = "primary";
        title = "Uploaded";
        break;

      default:
        color = "primary";
        title = "None";
    }

    return (
      <Stack direction="row" spacing={1} alignItems="center">
        <Dot color={color} />
        <Typography
          sx={{
            fontSize: "13px",
            fontFamily: "Montserrat, sans-serif",

            fontWeight: "400",
          }}
        >
          {title}
        </Typography>
      </Stack>
    );
  };

  const handleClick = (
    event: React.MouseEvent<HTMLElement>,
    applicant: Applicant
  ) => {
    setAnchorEl(event.currentTarget);
    setSelectedApplicant(applicant);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOpenUploadRequirementModal = (applicant: Applicant) => {
    setIsUploadRequirementModal(true);
  };
  const handleCloseUploadRequirementModal = () => {
    setIsUploadRequirementModal(false);
  };


  const handleButtonClick = () => {
    const googleDriveLink =
      "https://drive.google.com/file/d/1AfqTsWje-u-YkZKNcNWO-qDOMM0fRqEy/view?usp=sharing";

    // Open the link in the same window
    window.open(googleDriveLink, "_blank");
  };



  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          marginBottom: "5px",
        }}
      >
        <Typography
        variant="subtitle1"
        color="primary"
        sx={{fontFamily: "Montserrat, sans-serif", textDecoration: 'underline'}}
            onClick={handleButtonClick}>
       Instruction to complete your requirement
        </Typography>
      
      </div>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <TableContainer component={Paper}>
          <Table size="small" sx={{ minWidth: 650 }}>
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <StyledTableCell
                    key={column.id}
                    sx={{
                      fontFamily: "Montserrat, sans-serif",
                      fontWeight: "800",
                    }}
                  >
                    {column.label}
                  </StyledTableCell>
                ))}
              </TableRow>
            </TableHead>

            <TableBody>
              {loading ? (
                <TableRow>
                  <TableCell colSpan={columns.length} align="center">
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        height: "100%",
                      }}
                    >
                      <Loader />
                    </Box>
                  </TableCell>
                </TableRow>
              ) : data.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={columns.length} align="center">
                    <Typography
                      variant="h5"
                      sx={{ fontFamily: "Montserrat, sans-serif" }}
                    >
                      No data available in the table
                    </Typography>
                  </TableCell>
                </TableRow>
              ) : (
                data.map((row, index) => (
                  <StyledTableRow
                    key={row.applicantRequirementId}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell sx={cellTextStyle}>{index + 1}</TableCell>

                    <TableCell sx={cellTextStyle}>{row.requirement}</TableCell>

                    <TableCell sx={cellTextStyle}>
                      {moment(row.deadline).format("MMM DD, YYYY")}
                    </TableCell>
                    <TableCell sx={cellTextStyle}>
                      {moment(row.dateCompleted).isValid()
                        ? moment(row.dateCompleted).format("MMM DD, YYYY")
                        : ""}
                    </TableCell>
                    <TableCell sx={cellTextStyle}>
                      <DocumentStatus status={row.documentStatus} />
                    </TableCell>
                    <TableCell>
                      <div>
                        <Button
                          id="demo-customized-button"
                          aria-controls={
                            open ? "demo-customized-menu" : undefined
                          }
                          aria-haspopup="true"
                          aria-expanded={open ? "true" : undefined}
                          disableElevation
                          onClick={(event) => handleClick(event, row)}
                        >
                          <MoreVertIcon color="primary" />
                        </Button>
                        <StyledMenu
                          id="demo-customized-menu"
                          MenuListProps={{
                            "aria-labelledby": "demo-customized-button",
                          }}
                          anchorEl={anchorEl}
                          open={open}
                          onClose={handleClose}
                        >
                          {(selectedApplicant?.uploadedDocument || selectedApplicant?.templatePath) && (
                            <MenuItem
                              onClick={() => handleFileDownloadFile(!!!selectedApplicant?.uploadedDocument, selectedApplicant?.uploadedDocument)}
                              disableRipple
                            >
                              {loadingDownloadFile ? (
                                <CircularProgress
                                  size={24}
                                  sx={{ marginRight: 1 }}
                                  color="primary"
                                />
                              ) : (
                                <DownloadIcon sx={{ marginRight: 1 }} />
                              )}
                              Download File
                            </MenuItem>
                          )}
                          {selectedApplicant?.documentStatus !==
                            "Validated" && (
                            <MenuItem
                              onClick={() =>
                                handleOpenUploadRequirementModal(row)
                              }
                              disableRipple
                            >
                              <UploadIcon sx={{ marginRight: 1 }} />
                              Upload
                            </MenuItem>
                          )}

                          {selectedApplicant?.withTemplate === "yes" && [
                            <Divider key="divider" />,
                            <MenuItem
                              key="downloadTemplate"
                              onClick={handleDownloadTemplate}
                              disableRipple
                            >
                              {loadingDownloadTemplate ? (
                                <CircularProgress
                                  size={24}
                                  sx={{ marginRight: 1 }}
                                  color="primary"
                                />
                              ) : (
                                <DownloadIcon sx={{ marginRight: 1 }} />
                              )}
                              Download Template
                            </MenuItem>,
                          ]}
                          {selectedApplicant?.requirement === "BPI Account Document" && (
                            <MenuItem
                              key="downloadTemplate"
                              onClick={handleDownloadTemplate}
                              disableRipple
                            >
                              {loadingDownloadTemplate ? (
                                <CircularProgress
                                  size={24}
                                  sx={{ marginRight: 1 }}
                                  color="primary"
                                />
                              ) : (
                                <DownloadIcon sx={{ marginRight: 1 }} />
                              )}
                              Download Template
                            </MenuItem>
                          )}
                          {/* {selectedApplicant?.s3_path === "special_requirement" && (
                            <MenuItem
                              key="downloadTemplate"
                              onClick={handleDownloadTemplate}
                              disableRipple
                            >
                              {loadingDownloadTemplate ? (
                                <CircularProgress
                                  size={24}
                                  sx={{ marginRight: 1 }}
                                  color="primary"
                                />
                              ) : (
                                <DownloadIcon sx={{ marginRight: 1 }} />
                              )}
                              Download 
                            </MenuItem>
                          )} */}
                        </StyledMenu>
                      </div>
                    </TableCell>
                  </StyledTableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>

      <CustomModal
        isOpen={isUploadRequirementModal}
        handleClose={handleCloseUploadRequirementModal}
        content={<UploadRequirement applicant={selectedApplicant} />}
      />
    </div>
  );
};

export default ApplicationContent;
