import React, { useState, useEffect, ChangeEvent } from "react";
import Box from "@mui/material/Box";
import ButtonField from "../../../components/Button";
import Typography from "@mui/material/Typography";
import axios, { AxiosError } from "axios";
import { Grid } from "@mui/material";
import { Applicant } from "../../Recruiter/RequirementProgress/RequirementDetailsContent";
import TextInput from "../../../components/TextInput";
import CircularProgress from "@mui/material/CircularProgress";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import TextField from "@mui/material/TextField";
import { Button } from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { styled } from "@mui/system";
import CustomModal from "../../../components/Modal";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

interface AddInternalRequirementsProps {
  applicantRequirements: Applicant[] | string[];
}

interface FormData {
  requirement: string;
  jobId?: string;
  applicantId?: string;
  prfId?: string;
  jobOfferId?: string;
  specialRequirementId: string;
  deadline: string | null;
  userType: string;
  withTemplate: string;
}

interface List {
  id: string;
  requirement: string;
  groupName: string;
  withTemplate: string;
}

export default function AddInternalRequirements({
  applicantRequirements,
}: AddInternalRequirementsProps) {
  const apiKey = process.env.REACT_APP_RECRUITMENT_API;
  const [formData, setFormData] = useState<FormData>({
    requirement: "",
    specialRequirementId: "",
    deadline: null as string | null,
    userType: "",
    withTemplate: "",
  });
  const [formErrors, setFormErrors] = useState({
    requirement: "",
    specialRequirementId: "",
    deadline: "",
    userType: "",
    withTemplate: "",
  });

  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState({ message: "" });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [groupList, setGroupList] = useState<List[]>([]);
  const [selectedGroup, setSelectedGroup] = useState("");
  const [selectedGroupRequirement, setSelectedGroupRequirement] = useState("");
  const [requirementList, setRequirementList] = useState<List[]>([]);
  const [hasTemplate, setHasTemplate] = useState(false);
  
  useEffect(() => {
    if (applicantRequirements && applicantRequirements.length > 0) {
      if (typeof applicantRequirements[0] === "string") {
      } else {
        setFormData({
          ...formData,
          jobId: applicantRequirements[0].jobId,
          applicantId: applicantRequirements[0].applicantId,
          prfId: applicantRequirements[0].prfId,
          jobOfferId: applicantRequirements[0].jobOfferId,
        });
      }
    }
  }, [applicantRequirements]);  // Include applicantRequirements in the dependency array
  

  useEffect(() => {
    const token = localStorage.getItem("accessToken");
    axios
      .get(`${apiKey}/api/recruitment/special-group-not-paginate`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setGroupList(response.data.result);
      })
      .catch((error) => {
        console.error("Error fetching group names", error);
      });
  }, []);

  useEffect(() => {
    const token = localStorage.getItem("accessToken");
    if (selectedGroup !== 'other') {
      axios
        .get(
          `${apiKey}/api/recruitment/special-requirement-not-paginate?groupId=${selectedGroup}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          setRequirementList(response.data.result);
   
          const withGroupRequirement = requirementList.map((requirement) => requirement.requirement);

          const withTemplateValues = requirementList.map((requirement) => requirement.withTemplate);
        })
        .catch((error) => {
          console.error("Error fetching group requirements", error);
        });
    }
  }, [selectedGroup]);

  const handleAddDocument = async () => {
    setIsLoading(true);
    const token = localStorage.getItem("accessToken");

    try {
      const formDataObj = new FormData();

      // Append other form data fields
      formDataObj.append("requirement", formData.requirement);
      formDataObj.append("specialRequirementId", selectedGroupRequirement);
      formDataObj.append("deadline", formData.deadline || "");
      formDataObj.append("userType", formData.userType);
      formDataObj.append("withTemplate", formData.withTemplate);
      formDataObj.append("jobId", formData.jobId || "");
      formDataObj.append("applicantId", formData.applicantId || "");
      formDataObj.append("prfId", formData.prfId || "");
      formDataObj.append("jobOfferId", formData.jobOfferId || "");
      // Append the file
      if (selectedFile) {
        formDataObj.append("file", selectedFile, selectedFile.name);
      }
  

      const response = await axios.post(
        `${apiKey}/api/recruitment/add-special-requirement`,
        formDataObj,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.status === 200) {
        setIsModalOpen(true);
      }
    } catch (error) {
      console.log(error);
      if (axios.isAxiosError(error)) {
        const axiosError: AxiosError = error;

        if (axiosError.response) {
          const status = axiosError.response.status;

          if (status === 401) {
            setErrorMessage({
              message: "Unauthorized. Please provide a valid Bearer Token.",
            });
          } else if (status === 404) {
            setErrorMessage({
              message: "User not found.",
            });
          } else if (status === 409) {
            setErrorMessage({
              message:
                "Record already exist. / Job offer is not yet accepted. You cannot proceed to send the requirement.",
            });
          } else if (status === 413) {
            setErrorMessage({
              message: "File uploaded is too large.",
            });
          } else if (status === 415) {
            setErrorMessage({
              message: "Invalid file type. Please upload a PDF file.",
            });
          } else if (status === 422) {
            setErrorMessage({
              message:
                "Unprocessable Entity. Required fields are missing or file-related errors.",
            });
          } else if (status === 500) {
            setErrorMessage({
              message: "Internal Server Error.",
            });
          }
        }
      } else {
        console.error("Internal Server Error", error);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleRequirementChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setFormData((prevData) => ({ ...prevData, requirement: value }));
    setFormErrors((prevErrors) => ({ ...prevErrors, requirement: "" }));
  };

  const handleSelectChange = (id: string) => (e: SelectChangeEvent<string>) => {
    const value = e.target.value;
    setFormData((prevData) => ({ ...prevData, [id]: value }));
    setFormErrors((prevErrors) => ({ ...prevErrors, [id]: "" }));
    setIsLoading(false);
  };

  const handleGroupChange = async (e: SelectChangeEvent<string>) => {
    const value = e.target.value;
    if (value === "other") {
      setSelectedGroupRequirement("");
      setSelectedGroup(value);
    } else {
      setSelectedGroup(value);
    }
  };

  return (
    <Box
      component="form"
      sx={{
        "& .MuiTextField-root": {},
      }}
      noValidate
      autoComplete="off"
    >
      <Typography
        sx={{
          fontSize: "14px",
          fontFamily: "Montserrat, sans-serif",
          fontWeight: 600,
          color: "#000",
          mb: 1,
        }}
      >
        Group Name
      </Typography>

      <Select
        id="outlined-required"
        value={selectedGroup}
        onChange={handleGroupChange}
        error={!!formErrors.userType}
        sx={{ mb: 2 }}
        fullWidth
      >
        {groupList.map((group) => (
          <MenuItem key={group.id} value={group.id}>
            {group.groupName}
          </MenuItem>
        ))}
        <MenuItem key="other" value="other">
          Other Please Specify
        </MenuItem>
      </Select>
      {selectedGroup === "other" && (
        <>
          <Typography
            sx={{
              fontSize: "14px",
              fontFamily: "Montserrat, sans-serif",
              fontWeight: 600,
              color: "#000",
              mb: 1,
            }}
          >
            Requirement<span style={{ color: "red" }}>*</span>
          </Typography>
          <TextField
            variant="outlined"
            name="requirement"
            value={formData.requirement}
            fullWidth
            onChange={handleRequirementChange}
            sx={{ mb: 2 }}
          />
          <Typography
            variant="caption"
            sx={{
              color: "#d32f2f",
              ml: 2,
              mt: 0.3,
            }}
          >
            {formErrors.requirement}
          </Typography>
          <Typography
            variant="caption"
            sx={{
              color: "#d32f2f",
              ml: 2,
              mt: 0.3,
            }}
          >
            {formErrors.requirement}
          </Typography>
        </>
      )}

      {selectedGroup !== "other" && (
        <>
          <Typography
            sx={{
              fontSize: "14px",
              fontFamily: "Montserrat, sans-serif",
              fontWeight: 600,
              color: "#000",
              mb: 1,
            }}
          >
            Group Requirement
          </Typography>

          <Select
            id="outlined-required"
            value={selectedGroupRequirement}
            onChange={(e) => setSelectedGroupRequirement(e.target.value)}
            sx={{ mb: 2 }}
            fullWidth
          >
      {requirementList && requirementList.length > 0 ? (
        requirementList.map((requirement) => (
          <MenuItem key={requirement.id} value={requirement.id}>
            {requirement.requirement}
          </MenuItem>
        ))
      ) : (
        <MenuItem disabled>
          No data available
        </MenuItem>
      )}
          </Select>
        </>
      )}

      <Typography
        sx={{
          fontSize: "14px",
          fontFamily: "Montserrat, sans-serif",
          fontWeight: 600,
          color: "#000",
          mb: 1,
        }}
      >
        With Template<span style={{ color: "red" }}>*</span>
      </Typography>
      <Select
        id="outlined-required"
        value={formData.withTemplate}
        onChange={handleSelectChange("withTemplate")}
        error={!!formErrors.withTemplate}
        inputProps={{
          maxLength: 256,
        }}
        sx={{ mb: 1 }}
        fullWidth
      >
        <MenuItem value="yes">Yes</MenuItem>
        <MenuItem value="no">No</MenuItem>
        <MenuItem value="unique template">Unique Template</MenuItem>
      </Select>
      <Typography
        variant="caption"
        sx={{
          color: "#d32f2f",
          ml: 2,
          mt: 0.3,
        }}
      >
        {formErrors.withTemplate}
      </Typography>

      <Typography
        sx={{
          fontSize: "14px",
          fontFamily: "Montserrat, sans-serif",
          fontWeight: 600,
          color: "#000",
          mb: 1,
          mt: 1,
        }}
      >
        User Type<span style={{ color: "red" }}>*</span>
      </Typography>
      <Select
        id="outlined-required"
        value={formData.userType}
        onChange={handleSelectChange("userType")}
        error={!!formErrors.userType}
        sx={{ mb: 2 }}
        fullWidth
      >
        <MenuItem value="External">External</MenuItem>
        <MenuItem value="Internal">Internal</MenuItem>
      </Select>
      <Typography
        variant="caption"
        sx={{
          color: "#d32f2f",
          ml: 2,
          mt: 0.3,
        }}
      >
        {formErrors.userType}
      </Typography>

      <Typography
        sx={{
          fontSize: "14px",
          fontFamily: "Montserrat, sans-serif",
          fontWeight: 600,
          color: "#000",
          mb: 1,
        }}
      >
        Deadline<span style={{ color: "red" }}>*</span>
      </Typography>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          value={formData.deadline}
          slotProps={{ textField: { variant: "outlined" } }}
          sx={{ mb: 2 }}
          onChange={(newValue) => {
            if (newValue !== null && newValue !== "") {
              const formattedDate = dayjs(newValue).format("YYYY-MM-DD");
              setFormData({ ...formData, deadline: formattedDate });
            } else {
              setFormData({ ...formData, deadline: newValue });
            }
          }}
        />
      </LocalizationProvider>
      <Typography
        variant="caption"
        sx={{
          color: "#d32f2f",
          ml: 2,
          mt: 0.2,
        }}
      >
        {formErrors.deadline}
      </Typography>

      <div style={{ display: "flex", alignItems: "center" }}>
   {formData.withTemplate !== "no" && (
    <>
         <Button
          component="label"
          variant="contained"
          startIcon={<CloudUploadIcon />}
          sx={{
            mt: 2,
            width: "200px",
            backgroundColor: "#7901A3",
            "&:hover": {
              backgroundColor: "#7901A3",
            },
          }}
        >
          Upload file
          <VisuallyHiddenInput
            type="file"
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              // Handle the file selection here
              const selected = e.target.files?.[0] ?? null;
              setSelectedFile(selected);
            }}
          />
        </Button>
        {selectedFile && (
          <Typography variant="body1" sx={{ mt: 2, ml: 1 }}>
            {selectedFile.name}
          </Typography>
        )}</>
   )}
      </div>

      <ButtonField
        variant="contained"
        sx={{
          width: "200px",
          mt: 3,
          mb: 2,
          backgroundColor: isLoading ? "#666666" : "#7901A3",
          "&:hover": {
            backgroundColor: "#7901A3",
          },
        }}
        onClick={handleAddDocument}
        disabled={isLoading}
      >
        {isLoading ? (
          <CircularProgress size={25} style={{ color: "white" }} />
        ) : (
          "Add Document"
        )}
      </ButtonField>
      {errorMessage.message && (
        <Typography
          variant="body2"
          sx={{
            fontFamily: "Montserrat, sans-serif",
            color: "#d32f2f",
            ml: 1,
            mt: 1,
          }}
        >
          {errorMessage.message}
        </Typography>
      )}
      <CustomModal
        open={isModalOpen}
        onClose={() => {
          setIsModalOpen(false);
          window.location.reload(); // Reload the page
        }}
        title={"Successfully Added"}
        message={"Internal requirement was successfully added."}
        success={true}
      />
    </Box>
  );
}
