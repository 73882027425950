import React, { useState } from "react";
import videoThumbnail from "../../../assets/icons/WelcomeMessage.png";
import { Typography, Modal, Box, useMediaQuery  } from "@mui/material";
import Loader from "../../../components/Loader";

const VideoMessage = () => {
  const videoUrl =
    "https://drive.google.com/file/d/10voybaPQhEZsBRtMtOW12quRrsDTyyIr/preview";

  const [showModal, setShowModal] = useState(false);
  const [iframeLoading, setIframeLoading] = useState(true);
  const [iframeKey, setIframeKey] = useState(1);
  const isMobile = useMediaQuery('(max-width: 600px)');

  const handleThumbnailClick = () => {
    setShowModal(true);
    setIframeLoading(true);
    setIframeKey((prevKey) => prevKey + 1);
  };


  const handleCloseModal = () => {
    setShowModal(false);
  };
  
  const handleIframeLoad = () => {
    setIframeLoading(false);
  };

  return (
    <div
      style={{
        display: "flex",
   flexDirection: isMobile ? "column" : "row",
        alignItems: "center",
      
      }}
    >
      <div
        style={{
          textAlign: "left",
          margin: "10px", // Add margin as needed
          width: isMobile ? "100%" : "48%",
          alignSelf: "flex-start",
          marginRight: '50px'
          }}
      >
        <Typography
          variant="h6"
          sx={{
            mb: 2,
            fontWeight: 400,
            color: "#000",
            fontFamily: "Montserrat, sans-serif",
          }}
        >
          Welcome to our{" "}
          <span
            style={{
              fontWeight: 700,
              color: "#000",
              fontFamily: "Montserrat, sans-serif",
            }}
          >
            Onboarding Hub
          </span>{" "}
          - Asticom’s Onboarding Hub!
        </Typography>
        <Typography
          variant="body1"
          sx={{
            textAlign: "justify",
            fontWeight: 400,
            color: "#000",
            fontFamily: "Montserrat, sans-serif",
          }}
        >
          We are excited to have you on our team, and that's why{" "}
          <span
            style={{
              fontWeight: 700,
              color: "#000",
              fontFamily: "Montserrat, sans-serif",
            }}
          >
            Onboarding Hub 
          </span>{' '}
          is here to make the experience as smooth as possible. To officially
          start your Asticom journey, please click{" "}
          <span
            style={{
              fontWeight: 700,
              color: "#000",
              fontFamily: "Montserrat, sans-serif",
            }}
          >
            "accept offer"
          </span>{" "}
          on the job offer listed below.
          <br />
          <br />
          At Asticom, we believe in fostering a culture of collaboration,
          innovation, and growth. That’s why we've carefully prepared this
          opportunity to align with your skills and aspirations.
          <br />
          <br />
          If you have any questions, our team is here to assist. We look forward
          to having you as a part of our thriving teams.
          <br />
          <br />
          As you explore this hub, we hope you sense the exciting possibilities
          that await you. To get a feel of what Asticom truly is, watch this
          message from our{" "}
          <span
            style={{
              fontWeight: 700,
              color: "#000",
              fontFamily: "Montserrat, sans-serif",
            }}
          >
            President and CEO, Mharicar Castillo-Reyes.
          </span>
          <br />
          <br />
          See you around!
        </Typography>
      </div>

      <div
        style={{
          position: "relative",
          width: isMobile ? "100%":"50%",

          overflow: "hidden",
          paddingTop: "26.25%", 
        }}
      >
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            bottom: 0,
            width: "100%",
            cursor: "pointer",
            overflow: "hidden",
            borderRadius: "8px",
          }}
        >
          <img
            src={videoThumbnail}
            alt="Video Thumbnail"
            style={{ width: "100%", height: "100%", objectFit: "cover" }}
            onClick={handleThumbnailClick}
          />
          <div
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              borderRadius: "50%",
              display: "flex",
              alignItems: "center",
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="50"
              height="50"
              viewBox="0 0 24 24"
              fill="none"
              stroke="#FFF"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              onClick={handleThumbnailClick}
            >
              <circle cx="12" cy="12" r="10" />
              <polygon points="8 8 16 12 8 16 8 8"></polygon>
            </svg>
            <Typography
              variant="h6"
              style={{
                color: "#FFF",
                marginLeft: "5px",
                fontFamily: "Montserrat, sans-serif",
              }}
            >
              Watch Video
            </Typography>
          </div>
        </div>

        <Modal
          open={showModal}
          onClose={handleCloseModal}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              width: "80%",
              height: "80vh",
              bgcolor: "background.paper",
              borderRadius: "8px",
              overflow: "hidden",
            }}
          >
            {iframeLoading && (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "100%",
                }}
              >
                <Loader />
                {/* <CircularProgress sx={{ color: "#7901A3"}} size={100} /> */}
              </div>
            )}
            <iframe
              title="Google Drive Video"
              className="responsive-iframe"
              src={videoUrl}
              allow="autoplay"
              allowFullScreen
              style={{
                width: "100%",
                height: "100%",
                border: "none",
                borderRadius: "8px",
                display: iframeLoading ? "none" : "block",
              }}
              onLoad={handleIframeLoad}
            ></iframe>
          </Box>
        </Modal>
      </div>
    </div>
  );
};

export default VideoMessage;
