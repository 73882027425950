import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
  TextField,
  Button,
  Typography,
  Select,
  MenuItem,
  SelectChangeEvent,
  FormControl,
  IconButton,
} from "@mui/material";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { styled } from "@mui/material/styles";
import { Edit as EditIcon } from "@mui/icons-material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import moment from "moment";
import { Add as AddIcon } from "@mui/icons-material";
import RecruiterModal from "./InternalModal";
import CustomPaginationOutlined from "../../../components/CustomPagination";
import dayjs from "dayjs";
import CircularProgress from "@mui/material/CircularProgress";
import { Refresh as RefreshIcon } from "@mui/icons-material";
import AddInternal from "./AddInternal";
import EditInternal from "./EditInternal";
import InternalModal from "./InternalModal";
import SearchIcon from "@mui/icons-material/Search";

export interface Applicant {
  userId: string;
  lastName: string;
  middleName: string;
  firstName: string;
  contactNumber: string;
  emailAddress: string;
  address: string;
  userType: string;
  userStatus: string;
  isVerified: string;
  createdAt: string;
}

interface ApiResponse {
  result: Applicant[];
}

const UserManagementContent = () => {
  const apiKey = process.env.REACT_APP_USER_SERVICE_API;
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [data, setData] = useState<Applicant[]>([]);
  const [totalCount, setTotalCount] = useState(0);
  const [filters, setFilters] = useState({
    firstName: "",
    lastName: "",
    emailAddress: "",
  });

  const [startDateFilter, setStartDateFilter] = useState<string | null>(null);
  const [endDateFilter, setEndDateFilter] = useState<string | null>(null);

  const [selectedRowsPerPage, setSelectedRowsPerPage] = useState(10);
  const [selectedApplicant, setSelectedApplicant] = useState<Applicant | null>(
    null
  );

  const columns = [
    { id: "firstName", label: "First Name" },
    { id: "lastName", label: "Last Name" },
    { id: "emailAddress", label: "Email Address" },
    { id: "contactNumber", label: "Contact Number" },
    { id: "Role", label: "Role" },
    { id: "createdAt", label: "Created At" },
    { id: "userStatus", label: "Status" },
    { id: "isVerified", label: "Verification" },
    { id: "", label: "" },
  ];

  const [isAddApplicantModalOpen, setIsAddApplicantModalOpen] = useState(false);
  const [isEditApplicantModalOpen, setIsEditApplicantModalOpen] =
    useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingFilter, setLoadingFilter] = useState(false);
  const [endDateSelectionMessage, setEndDateSelectionMessage] = useState<
    string | null
  >(null);

  useEffect(() => {
    fetchData(page + 1, rowsPerPage);
  }, [page, rowsPerPage]);

  const fetchData = async (page: number, limit: number) => {
    try {
      setLoading(true);
      const accessToken = localStorage.getItem("accessToken");

      const response = await axios.post(
        `${apiKey}/api/user-service/fetch-user`,
        {
          page,
          limit,
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      setData(response.data.result);
      setTotalCount(response.data.total);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false); // Set loading to false after fetching data
    }
  };
  const handleApplyFilter = async () => {
    try {
      setLoadingFilter(true);
      const accessToken = localStorage.getItem("accessToken");

      const nonEmptyFilters: Record<string, string> = {};
      Object.entries(filters).forEach(([key, value]) => {
        if (value !== "") {
          nonEmptyFilters[key] = value;
        }
      });

      const requestPayload: Record<string, any> = {
        page: 1,
        limit: rowsPerPage,
        ...(Object.keys(nonEmptyFilters).length > 0 && {
          filters: nonEmptyFilters,
        }),
      };

      if (startDateFilter && endDateFilter) {
        requestPayload.dateFilter = {
          field: "createdAt",
          start: startDateFilter,
          end: endDateFilter,
        };
      }

      const response = await axios.post(
        `${apiKey}/api/user-service/fetch-user`,
        requestPayload,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      setData(response.data.result);
      setTotalCount(response.data.total);
      setPage(0);
    } catch (error) {
      console.error("Error applying filter:", error);
    } finally {
      setLoadingFilter(false);
    }
  };

  const handleRefresh = () => {
    setFilters({
      firstName: "",
      lastName: "",
      emailAddress: "",
    });

    setStartDateFilter(null);
    setEndDateFilter(null);

    fetchData(page + 1, rowsPerPage);
  };

  const handleOpenAddApplicantModal = () => {
    setIsAddApplicantModalOpen(true);
  };

  const handleCloseAddApplicantModal = () => {
    setIsAddApplicantModalOpen(false);
  };

  const handleOpenEditApplicantModal = (applicant: Applicant) => {
    setSelectedApplicant(applicant);
    setIsEditApplicantModalOpen(true);
  };

  const handleCloseEditApplicantModal = () => {
    setIsEditApplicantModalOpen(false);
  };

  const handleFilterChange = (field: string, value: string) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [field]: value,
    }));
  };

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#7901A3",
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));

  const StyledEditIcon = styled(EditIcon)(({ theme }) => ({
    color: theme.palette.primary.main,
  }));

  const handleChangeRowsPerPageSelect = (event: SelectChangeEvent<number>) => {
    const newRowsPerPage = Number(event.target.value);
    setSelectedRowsPerPage(newRowsPerPage);
    setRowsPerPage(newRowsPerPage);
    setPage(0);
  };

  const handleChangePageOutlined = (
    event: React.ChangeEvent<unknown>,
    newPage: number
  ) => {
    setPage(newPage - 1);
  };

  const cellTextStyle = {
    fontFamily: "Montserrat, sans-serif",
    fontWeight: "400",
    fontSize: "13px",
  };

  return (
    <div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          marginBottom: "10px",
          justifyContent: "space-between",
        }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <TextField
            label="First Name"
            variant="outlined"
            value={filters.firstName}
            onChange={(e) => handleFilterChange("firstName", e.target.value)}
          />
          <TextField
            label="Last Name"
            variant="outlined"
            value={filters.lastName}
            onChange={(e) => handleFilterChange("lastName", e.target.value)}
            sx={{ margin: "0 10px" }}
          />
          <TextField
            label="Email Address"
            variant="outlined"
            value={filters.emailAddress}
            onChange={(e) => handleFilterChange("emailAddress", e.target.value)}
            sx={{ marginRight: "10px" }}
          />
          {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label="Start Date"
              value={startDateFilter}
              slotProps={{ textField: { variant: "outlined" } }}
              onChange={(newValue) => {
                if (newValue !== null && newValue !== "") {
                  const formattedDate = dayjs(newValue).format("YYYY-MM-DD");
                  setStartDateFilter(formattedDate);
                } else {
                  setStartDateFilter(newValue);
                }
              }}
              sx={{ marginRight: "10px" }}
            />
            <DatePicker
              label="End Date"
              value={endDateFilter}
              slotProps={{ textField: { variant: "outlined" } }}
              onChange={(newValue) => {
                if (newValue !== null && newValue !== "") {
                  const formattedDate = dayjs(newValue).format("YYYY-MM-DD");
                  setEndDateFilter(formattedDate);
                } else {
                  setEndDateFilter(newValue);
                }
              }}
              sx={{ marginRight: "10px" }}
            />
          </LocalizationProvider> */}
          <Button
            variant="contained"
            onClick={handleApplyFilter}
            disabled={loadingFilter}
            sx={{
              width: "25ch",
              backgroundColor: "#7901A3",
              "&:hover": {
                backgroundColor: "#7901A3",
              },
            }}
          >
            <SearchIcon />
            <div style={{ visibility: loadingFilter ? "hidden" : "visible" }}>
              Search
            </div>
            {loadingFilter && (
              <CircularProgress
                size={24}
                sx={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  marginTop: "-12px", // Half of the loader size to center it
                  marginLeft: "-12px", // Half of the loader size to center it
                }}
              />
            )}
          </Button>

          <Button
            variant="contained"
            onClick={handleRefresh}
            size="medium"
            sx={{
              ml: 1,
              backgroundColor: "#7901A3",
              "&:hover": {
                backgroundColor: "#7901A3",
              },
            }}
          >
            <RefreshIcon />
          </Button>
        </div>
        <Button
          variant="contained"
          onClick={handleOpenAddApplicantModal}
          startIcon={<AddIcon />}
          sx={{
            marginLeft: 2,
            width: "30ch",
            backgroundColor: "#7901A3",
            "&:hover": {
              backgroundColor: "#7901A3",
            },
          }}
        >
          Add Role
        </Button>
      </div>

      <div style={{ display: "flex", justifyContent: "center" }}>
        <TableContainer component={Paper}>
          <Table size="small" sx={{ minWidth: 650 }} >
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <StyledTableCell
                    key={column.id}
                    sx={{
                      fontFamily: "Montserrat, sans-serif",
                      fontWeight: "800",
                    }}
                  >
                    {column.label}
                  </StyledTableCell>
                ))}
              </TableRow>
            </TableHead>

            <TableBody>
              {loading ? (
                <TableRow>
                  <TableCell colSpan={columns.length} align="center">
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        height: "100%", // Ensure that the container takes the full height
                      }}
                    >
                      <CircularProgress size={70} />
                    </Box>
                  </TableCell>
                </TableRow>
              ) : data.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={columns.length} align="center">
                    <Typography
                      variant="h5"
                      sx={{ fontFamily: "Montserrat, sans-serif", }}
                    >
                      No data available in the table
                    </Typography>
                  </TableCell>
                </TableRow>
              ) : (
                data.map((row) => (
                  <StyledTableRow
                    key={row.userId}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell sx={cellTextStyle}>{row.firstName}</TableCell>
                    <TableCell sx={cellTextStyle}>{row.lastName}</TableCell>
                    <TableCell sx={cellTextStyle}>{row.emailAddress}</TableCell>
                    <TableCell sx={cellTextStyle}>
                      {row.contactNumber}
                    </TableCell>
                    <TableCell sx={cellTextStyle}>{row.userType}</TableCell>
                    <TableCell sx={{ ...cellTextStyle }}>
                      {moment(row.createdAt).format("MMM DD, YYYY")}
                    </TableCell>
                    <TableCell sx={cellTextStyle}>
                      {row.userStatus.toUpperCase()}
                    </TableCell>

                    <TableCell sx={cellTextStyle}>
                      {row.isVerified.toUpperCase()}
                    </TableCell>

                    {/* <TableCell sx={cellTextStyle}>{row.isVerified}</TableCell> */}
                    <TableCell>
                      <Box
                        onClick={() => handleOpenEditApplicantModal(row)}
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          width: "30px",
                          height: "30px",
                          borderRadius: "20%",
                          backgroundColor: "#7901A3",
                        }}
                      >
                        <StyledEditIcon sx={{ color: "white" }} />
                      </Box>
                    </TableCell>
                  </StyledTableRow>
                ))
              )}
            </TableBody>
          </Table>

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              marginBottom: 2,
            }}
          >
            <Typography
              sx={{
                fontFamily: "Montserrat, sans-serif",
                fontWeight: "600",
                fontSize: 12,
              }}
            >
              Rows Per Page:
            </Typography>
            <FormControl variant="standard">
              <Select
                value={selectedRowsPerPage}
                label="Rows Per Page"
                onChange={handleChangeRowsPerPageSelect}
                sx={{ marginLeft: 1 }}
              >
                <MenuItem value={5}>5</MenuItem>
                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={25}>25</MenuItem>
              </Select>
            </FormControl>
            <Typography
              sx={{
                fontFamily: "Montserrat, sans-serif",
                fontWeight: "600",
                fontSize: 12,
                marginLeft: 2,
              }}
            >
              {` ${page * rowsPerPage + 1} - ${Math.min(
                (page + 1) * rowsPerPage,
                totalCount
              )} of ${totalCount}`}
            </Typography>
            <CustomPaginationOutlined
              count={Math.ceil(totalCount / rowsPerPage)}
              page={page + 1}
              onChange={handleChangePageOutlined}
            />
          </Box>
        </TableContainer>
        <RecruiterModal
          isOpen={isAddApplicantModalOpen}
          handleClose={handleCloseAddApplicantModal}
          title={"Role Form"}
          content={<AddInternal />}
        />
        <InternalModal
          isOpen={isEditApplicantModalOpen}
          handleClose={handleCloseEditApplicantModal}
          title={"Edit Applicant Form"}
          content={<EditInternal applicant={selectedApplicant} />}
        />
      </div>
    </div>
  );
};

export default UserManagementContent;
