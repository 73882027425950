import React, { useEffect, useState } from "react";
import axios, { AxiosError } from "axios";
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
  Typography,
  Button,
  MenuItem,
  TableCell,
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { useLocation } from "react-router-dom";
import moment from "moment";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import StyledMenu from "../../../components/TableStyle/StyledMenu";
import StyledTableCell from "../../../components/TableStyle/StyledTableCell";
import StyledTableRow from "../../../components/TableStyle/StyledTableRow";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Edit as EditIcon } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import "react-pdf/dist/esm/Page/TextLayer.css";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import { Add as AddIcon } from "@mui/icons-material";
import AddRequirement from "./AddRequirement";
import RecruiterModal from "../../UserManagement/InternalList/InternalModal";
import EditRequirements from "./EditRequirements";

export interface Requirement {
  applicantRequirementId: string;
  requirement: string;
  prioritization: string;
  deadline: string;
  dateCompleted: string;
  documentStatus: string;
  jobId: string;
  applicantId: string;
  prfId: string;
  jobOfferId: string;
  userType: string;
  templatePath: string;
  uploadedDocument: string;
  s3_path: string;
  completionPercentage: number;
  createdAt: string;
  withTemplate: string;
  groupId: string;
  id: string;
}

const cellTextStyle = {
  fontFamily: "Montserrat, sans-serif",
  fontWeight: "400",
  fontSize: "13px",
  whiteSpace: "normal",
};

const SpecificInternalRequirementContent = () => {
  const apiKey = process.env.REACT_APP_RECRUITMENT_API;
  const navigate = useNavigate();
  const location = useLocation();
  const {
    state: { id },
  } = location;

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [data, setData] = useState<Requirement[]>([]);
  const [isAddApplicantModalOpen, setIsAddApplicantModalOpen] = useState(false);
  const [isEditApplicantModalOpen, setIsEditApplicantModalOpen] = useState(false);

  const columns = [
    { id: "no", label: "No." },
    { id: "requirement", label: "Requirement" },
    { id: "createdAt", label: "CreatedAt" },
    { id: "withTemplate", label: "With Template" },

    { id: "actions", label: "Actions" },
  ];
  const [loading, setLoading] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [errorMessage, setErrorMessage] = useState({ message: "" });
  const [selectedRequirement, setSelectedRequirement] = useState<Requirement | null>(
    null
  );

  useEffect(() => {
    fetchData(page + 1, rowsPerPage);
  }, [page, rowsPerPage]);

  const fetchData = async (page: number, limit: number) => {
    try {
      setLoading(true);
      const accessToken = localStorage.getItem("accessToken");

      const response = await axios.post(
        `${apiKey}/api/recops-head/fetch-special-requirement`,
        {
          groupId: id,
          page,
          limit,
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      if (response.status === 200) {

        setData(response.data.result);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleClick = (
    event: React.MouseEvent<HTMLElement>,
    requirement: Requirement
  ) => {
    setAnchorEl(event.currentTarget);
    setSelectedRequirement(requirement)
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOpenAddApplicantModal = () => {
    setIsAddApplicantModalOpen(true);
  };

  const handleCloseAddApplicantModal = () => {
    setIsAddApplicantModalOpen(false);
  };

  const handleOpenEditApplicantModal = () => {
    setIsEditApplicantModalOpen(true);
  };

  const handleCloseEditdApplicantModal = () => {
    setIsEditApplicantModalOpen(false);
  };

  const capitalizeFirstLetter = (inputString: string) => {
    return (
      inputString.charAt(0).toUpperCase() + inputString.slice(1).toLowerCase()
    );
  };

  return (
    <div>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Button
          startIcon={<ArrowBackIcon />}
          variant="contained"
          color="primary"
          onClick={() => navigate("/internal-requirement", { replace: true })}
          sx={{ marginBottom: 2,
            backgroundColor: "#7901A3",
            "&:hover": {
              backgroundColor: "#7901A3",
            },}}
        >
          Back
        </Button>
        <Button
          variant="contained"
          onClick={handleOpenAddApplicantModal}
          startIcon={<AddIcon />}
          sx={{
            marginBottom: 2,
            width: "30ch",
            backgroundColor: "#7901A3",
            "&:hover": {
              backgroundColor: "#7901A3",
            },
          }}
        >
          Add Requirement
        </Button>
      </div>

      <div style={{ display: "flex", justifyContent: "center" }}>
        <TableContainer component={Paper}>
          <Table size="small" sx={{ minWidth: 650 }}>
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <StyledTableCell
                    key={column.id}
                    sx={{
                      fontFamily: "Montserrat, sans-serif",
                      fontWeight: "800",
                    }}
                  >
                    {column.label}
                  </StyledTableCell>
                ))}
              </TableRow>
            </TableHead>

            <TableBody>
              {loading ? (
                <TableRow>
                  <TableCell colSpan={columns.length} align="center">
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        height: "100%",
                      }}
                    >
                      <CircularProgress size={70} />
                    </Box>
                  </TableCell>
                </TableRow>
              ) : data.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={columns.length} align="center">
                    <Typography
                      variant="h5"
                      sx={{ fontFamily: "Montserrat, sans-serif" }}
                    >
                      No data available in the table
                    </Typography>
                  </TableCell>
                </TableRow>
              ) : (
                data.map((row, index) => (
                  <StyledTableRow
                    key={index}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell sx={cellTextStyle}>{index + 1}</TableCell>
                    <TableCell sx={cellTextStyle}>{row.requirement}</TableCell>
                    <TableCell sx={cellTextStyle}>
                      {moment(row.createdAt).format("MMM DD, YYYY")}
                    </TableCell>
                    <TableCell sx={cellTextStyle}>
                      {capitalizeFirstLetter(row.withTemplate)}
                    </TableCell>
                    <TableCell>
                      <div>
                        {row.documentStatus !== "Pending" ? (
                          <Button
                            id="demo-customized-button"
                            aria-controls={
                              open ? "demo-customized-menu" : undefined
                            }
                            aria-haspopup="true"
                            aria-expanded={open ? "true" : undefined}
                            disableElevation
                            onClick={(event) => handleClick(event, row)}
                          >
                            <MoreVertIcon color="primary" />
                          </Button>
                        ) : (
                          <Button
                            id="demo-customized-button"
                            aria-controls={
                              open ? "demo-customized-menu" : undefined
                            }
                            aria-haspopup="true"
                            aria-expanded={open ? "true" : undefined}
                            disabled
                          >
                            <MoreVertIcon color="inherit" />
                          </Button>
                        )}

                        <StyledMenu
                          id="demo-customized-menu"
                          MenuListProps={{
                            "aria-labelledby": "demo-customized-button",
                          }}
                          anchorEl={anchorEl}
                          open={open}
                          onClose={handleClose}
                        >
                          <MenuItem
                            disableRipple
                            onClick={handleOpenEditApplicantModal}
                          >
                            <EditIcon sx={{ marginRight: 1 }} />
                            Edit
                          </MenuItem>
                        </StyledMenu>
                      </div>
                    </TableCell>
                  </StyledTableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>

      <RecruiterModal
        isOpen={isAddApplicantModalOpen}
        handleClose={handleCloseAddApplicantModal}
        title={"Add Requirement Form"}
        content={<AddRequirement requirementId={id} />}
      />
      <RecruiterModal
        isOpen={isEditApplicantModalOpen}
        handleClose={handleCloseEditdApplicantModal}
        title={"Edit Requirement Form"}
        content={<EditRequirements requirementList={selectedRequirement} />}
      />
    </div>
  );
};

export default SpecificInternalRequirementContent;
