import React from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";


interface AddApplicantModalProps {
  isOpen: boolean;
  handleClose: () => void;
  title: string;
  content: React.ReactNode;
}

const ApplicantModal: React.FC<AddApplicantModalProps> = ({ isOpen, handleClose, title, content }) => {
  return (
    <Modal open={isOpen} onClose={handleClose}>
      <Box
        sx={{
          position: "absolute",
          top: "48%",
          left: "52%",
          transform: "translate(-50%, -50%)",
          backgroundColor: "white",
          padding: "20px",
          borderRadius: "8px",
        }}
      >
        {/* Modal Header */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "20px",
          }}
        >
          <Typography variant="h5" sx={{fontFamily: "Montserrat, sans-serif",}}>{title}</Typography>
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Box>
          {content}

  
      </Box>
    </Modal>
  );
};

export default ApplicantModal;
