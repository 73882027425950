import React from 'react'
import CustomDrawer from '../../../layouts/drawer/CustomDrawer'
import AssignedApplicantContent from './AssignedApplicantContent';
import {Helmet} from "react-helmet";

const AssignedApplicant: React.FC = () => {

  return (
    <>
      <Helmet>
        <title>Onboarding Hub | Assigned Applicant List</title>
      </Helmet>
      <CustomDrawer content={<AssignedApplicantContent  />} title='Assigned Applicant List' />
    </>
  );
};

export default AssignedApplicant;
