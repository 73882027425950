import React from "react";
import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import CircularProgress from "@mui/material/CircularProgress";
import axios, { AxiosError } from "axios";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import RequirementModal from "./RequirementModal";
import ViewOffer from "./ViewOffer";
import DeclineOffer from "./DeclineOffer";
import Skeleton from "@mui/material/Skeleton";

import VideoMessage from "./VideoMessage";
import searchIcon from "../../../assets/icons/search.png";
import reupload from "../../../assets/icons/reupload.png";
import downloadContract from "../../../assets/icons/downloadContract.png";
import { saveAs } from "file-saver";
import CongratulatoryMessage from "./CongratulatoryMessage";
import moment from "moment";

interface JobOffer {
  jobOfferId: string;
  applicantId: string;
  jobTitle: string;
  subsidiary: string;
  deadline: string;
  prfId: string;
  offerStatus: string;
  applicantStatus: string;
  applicantSubStatus: string;
  jobOfferPath: string;

  jobOfferContract: {
    applicantRequirementId: string;
    uploadedDocument: string;
    dateUploaded: string;
    documentStatus: string;
  }[];
}
const JobOfferContent: React.FC = () => {
  const apiKey = process.env.REACT_APP_RECRUITMENT_API;
  const isMobile = useMediaQuery("(max-width:600px)");
  const [data, setData] = useState<JobOffer[]>([]);

  const [errorMessage, setErrorMessage] = useState({ message: "" });
  const [isLoadingAccept, setIsLoadingAccept] = useState(false);
  const [isLoadingDecline, setIsLoadingDecline] = useState(false);
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [confirmationAction, setConfirmationAction] = useState<
    "Accepted" | "Declined"
  >("Accepted");
  const [selectedJobOfferId, setSelectedJobOfferId] = useState<string | null>(
    null
  );
  const [isRequirementtModalOpen, setIsRequirementModalOpen] = useState(false);
  const [isDeclineModalOpen, setIsDeclineModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [showCongratulatoryModal, setShowCongratulatoryModal] = useState(false);

  useEffect(() => {
    const accessToken = localStorage.getItem("accessToken");
    const fetchUserInfo = async () => {
      setIsLoading(true);
      try {
        const response = await axios.post(
          `${apiKey}/api/applicant/my-job-offer`,
          {
            page: 1,
            limit: 10,
          },
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );
        if (response.status === 200) {
          setData(response.data.result);
    
          localStorage.setItem(
            "jobOfferIdValue",
            response.data.result[0].jobOfferId
          );
        }

        
        const validatedJobOffer = response.data.result.find(
          (jobOffer: JobOffer) =>
            jobOffer.jobOfferContract[0]?.documentStatus === "Validated"
        );

        if (validatedJobOffer && response.data.result[0].popUp === "no") {
          setShowCongratulatoryModal(true);
        } else {
          setShowCongratulatoryModal(false);
        }

      } catch (error) {
        console.error("Error fetching user information:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchUserInfo();
  }, []);

  const handleCloseModal = () => {
    setIsRequirementModalOpen(false);
  };
  const handleCloseModalDecline = () => {
    setIsDeclineModalOpen(false);
  };

  const handleOffer = async (
    selectedJobOfferId: string,
    offerStatus: string
  ) => {
    const setLoadingState = (status: boolean) => {
      if (offerStatus === "Accepted") {
        setIsLoadingAccept(status);
      } else if (offerStatus === "Declined") {
        setIsLoadingDecline(status);
      }
    };

    setLoadingState(true);

    const token = localStorage.getItem("accessToken");
    try {
      const response = await axios.patch(
        `${apiKey}/api/applicant/respond-offer`,
        {
          jobOfferId: selectedJobOfferId,
          offerStatus: offerStatus,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        window.location.reload();
      }
    } catch (error) {
      console.error(error);
      if (axios.isAxiosError(error)) {
        const axiosError: AxiosError = error;

        if (axiosError.response) {
          const status = axiosError.response.status;
          if (status === 401) {
            setErrorMessage({
              message: "Unauthorized. Please provide a valid Bearer Token.",
            });
          } else if (status === 410) {
            setErrorMessage({
              message:
                "Job offer expired. Please contact the recruiter for a new offer.",
            });
          } else if (status === 422) {
            setErrorMessage({
              message: 'Unprocessable Entity. "addressee" is not allowed.',
            });
          } else if (status === 500) {
            setErrorMessage({
              message: "Internal Server Error. An unexpected error occurred.",
            });
          }
        }
      } else {
        console.error(
          "Internal Server Error. An unexpected error occurred.",
          error
        );
      }
    } finally {
      setLoadingState(false);
    }
  };

  const calculateDaysRemaining = (expirationDate: string): number => {
    const expiryDate = new Date(expirationDate);
    const currentDate = new Date();
    const timeDifference = expiryDate.getTime() - currentDate.getTime();
    return Math.ceil(timeDifference / (1000 * 60 * 60 * 24));
  };

  const isExpiringSoon = (expirationDate: string): boolean => {
    const daysRemaining = calculateDaysRemaining(expirationDate);
    return daysRemaining <= 10;
  };

  const handleConfirmationOpen = (
    action: "Accepted" | "Declined",
    jobOfferId: string
  ) => {
    setConfirmationOpen(true);
    setConfirmationAction(action);
    setSelectedJobOfferId(jobOfferId);
  };

  const handleConfirmationClose = () => {
    setConfirmationOpen(false);
  };

  const handleConfirmation = () => {
    handleConfirmationClose();
    if (confirmationAction === "Declined") {
      setSelectedJobOfferId(selectedJobOfferId || "");
      setIsDeclineModalOpen(true);
    } else {
      handleOffer(selectedJobOfferId || "", confirmationAction);
    }
  };

  const handleFileDownload = async (applicantRequirementId: string) => {
    const accessToken = localStorage.getItem("accessToken");

    try {
      const response = await axios.get(`${apiKey}/api/applicant/document-file`, {
        params: {
          downloadType: "template",
          applicantRequirementId: applicantRequirementId,
        },
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        responseType: "arraybuffer",
      });

      const contentType = response.headers["content-type"];

      // Check if the file is a PDF
      if (contentType === "application/pdf") {
        // Open a new tab/window to display the PDF
        const file = new Blob([response.data], { type: contentType });
        const fileURL = URL.createObjectURL(file);
        window.open(fileURL, "_blank");
      } else {
        // For other file types
        const blob = new Blob([response.data], { type: contentType });

        // Replace the file name based on the document type
        let fileName = "ContractFile";
        if (contentType === "application/msword") {
          // For Word documents
          fileName += ".doc";
        } else if (
          contentType ===
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
        ) {
          // For Word documents (docx)
          fileName += ".docx";
        } else if (
          contentType ===
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        ) {
          // For Excel documents (xlsx)
          fileName += ".xlsx";
        } else if (contentType === "application/vnd.ms-powerpoint") {
          // For PowerPoint presentations
          fileName += ".ppt";
        } else if (
          contentType ===
          "application/vnd.openxmlformats-officedocument.presentationml.presentation"
        ) {
          // For PowerPoint presentations (pptx)
          fileName += ".pptx";
        } else {
          // For other types, use the original file name
          fileName += "." + contentType.split("/")[1];
        }

        // Check if it's a "docs" type and open it in a new tab/window
        if (contentType === "application/msword") {
          const fileURL = URL.createObjectURL(blob);
          window.open(fileURL, "_blank");
        } else {
          // Trigger the file download using FileSaver
          saveAs(blob, fileName);
        }
      }
    } catch (error) {
      console.error("Error downloading file:", error);
      // Handle errors appropriately, e.g., display an error message
    }
  };

  const handleUploadedContract = async (applicantRequirementId: string) => {
    const accessToken = localStorage.getItem("accessToken");

    try {
      const response = await axios.get(`${apiKey}/api/applicant/document-file`, {
        params: {
          downloadType: "file",
          applicantRequirementId: applicantRequirementId,
        },
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        responseType: "arraybuffer",
      });

      const contentType = response.headers["content-type"];

      // Check if the file is a PDF
      if (contentType === "application/pdf") {
        // Open a new tab/window to display the PDF
        const file = new Blob([response.data], { type: contentType });
        const fileURL = URL.createObjectURL(file);
        window.open(fileURL, "_blank");
      } else {
        // For other file types
        const blob = new Blob([response.data], { type: contentType });

        // Replace the file name based on the document type
        let fileName = "ContractFile";
        if (contentType === "application/msword") {
          // For Word documents
          fileName += ".doc";
        } else if (
          contentType ===
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
        ) {
          // For Word documents (docx)
          fileName += ".docx";
        } else if (
          contentType ===
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        ) {
          // For Excel documents (xlsx)
          fileName += ".xlsx";
        } else if (contentType === "application/vnd.ms-powerpoint") {
          // For PowerPoint presentations
          fileName += ".ppt";
        } else if (
          contentType ===
          "application/vnd.openxmlformats-officedocument.presentationml.presentation"
        ) {
          // For PowerPoint presentations (pptx)
          fileName += ".pptx";
        } else {
          // For other types, use the original file name
          fileName += "." + contentType.split("/")[1];
        }

        // Check if it's a "docs" type and open it in a new tab/window
        if (contentType === "application/msword") {
          const fileURL = URL.createObjectURL(blob);
          window.open(fileURL, "_blank");
        } else {
          // Trigger the file download using FileSaver
          saveAs(blob, fileName);
        }
      }
    } catch (error) {
      console.error("Error downloading file:", error);
      // Handle errors appropriately, e.g., display an error message
    }
  };

  const handleGetJobOffer = async (jobOfferId: string) => {
    const accessToken = localStorage.getItem("accessToken");

    try {
      const response = await axios.get(
        `${apiKey}/api/recruitment/job-offer-file`,
        {
          params: {
            jobOfferId: jobOfferId,
          },
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
          responseType: "arraybuffer",
        }
      );

      const contentType = response.headers["content-type"];

      // Check if the file is a PDF
      if (contentType === "application/pdf") {
        // Open a new tab/window to display the PDF
        const file = new Blob([response.data], { type: contentType });
        const fileURL = URL.createObjectURL(file);
        window.open(fileURL, "_blank");
      } else {
        // For other file types
        const blob = new Blob([response.data], { type: contentType });

        // Replace the file name based on the document type
        let fileName = "ContractFile";
        if (contentType === "application/msword") {
          // For Word documents
          fileName += ".doc";
        } else if (
          contentType ===
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
        ) {
          // For Word documents (docx)
          fileName += ".docx";
        } else if (
          contentType ===
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        ) {
          // For Excel documents (xlsx)
          fileName += ".xlsx";
        } else if (contentType === "application/vnd.ms-powerpoint") {
          // For PowerPoint presentations
          fileName += ".ppt";
        } else if (
          contentType ===
          "application/vnd.openxmlformats-officedocument.presentationml.presentation"
        ) {
          // For PowerPoint presentations (pptx)
          fileName += ".pptx";
        } else {
          // For other types, use the original file name
          fileName += "." + contentType.split("/")[1];
        }

        // Check if it's a "docs" type and open it in a new tab/window
        if (contentType === "application/msword") {
          const fileURL = URL.createObjectURL(blob);
          window.open(fileURL, "_blank");
        } else {
          // Trigger the file download using FileSaver
          saveAs(blob, fileName);
        }
      }
    } catch (error) {
      console.error("Error downloading file:", error);
      // Handle errors appropriately, e.g., display an error message
    }
  };

  const handlePopUp = async (selectedJobOfferId: string) => {
 
    const token = localStorage.getItem("accessToken");
    try {
      const response = await axios.patch(
        `${apiKey}/api/applicant/applicant-pop-up`,
        {
          jobOfferId: selectedJobOfferId,
          popUp: "yes",
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.status === 200) {
        window.location.reload();
      }
    } catch (error) {
      console.error(error);
      if (axios.isAxiosError(error)) {
        const axiosError: AxiosError = error;

        if (axiosError.response) {
          const status = axiosError.response.status;
          if (status === 401) {
            setErrorMessage({
              message: "Unauthorized. Please provide a valid Bearer Token.",
            });
          } else if (status === 410) {
            setErrorMessage({
              message:
                "Job offer expired. Please contact the recruiter for a new offer.",
            });
          } else if (status === 422) {
            setErrorMessage({
              message: 'Unprocessable Entity. "addressee" is not allowed.',
            });
          } else if (status === 500) {
            setErrorMessage({
              message: "Internal Server Error. An unexpected error occurred.",
            });
          }
        }
      } 
    }
  };

  const handleModalClose = async () => {

    if (showCongratulatoryModal) {
      const selectedJobOfferId = data[0].jobOfferId; 
      await handlePopUp(selectedJobOfferId);
    }

    // Close the modal
    setShowCongratulatoryModal(false);
  };

  return (
    <>
      <VideoMessage />
      {data.map((jobOffer, index) => (
        <div key={index}>          
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              alignItems: "flex-start",
              width: "100%",
              margin: "auto", // Center the box horizontally
              padding: "16px",
              border: "1px solid #ccc",
              borderRadius: "5px",
              marginTop: "20px",
              backgroundColor: "#FFF",
              boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)", // Add box shadow

              "&:not(:last-child)": {
                marginBottom: "10px", // 10px space between items
              },
            }}
          >
            <Typography
              component="div"
              gutterBottom
              sx={{
                fontSize: "21px",
                fontWeight: 600,
                color: "#000",
                fontFamily: "Montserrat, sans-serif",
              }}
            >
              Job Offer
            </Typography>
            <Box
              sx={{
                flexDirection: isMobile ? "column" : "row",
                alignItems: "center",
                width: "100%",
                padding: "16px",
                border: "1px solid #ccc",
                borderRadius: "5px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: isMobile ? "column" : "row",
                  justifyContent: "space-between",
                }}
              >
                <Typography
                  variant="subtitle1"
                  component="div"
                  gutterBottom
                  sx={{
                    fontSize: "14px",
                    fontWeight: 600,
                    color: "#000",
                    fontFamily: "Montserrat, sans-serif",
                  }}
                >
                  PRF ID:{" "}
                  <span
                    style={{
                      fontFamily: "Montserrat, sans-serif",
                      fontSize: "14px",
                      fontWeight: 600,
                      color: "#0094FF",
                      textDecoration: "underline",
                    }}
                  >
                    {jobOffer.prfId}
                  </span>
                </Typography>
                {jobOffer.jobOfferContract &&
                  jobOffer.jobOfferContract.length > 0 && (
                    <Button
                      variant="contained"
                      style={{
                        marginRight: "8px",
                        borderRadius: "20px",
                        textTransform: "none",
                        fontFamily: "Montserrat, sans-serif",
                        backgroundColor:
                          jobOffer.jobOfferContract[0].documentStatus ===
                          "For Validation"
                            ? "#FF9900"
                            : jobOffer.jobOfferContract[0].documentStatus ===
                              "Validated"
                            ? "#00CA8E"
                            : "#2074d4",
                      }}
                    >
                      {jobOffer.jobOfferContract[0].documentStatus}
                    </Button>
                  )}
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: isMobile ? "column" : "row",
                }}
              >
                <div style={{ marginRight: "30px" }}>
                  <Typography
                    variant="h6"
                    sx={{
                      fontWeight: 600,
                      color: "#000",
                      fontFamily: "Montserrat, sans-serif",
                    }}
                  >
                    Position
                  </Typography>
                  <Typography
                    variant="body1"
                    sx={{
                      fontWeight: 500,
                      color: "#000",
                      fontFamily: "Montserrat, sans-serif",
                    }}
                  >
                    {jobOffer.jobTitle}
                  </Typography>
                </div>

                <div style={{ marginRight: "30px" }}>
                  <Typography
                    variant="h6"
                    sx={{
                      fontWeight: 600,
                      color: "#000",
                      fontFamily: "Montserrat, sans-serif",
                    }}
                  >
                    Company
                  </Typography>
                  <Typography
                    variant="body1"
                    sx={{
                      fontWeight: 500,
                      color: "#000",
                      fontFamily: "Montserrat, sans-serif",
                    }}
                  >
                    {jobOffer.subsidiary.toUpperCase()}
                  </Typography>
                </div>

                <div style={{ marginRight: "30px" }}>
                  <Typography
                    variant="h6"
                    sx={{
                      fontWeight: 600,
                      color: "#000",
                      fontFamily: "Montserrat, sans-serif",
                    }}
                  >
                    Deadline Date
                  </Typography>
                  <Typography
                    variant="body1"
                    sx={{
                      color: isExpiringSoon(jobOffer.deadline)
                        ? "#ec6d06"
                        : "inherit",
                      fontWeight: 500,
                      fontFamily: "Montserrat, sans-serif",
                    }}
                  >
                    {`${calculateDaysRemaining(jobOffer.deadline)} Days`}
                  </Typography>
                </div>
                {jobOffer.jobOfferContract &&
                  jobOffer.jobOfferContract.length > 0 &&
                  jobOffer.jobOfferContract[0].uploadedDocument !== null && (
                    <div style={{ marginRight: "30px" }}>
                      <Typography
                        variant="h6"
                        sx={{
                          fontWeight: 600,
                          color: "#000",
                          fontFamily: "Montserrat, sans-serif",
                        }}
                      >
                        Uploaded Date
                      </Typography>
                      <Typography
                        variant="body1"
                        sx={{
                          color: isExpiringSoon(jobOffer.deadline)
                            ? "#ec6d06"
                            : "inherit",
                          fontWeight: 500,
                          fontFamily: "Montserrat, sans-serif",
                        }}
                      >
                        {moment(
                          jobOffer.jobOfferContract[0].dateUploaded
                        ).format("MMM DD, YYYY - HH:mm:ss")}
                      </Typography>
                    </div>
                  )}
                {jobOffer.applicantSubStatus === null &&
                  jobOffer.offerStatus === "Accepted" && (
                    <div>
                      <Typography
                        variant="h6"
                        sx={{
                          fontWeight: 600,
                          color: "#000",
                          fontFamily: "Montserrat, sans-serif",
                        }}
                      >
                        Offer Status
                      </Typography>
                      <Typography
                        color="primary"
                        variant="body1"
                        sx={{ fontFamily: "Montserrat, sans-serif" }}
                      >
                        Waiting for Job Contract
                      </Typography>
                    </div>
                  )}
              </div>
              {/* Accept and Decline buttons */}
              <div
                style={{
                  marginTop: "16px",
                  display: "flex",
                  justifyContent: 'space-between',
                  flexDirection: 'row',
                }}
              >
         
                <div>
                {jobOffer.jobOfferPath && (
                  <Button
                    variant="contained"
                    style={{
                      marginRight: "8px",
                      backgroundColor: "#0094FF",
                      borderRadius: "20px",
                      textTransform: "none",
                      marginBottom: isMobile ? "10px" : "",
                    }}
                    startIcon={<img src={searchIcon} alt="Search Icon" />}
                    onClick={() => handleGetJobOffer(jobOffer.jobOfferId)}
                  >
                    View Job Offer Letter
                  </Button>
                )}
                  {jobOffer.applicantSubStatus !== null &&
                    jobOffer.offerStatus !== "Declined" && (
                      <Button
                        variant="contained"
                        style={{
                          marginRight: "8px",
                          backgroundColor: "#0094FF",
                          borderRadius: "20px",
                          textTransform: "none",
                          marginBottom: isMobile ? "10px" : "",
                        }}
                        startIcon={<img src={searchIcon} alt="Search Icon" />}
                        onClick={() =>
                          handleFileDownload(
                            jobOffer.jobOfferContract[0].applicantRequirementId
                          )
                        }
                      >
                        View Contract
                      </Button>
                    )}

                  {jobOffer.applicantSubStatus !== null &&
                    jobOffer.offerStatus !== "Declined" && (
                      <Button
                        variant="contained"
                        style={{
                          marginRight: "8px",
                          backgroundColor: "#00CA8E",
                          borderRadius: "20px",
                          textTransform: "none",
                          marginBottom: isMobile ? "10px" : "",
                          fontFamily: "Montserrat, sans-serif",
                        }}
                        startIcon={
                          <img src={downloadContract} alt="Download Icon" />
                        }
                        onClick={() => {
                          setSelectedJobOfferId(jobOffer.jobOfferId);
                          setIsRequirementModalOpen(true);
                        }}
                      >
                        {jobOffer.jobOfferContract[0].uploadedDocument !== null
                          ? "Re-Upload Contract"
                          : "Upload Contract"}
                      </Button>
                    )}
                  {jobOffer.applicantSubStatus !== null &&
                    jobOffer.offerStatus !== "Declined" &&
                    jobOffer.jobOfferContract[0].uploadedDocument !== null && (
                      <Button
                        variant="contained"
                        style={{
                          marginRight: "8px",
                          backgroundColor: "#0094FF",
                          borderRadius: "20px",
                          textTransform: "none",
                          marginBottom: isMobile ? "10px" : "",
                        }}
                        startIcon={<img src={searchIcon} alt="Search Icon" />}
                        onClick={() =>
                          handleUploadedContract(
                            jobOffer.jobOfferContract[0].applicantRequirementId
                          )
                        }
                      >
                        View Uploaded Contract
                      </Button>
                    )}
                </div>
                <div>
                  <Button
                    variant={
                      jobOffer.offerStatus === "Accepted"
                        ? "outlined"
                        : "contained"
                    }
                    disabled={jobOffer.offerStatus === "Accepted"}
                    style={{
                      marginRight: "8px",
                      backgroundColor:
                        jobOffer.offerStatus === "Accepted" ? "" : "#00CA8E",
                      borderColor:
                        jobOffer.offerStatus === "Accepted" ? "#00CA8E" : "",
                      color:
                        jobOffer.offerStatus === "Accepted" ? "#00CA8E" : "",
                      borderRadius: "20px",
                      textTransform: "none",
                      marginBottom: isMobile ? "10px" : "",
                      fontFamily: "Montserrat, sans-serif",
                    }}
                    onClick={() =>
                      handleConfirmationOpen("Accepted", jobOffer.jobOfferId)
                    }
                    startIcon={
                      isLoadingAccept ? (
                        <></>
                      ) : (
                        <CheckIcon
                          sx={{
                            color:
                              jobOffer.offerStatus === "Accepted"
                                ? "#00CA8E"
                                : "",
                          }}
                        />
                      )
                    }
                  >
                    {isLoadingAccept ? (
                      <CircularProgress size={24} sx={{ color: "#FFF" }} />
                    ) : jobOffer.offerStatus === "Accepted" ? (
                      "Offer Accepted"
                    ) : (
                      "Accept Offer"
                    )}
                  </Button>

                  <Button
                    variant="contained"
                    style={{
                      marginRight: "8px",
                      backgroundColor: "#FF0000",
                      borderRadius: "20px",
                      textTransform: "none",
                      fontFamily: "Montserrat, sans-serif",
                    }}
                    onClick={() =>
                      handleConfirmationOpen("Declined", jobOffer.jobOfferId)
                    }
                    startIcon={isLoadingDecline ? <></> : <CloseIcon />}
                    disabled={jobOffer.offerStatus === "Declined"}
                  >
                    {isLoadingDecline ? (
                      <CircularProgress size={24} sx={{ color: "#FFF" }} />
                    ) : jobOffer.offerStatus === "Declined" ? (
                      "Declined"
                    ) : (
                      "Decline Offer"
                    )}
                  </Button>
                </div>

                <Dialog
                  open={confirmationOpen}
                  onClose={handleConfirmationClose}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                  fullWidth
                >
                  <DialogTitle>
                    {`Are you sure you want to ${
                      confirmationAction === "Accepted" ? "accept" : "decline"
                    } the job offer?`}
                  </DialogTitle>
                  <DialogContent></DialogContent>
                  <DialogActions>
                    <Button
                      variant="contained"
                      onClick={() => handleConfirmation()}
                      sx={{
                        backgroundColor: "#00CA8E",
                        "&:hover": {
                          backgroundColor: "#00CA8E",
                        },
                      }}
                    >
                      Yes
                    </Button>
                    <Button
                      variant="contained"
                      onClick={handleConfirmationClose}
                      style={{ backgroundColor: "red", color: "#fff" }}
                    >
                      No
                    </Button>
                  </DialogActions>
                </Dialog>
              </div>
              <RequirementModal
                isOpen={isRequirementtModalOpen}
                handleClose={handleCloseModal}
                title={""}
                content={<ViewOffer selectedJobOfferId={selectedJobOfferId} />}
              />
              <RequirementModal
                isOpen={isDeclineModalOpen}
                handleClose={handleCloseModalDecline}
                title={"Decline Reason"}
                content={<DeclineOffer jobOfferId={selectedJobOfferId} />}
              />
              {showCongratulatoryModal && (
                <CongratulatoryMessage
                  isOpen={showCongratulatoryModal}
                  handleClose={handleModalClose}
                />
              )}
            </Box>
          </Box>
        </div>
      ))}
    </>
  );
};

export default JobOfferContent;
