import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";

import Grid from "@mui/material/Grid";
import agcLogo from "../assets/images/agcLogo.png";
import subsLogo from "../assets/images/subsLogo.png";

export default function Header() {
  return (
    <AppBar
      position="fixed"
      sx={{
        backgroundColor: "#3D005D",

        justifyContent: "center",
      }}
    >
      <Toolbar>
        <Grid container  alignItems="center">
          <Grid item>
            {/* Left Image */}
            <img
              src={agcLogo}
              alt="AGC Logo"
              style={{ width: "100%", maxWidth: "200px",  }}
            />
          </Grid>
          <Grid item>
            {/* Right Image */}
            <img
              src={subsLogo}
              alt="Subs Logo"
              style={{ width: "100%", maxWidth: "200px", }}
            />
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
}
