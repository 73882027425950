import React from 'react';
import { Button, ButtonProps } from '@mui/material';


const ButtonField: React.FC<ButtonProps> = (props) => {
  return (
    <Button
      variant="contained" 
      {...props}
    />
  );
};

export default ButtonField;
