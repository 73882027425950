import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Login from "./pages/Login/login";
import Registration from "./pages/Registration/registration";
import Forgotpassword from "./pages/ForgotPassword/forgotpassword";
import VerifyOtp from "./pages/VerifyOtp/verifyotp";
import RecruiterDashboard from "./pages/Recruiter/Dashboard/RecruiterDashboard";
import NotFound from "./pages/Error/NotFound";
import PrivateRoute from "./routes/PrivateRoute";
import ApplicantList from "./pages/Recruiter/ApplicantList/ApplicantList";
import UserManagementList from "./pages/UserManagement/InternalList/UserManagementList";
import UserManagementDashboard from "./pages/UserManagement/Dashboard/UserManagementDashboard";
import ApplicationRequirements from "./pages/Applicant/ApplicationRequirements/ApplicationRequirements";
import JobOffer from "./pages/Applicant/JobOffer/JobOffer";
import ChangePassword from "./pages/ChangePassword/changepassword";
import ComingSoon from "./pages/Error/ComingSoon";
import RequirementProgress from "./pages/Recruiter/RequirementProgress/RequirementProgress";
import RequirementDetails from "./pages/Recruiter/RequirementProgress/RequirementDetails";
import RequirementList from "./pages/RecOpsHead/RequirementList/RequirementList";
import InternalRequirements from "./pages/RecOpsHead/InternalRequirements/InternalRequirements";
import SpecificInternalRequirement from "./pages/RecOpsHead/InternalRequirements/SpecificInternalRequirement";
import DashBoard from "./pages/RecOpsHead/Dashboard/DashBoard";
import AssignedApplicant from "./pages/Onboarding/AssignedApplicant/AssignedApplicant";
import RequirementView from "./pages/Onboarding/AssignedApplicant/RequirementView";
import Profile from './pages/Applicant/Profile/Profile'
import OnboardingDashboard from './pages/Onboarding/Dashboard/OnboardingDashboard' 
import ViewApplicantInformation from "./pages/Recruiter/RequirementProgress/ViewApplicantInformation";
import SelectedCandidates from "./pages/RecOpsHead/SelectedCandidates/SelectedCandidates";

const App: React.FC = () => {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/registration" element={<Registration />} />
          <Route path="/forgotpassword" element={<Forgotpassword />} />
          <Route path="/verifyotp" element={<VerifyOtp />} />
          <Route path="/change-password" element={<ChangePassword />} />
          <Route path="/coming-soon" element={<ComingSoon />} />
          <Route
            path="/recruiter-dashboard"
            element={
              <PrivateRoute
                element={<RecruiterDashboard />}
                allowedUserTypes={["Recruiter", "Onboarding Team"]}
              />
            }
          />
   
          <Route
            path="/system-admin-dashboard"
            element={
              <PrivateRoute
                element={<UserManagementDashboard />}
                allowedUserTypes={["System Admin"]}
              />
            }
          />
          <Route
            path="/recops-dashboard"
            element={
              <PrivateRoute
                element={<DashBoard />}
                allowedUserTypes={["RECOPS Head"]}
              />
            }
          />
              <Route
            path="/onboarding-dashboard"
            element={
              <PrivateRoute
                element={<OnboardingDashboard />}
                allowedUserTypes={["Onboarding Team"]}
              />
            }
          />
            <Route
            path="/assigned-applicant"
            element={
              <PrivateRoute
                element={<AssignedApplicant />}
                allowedUserTypes={["Onboarding Team"]}
              />
            }
          />
          <Route
            path="/assigned-applicant/:applicantId/:jobOfferId"
            element={
              <PrivateRoute
                element={<RequirementView />}
                allowedUserTypes={["Onboarding Team"]}
              />
            }
          />
          <Route
          path="/employee-information-sheet/:applicantId/*"
            element={
              <PrivateRoute
                element={<ViewApplicantInformation />}
                allowedUserTypes={["Onboarding Team", "Recruiter","RECOPS Head"]}
              />
            }
          />
          <Route
            path="/applicant-list"
            element={
              <PrivateRoute
                element={<ApplicantList />}
                allowedUserTypes={["Recruiter", "RECOPS Head"]}
              />
            }
          />
            <Route
            path="/selected-applicant-list"
            element={
              <PrivateRoute
                element={<SelectedCandidates/>}
                allowedUserTypes={["RECOPS Head"]}
              />
            }
          />
            <Route
            path="/internal-requirement"
            element={
              <PrivateRoute
                element={<InternalRequirements/>}
                allowedUserTypes={["Recruiter", "RECOPS Head"]}
              />
            }
          />
          <Route
            path="/requirement-progress"
            element={
              <PrivateRoute
                element={<RequirementProgress />}
                allowedUserTypes={["Recruiter", "RECOPS Head"]}
              />
            }
          />
           <Route
            path="/requirement-progress/:id"
            element={
              <PrivateRoute
                element={<SpecificInternalRequirement />}
                allowedUserTypes={["Recruiter", "RECOPS Head"]}
              />
            }
          />
          <Route
            path="/requirement-progress/:applicantId/:jobOfferId"
            element={
              <PrivateRoute
                element={<RequirementDetails />}
                allowedUserTypes={["Recruiter", "RECOPS Head"]}
              />
            }
          />

          <Route
            path="/recruiter-list"
            element={
              <PrivateRoute
                element={<UserManagementList />}
                allowedUserTypes={["System Admin"]}
              />
            }
          />
          <Route
            path="/my-requirements"
            element={
              <PrivateRoute
                element={<ApplicationRequirements />}
                allowedUserTypes={["Selected Applicant"]}
              />
            }
          />
          <Route
            path="/profile"
            element={
              <PrivateRoute
                element={<Profile />}
                allowedUserTypes={["Selected Applicant"]}
              />
            }
          />
          <Route
            path="/home"
            element={
              <PrivateRoute
                element={<JobOffer />}
                allowedUserTypes={["Selected Applicant"]}
              />
            }
          />
            <Route
            path="/requirement-list"
            element={
              <PrivateRoute
                element={<RequirementList />}
                allowedUserTypes={["RECOPS Head"]}
              />
            }
          />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </div>
    </Router>
  );
};

export default App;
