import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import TextInput from "../../../components/TextInput";
import InputAdornment from "@mui/material/InputAdornment";
import ButtonField from "../../../components/Button";
import Typography from "@mui/material/Typography";
import axios, { AxiosError } from "axios";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import dayjs from "dayjs";
import CircularProgress from "@mui/material/CircularProgress";
import CustomModal from "../../../components/Modal";
import { Applicant } from "./UserManagementContent";
import { v4 as uuidv4 } from "uuid";

interface EditApplicantProps {
  applicant: Applicant | null;
}

export default function EditInternal({ applicant }: EditApplicantProps) {
  const apiKey = process.env.REACT_APP_USER_SERVICE_API;
  const [formData, setFormData] = useState({
    firstName: applicant?.firstName || "",
    middleName: applicant?.middleName || "",
    lastName: applicant?.lastName || "",
    emailAddress: applicant?.emailAddress || "",
    contactNumber: applicant?.contactNumber || "",
    userType: applicant?.userType || "",
    userId: applicant?.userId || "",
    userStatus: applicant?.userStatus || "",
    isVerified: applicant?.isVerified || "",
  });

  const [formErrors, setFormErrors] = useState({
    firstName: "",
    middleName: "",
    lastName: "",
    emailAddress: "",
    contactNumber: "",
    userType: "",
    userId: "",
    userStatus: "",
    isVerified: "",
  });

  const [errorMessage, setErrorMessage] = useState({ message: "" });
  const [isLoading, setIsLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    if (formData.contactNumber.startsWith("+63")) {
      const updatedContactNumber = formData.contactNumber.substring(3);
      setFormData((prevData) => ({
        ...prevData,
        contactNumber: updatedContactNumber,
      }));
    }
  }, [formData.contactNumber]);

  const handleSelectChange = (id: string) => (e: SelectChangeEvent<string>) => {
    const value = e.target.value;

    setFormData((prevData) => ({ ...prevData, [id]: value }));
    setFormErrors((prevErrors) => ({ ...prevErrors, [id]: "" }));
    setIsLoading(false);
  };

  const handleInputChange =
    (id: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
      let value = e.target.value;

      if (["firstName", "middleName", "lastName"].includes(id)) {
        const nameRegex = /^[A-Za-z ]+$/; 
        if (value !== "" && !nameRegex.test(value)) {
          setFormErrors((prevErrors) => ({
            ...prevErrors,
            [id]: "Only letters are allowed",
          }));
          return;
        }
      }

      if (id === "contactNumber") {
        value = value.replace(/\D/g, "").replace(/^63/, "");
        setFormData((prevData) => ({ ...prevData, [id]: value }));
      }

      setFormData((prevData) => ({ ...prevData, [id]: value }));
      setFormErrors((prevErrors) => ({ ...prevErrors, [id]: "" }));
      setIsLoading(false);
    };

  const handleAddUser = async () => {
    setIsLoading(true);

    const requiredFields: (keyof typeof formData)[] = [
      "firstName",
      "lastName",
      "emailAddress",
      "contactNumber",
      "userType",
      "userStatus",
      "isVerified"
    ];

    const emptyFields: string[] = [];
    for (const field of requiredFields) {
      if (!formData[field]) {
        emptyFields.push(field);
      }
    }

    if (emptyFields.length > 0) {
      const errorMessages: Record<string, string> = {};
      emptyFields.forEach((field) => {
        errorMessages[field] = "This field is required";
      });
      setFormErrors((prevErrors) => ({ ...prevErrors, ...errorMessages }));
      setIsLoading(false);
      return;
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const contactNumberRegex = /^\d{10}$/;

    if (!emailRegex.test(formData.emailAddress)) {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        emailAddress: "Invalid email format",
      }));
      setIsLoading(false);
      return;
    }

    if (!contactNumberRegex.test(formData.contactNumber)) {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        contactNumber: "Invalid contact number format",
      }));
      setIsLoading(false);
      return;
    }

    const token = localStorage.getItem("accessToken");
    const formattedContactNumber = `+63${formData.contactNumber}`;
    const updatedFormData = {
      ...formData,
      contactNumber: formattedContactNumber,
      middleName:
        formData.middleName.trim() !== "" ? formData.middleName : undefined,
    };

    try {
      const response = await axios.patch(
        `${apiKey}/api/user-service/update-user`,
        updatedFormData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        setIsModalOpen(true);
      }
    } catch (error) {
      console.log(error);
      if (axios.isAxiosError(error)) {
        const axiosError: AxiosError = error;

        if (axiosError.response) {
          const status = axiosError.response.status;

          if (status === 401) {
            setErrorMessage({
              message: "Unauthorized. Please provide a valid Bearer Token.",
            });
          } else if (status === 409) {
            setErrorMessage({
              message: "User already exists with the provided email address.",
            });
          } else if (status === 422) {
            setErrorMessage({
              message: 'Unprocessable Entity. "addressee" is not allowed.',
            });
          } else if (status === 500) {
            setErrorMessage({
              message: "Internal Server Error.",
            });
          }
        }
      } else {
        console.error("Internal Server Error", error);
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Box
      component="form"
      sx={{
        "& .MuiTextField-root": {},
      }}
      noValidate
      autoComplete="off"
    >
      <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
          <TextInput
            required
            id="outlined-required"
            label="First Name"
            value={formData.firstName}
            onChange={handleInputChange("firstName")}
            error={!!formErrors.firstName}
            helperText={formErrors.firstName}
            inputProps={{
              maxLength: 256,
            }}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextInput
            id="outlined"
            label="Middle Name"
            value={formData.middleName}
            onChange={handleInputChange("middleName")}
            error={!!formErrors.middleName}
            helperText={formErrors.middleName}
            inputProps={{
              maxLength: 256,
            }}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextInput
            required
            id="outlined-required"
            label="Last Name"
            value={formData.lastName}
            onChange={handleInputChange("lastName")}
            error={!!formErrors.lastName}
            helperText={formErrors.lastName}
            inputProps={{
              maxLength: 256,
            }}
            fullWidth
          />
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <TextInput
            required
            id="outlined-required"
            label="Email Address"
            value={formData.emailAddress}
            onChange={handleInputChange("emailAddress")}
            error={!!formErrors.emailAddress}
            helperText={formErrors.emailAddress}
            inputProps={{
              maxLength: 256,
            }}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextInput
            required
            id="outlined-required"
            label="Contact Number"
            value={formData.contactNumber}
            onChange={handleInputChange("contactNumber")}
            error={!!formErrors.contactNumber}
            helperText={formErrors.contactNumber}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">+63</InputAdornment>
              ),
            }}
            inputProps={{
              maxLength: 10,
            }}
            fullWidth
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
          <FormControl variant="outlined" sx={{ mt: 2, width: "100%" }}>
            <InputLabel id="userType-label">User Type</InputLabel>
            <Select
              label="User Type"
              labelId="userType-label"
              id="outlined-required"
              value={formData.userType}
              onChange={handleSelectChange("userType")}
              error={!!formErrors.userType}
              inputProps={{
                maxLength: 256,
              }}
              fullWidth
            >
              <MenuItem value="Recruiter">Recruiter</MenuItem>
              <MenuItem value="Onboarding Team">Onboarding</MenuItem>
              <MenuItem value="RECOPS Head">RecOps Head</MenuItem>
              <MenuItem value="Data Management Team">
                Data Management Team
              </MenuItem>
              <MenuItem value="System Admin">System Admin</MenuItem>
            </Select>
            <Typography
              variant="caption"
              sx={{
                color: "#d32f2f",
                ml: 2,
                mt: 0.3,
              }}
            >
              {formErrors.userType}
            </Typography>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={4}>
          <FormControl variant="outlined" sx={{ mt: 2, width: "100%" }}>
            <InputLabel id="userStatus-label">User Status</InputLabel>
            <Select
              label="User Status"
              labelId="userStatus-label"
              id="outlined-required"
              value={formData.userStatus}
              onChange={handleSelectChange("userStatus")}
              error={!!formErrors.userStatus}
              inputProps={{
                maxLength: 256,
              }}
              fullWidth
            >
              <MenuItem value="active">Active</MenuItem>
              <MenuItem value="inactive">Not Active</MenuItem>
              <MenuItem value="blocked">Blocked</MenuItem>
            </Select>
            <Typography
              variant="caption"
              sx={{
                color: "#d32f2f",
                ml: 2,
                mt: 0.3,
              }}
            >
              {formErrors.userStatus}
            </Typography>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={4}>
          <FormControl variant="outlined" sx={{ mt: 2, width: "100%" }}>
            <InputLabel id="isVerified-label">Verification</InputLabel>
            <Select
              label="Verification"
              labelId="isVerified-label"
              id="outlined-required"
              value={formData.isVerified}
              onChange={handleSelectChange("isVerified")}
              error={!!formErrors.isVerified}
              inputProps={{
                maxLength: 256,
              }}
              fullWidth
            >
              <MenuItem value="verified">Verified</MenuItem>
              <MenuItem value="not-verified">Not Verified</MenuItem>
            </Select>
            <Typography
              variant="caption"
              sx={{
                color: "#d32f2f",
                ml: 2,
                mt: 0.3,
              }}
            >
              {formErrors.isVerified}
            </Typography>
          </FormControl>
        </Grid>
      </Grid>

      {errorMessage.message && (
        <Typography
          variant="body2"
          sx={{
            fontFamily: "Montserrat, sans-serif",
            color: "#d32f2f",
            ml: 1,
            mt: 1,
          }}
        >
          {errorMessage.message}
        </Typography>
      )}
      <ButtonField
        variant="contained"
        sx={{
          width: "30ch",
          mt: 3,
          mb: 2,
          backgroundColor: isLoading ? "#666666" : '#7901A3',
          "&:hover": {
            backgroundColor: "#7901A3",
          }, 
        }}
        onClick={handleAddUser}
      >
        {isLoading ? (
          <CircularProgress size={25} style={{ color: "white" }} />
        ) : (
          "Update"
        )}
      </ButtonField>
      <CustomModal
        open={isModalOpen}
        onClose={() => {
          setIsModalOpen(false);
          window.location.reload(); // Reload the page
        }}
        title={"Successfully Updated"}
        message={"The information has been successfully updated."}
        success={true}
      />
    </Box>
  );
}
