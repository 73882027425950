import React from "react";
import CustomDrawer from "../../../layouts/drawer/CustomDrawer";
import { Helmet } from "react-helmet";

const UserManagementDashboard = () => {
  return (
    <>
      <Helmet>
        <title>Onboard Hub | System Admin Dashboard</title>
      </Helmet>
      <CustomDrawer
        content={""}
        title="System Admin Dashboard"
      />
    </>
  );
};

export default UserManagementDashboard;
