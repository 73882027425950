import React from 'react'
import CustomDrawer from '../../../layouts/drawer/CustomDrawer'
import ApplicationContent from './ApplicationContent'
import {Helmet} from "react-helmet";

const ApplicationRequirements = () => {
  return (
    <>
    <Helmet>
      <title>OA | Applicant Requirements</title>
    </Helmet>
    <CustomDrawer content={<ApplicationContent />} title='Applicant Requirements' />
  </>
 
  )
}

export default ApplicationRequirements