import React from "react";
import Pagination, { PaginationProps } from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";

interface CustomPaginationOutlinedProps extends PaginationProps {
  count: number;
}

const CustomPaginationOutlined: React.FC<CustomPaginationOutlinedProps> = ({
  count,
  page,
  onChange,
}) => {
  return (
    <Stack spacing={2}>
      <Pagination
        count={count}
        page={page}
        onChange={onChange}
        shape="rounded"
        color="secondary"
      />
    </Stack>
  );
};

export default CustomPaginationOutlined;
