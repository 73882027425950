import React from "react";
import CustomDrawer from "../../../layouts/drawer/CustomDrawer";
import UserManagementContent from "./UserManagementContent";
import { Helmet } from "react-helmet";

const UserManagementList = () => {
  return (
    <>
      <Helmet>
        <title>Onboard Hub | Internal User</title>
      </Helmet>
      <CustomDrawer
        content={<UserManagementContent />}
        title="Internal Users List"
      />
    </>
  );
};

export default UserManagementList;
