import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import TextInput from "../../../components/TextInput";
import ButtonField from "../../../components/Button";
import Typography from "@mui/material/Typography";
import axios, { AxiosError } from "axios";
import CustomModal from "../../../components/Modal";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import { saveAs } from "file-saver";
import Button from "@mui/material/Button";
import Dropzone from "react-dropzone";
import cloudLogo from "../../../assets/icons/cloud.png";
import DescriptionIcon from "@mui/icons-material/Description";

export default function ViewOffer({
  selectedJobOfferId,
}: {
  selectedJobOfferId: string | null;
}) {
  const apiKey = process.env.REACT_APP_RECRUITMENT_API;

  const [formData, setFormData] = useState({
    documentStatus: "",
    requirement: "",
    templatePath: "",
    applicantRequirementId: "",
    file: null as File | null,
  });

  const [formErrors, setFormErrors] = useState({
    documentStatus: "",
    requirement: "",
    templatePath: "",
  });

  const [errorMessage, setErrorMessage] = useState({ message: "" });
  const [isLoading, setIsLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [filePreview, setFilePreview] = useState<string | null>(null);
  const [fileType, setFileType] = useState<"pdf" | "doc" | null>(null);

  useEffect(() => {
    const accessToken = localStorage.getItem("accessToken");
    const jobRequirements = async () => {
      try {
        const response = await axios.post(
          `${apiKey}/api/applicant/my-requirement`,
          {
            jobOfferId: selectedJobOfferId,
          },
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );

        if (response.status === 200) {
          const [result] = response.data.result;

          setFormData({
            documentStatus: result.documentStatus || "",
            requirement: result.requirement || "",
            templatePath: result.templatePath || "",
            applicantRequirementId: result.applicantRequirementId || "",
            file: result.file || "",
          });
        }
      } catch (error) {
        console.error(error);
      }
    };

    jobRequirements();
  }, [selectedJobOfferId, apiKey]);

 

  const handleFileUpload = async (e?: React.ChangeEvent<HTMLInputElement>) => {
    const accessToken = localStorage.getItem("accessToken");
    try {
      const response = await axios.post(
        `${apiKey}/api/applicant/process-document`,
        {
          file: formData.file,
          applicantRequirementId: formData.applicantRequirementId,
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.status === 200) {
        setIsModalOpen(true)
      }
    } catch (error) {
      console.error("Error uploading file:", error);
            if (axios.isAxiosError(error)) {
        const axiosError: AxiosError = error;

        if (axiosError.response) {
          const status = axiosError.response.status;
          if (status === 401) {
            setErrorMessage({
              message: "Unauthorized. Please provide a valid Bearer Token.",
            });
          } else if (status === 404) {
            setErrorMessage({
            message: "User not found."
            });
          } else if (status === 409) {
            setErrorMessage({
            message: "Record already exist. / Job offer is not yet accepted. You cannot proceed to send the requirement."
            });
          } else if (status === 410) {
            setErrorMessage({
            message: "You cannot proceed, Requirement Expired."
            });
          } else if (status === 413) {
            setErrorMessage({
            message: "File uploaded is too large."
            });
          } else if (status === 422) {
            setErrorMessage({
            message: "Unprocessable Entity. Required fields are missing or file-related errors."
            });
          } else if (status === 500) {
            setErrorMessage({
              message: "Internal Server Error. An unexpected error occurred.",
            });
          }
        }
      }
    }
  };

  const onDrop = (files: File[]) => {
    const uploadedFile = files[0];
    setFormData((prevData) => ({ ...prevData, file: uploadedFile }));

    const reader = new FileReader();
    reader.onloadend = () => {
      setFilePreview(reader.result as string);
      const isPDF = uploadedFile.type === "application/pdf";
      setFileType(isPDF ? "pdf" : "doc");
    };
    reader.readAsDataURL(uploadedFile);
  };

  const handleViewFile = () => {
    if (formData.file) {
      saveAs(formData.file, formData.file.name);
    }
  };


  const handleCancel = () => {
    setFormData({
      ...formData,
      file: null,
    });
    setFilePreview(null);
    setFileType(null);
  };

  return (
    <Box
      component="form"
      sx={{
        "& .MuiTextField-root": {},
      }}
      noValidate
      autoComplete="off"
    >
      <Dropzone onDrop={onDrop}>
        {({ getRootProps, getInputProps }) => (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              margin: "auto",
            }}
          >
            <Typography
              variant="h6"
              sx={{
                color: "#000",
                fontFamily: "Montserrat, sans-serif",
                marginBottom: "10px",
                fontWeight: "800",
              }}
            >
              Upload Signed Contract
            </Typography>
            <Box
              {...getRootProps()}
              sx={{
                width: "446px",
                height: "267px",
                border: "2px dashed #888",
                borderRadius: "10px",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                cursor: "pointer",
                backgroundColor: "#f0e4fc",
                margin: "10px", // Add margin to create space around the entire box
              }}
            >
              <input {...getInputProps()} />
              {/* Image icon inside the drop zone */}
              <img
                src={cloudLogo}
                alt="Cloud Icon"
                style={{
                  width: "50px",
                  height: "50px",
                  marginBottom: "10px",
                }}
              />
              <Typography
                sx={{
                  color: "#7901A3",
                  fontFamily: "Montserrat, sans-serif",
                  marginBottom: "10px", // Add margin to create space between image and text
                }}
              >
                Drag and drop to upload
              </Typography>

              <Box
                sx={{
                  width: "300px",
                  height: "50px",
                  backgroundColor: "#7901A3",
                  borderRadius: "10px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  cursor: "pointer",
                  marginTop: "10px",
                }}
              >
                <Typography
                  sx={{
                    color: "#FFF",
                    fontFamily: "Montserrat, sans-serif",
                  }}
                >
                  Upload
                </Typography>
              </Box>
            </Box>
            {formData.file && (
                  <>
             

             
                  <Box
                    sx={{
                      width: "446px",
                      height: "50px",
                      transition: "height 0.5s",
                      backgroundColor: "#E5E5E5",
                      borderRadius: "10px",
                      justifyContent: "space-between",
                      alignItems: 'center',
                      display: "flex",
                    }}
                  >
                 <div style={{display: "flex", flexDirection: 'row', marginLeft: '10px'}}>
                 <DescriptionIcon sx={{  color: "#A8A8A8" }} />
                    <Typography sx={{ color: "#000" }}>{`${formData.file.name}`}</Typography>
                 </div>
    
                    <div style={{ display: "flex", flexDirection: "row",  marginRight: '10px' }}>
                      
                      <Typography
                        onClick={handleViewFile}
                        sx={{
                          fontWeight: "600",
                          color: "#0094FF",
                          fontFamily: "Montserrat, sans-serif",
                        }}
                      >
                        View
                      </Typography>
    
                      <Typography
                        sx={{
                          color: "#0094FF",
                          fontFamily: "Montserrat, sans-serif",
                          fontWeight: "600",
                          cursor: "pointer",
                          marginLeft: '5px'
                        }}
                        onClick={() => handleCancel()}
                      >
                        Cancel
                      </Typography>
                    </div>
                  </Box>
    
                  <Box
                    display="flex"
                    justifyContent="center" // Center the buttons
                    sx={{ margin: "15px" }}
                  >
                    <ButtonField
                      variant="contained"
                      onClick={handleFileUpload as any}
                      sx={{
                        width: "216px",
                        marginRight: "10px",
                        backgroundColor: "#7901A3",
                        "&:hover": {
                          backgroundColor: "#7901A3",
                        },
                      }}
                    >
                      Submit
                    </ButtonField>
                    <ButtonField
                      variant="outlined"
                      color="secondary"
                      onClick={() => handleCancel()}
                      sx={{ width: "216px" }}
                    >
                      Cancel
                    </ButtonField>
                  </Box>
    
                  {errorMessage.message && (
                    <Typography
                      variant="body2"
                      sx={{
                        fontFamily: "Montserrat, sans-serif",
                        color: "#d32f2f",
                        ml: 1,
                        mt: 1,
                      }}
                    >
                      {errorMessage.message}
                    </Typography>
                  )}
                </>
            )}
        
          </Box>
        )}
      </Dropzone>

      <CustomModal
        open={isModalOpen}
        onClose={() => {
          setIsModalOpen(false);
          window.location.reload();
        }}
        title={"Successfully Added"}
        message={"The document is successfully uploaded."}
        success={true}
      />
    </Box>
  );
}
