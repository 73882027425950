import React from 'react';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

interface CustomModalProps {
  isOpen: boolean;
  handleClose: () => void;
  content: React.ReactNode;
}

const CustomModal: React.FC<CustomModalProps> = ({ isOpen, handleClose, content }) => {
  return (
    <Modal
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="custom-modal-title"
      aria-describedby="custom-modal-description"
    >
      <Box
        sx={{
          position: 'absolute',
          width: '80%', 
          maxHeight: '80vh', 
          overflow: 'auto',  
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          backgroundColor: '#FFF',
          borderRadius: '10px',
          boxShadow: 24,
          p: 4,
          '@media (min-width: 600px)': {
            width: '60%', 
          },
          '@media (min-width: 960px)': {
            width: '40%', 
          },
        }}
      >
        <IconButton
          edge="end"
          color="inherit"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            top: 0,
            right: 20,
          }}
        >
          <CloseIcon />
        </IconButton>

        {content}
      </Box>
    </Modal>
  );
}

export default CustomModal;
