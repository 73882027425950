import React from 'react'
import CustomDrawer from '../../../layouts/drawer/CustomDrawer'
import {Helmet} from "react-helmet";
import OnboardingDashboardContent from './OnboardingDashboardContent';

const OnboardingDashboard = () => {
  
  return (
    <>
         <Helmet>
        <title>Onboard Hub | Dashboard</title>
      </Helmet>
     <CustomDrawer content={<OnboardingDashboardContent/>} title='Dashboard' />
    </>
  )
}

export default OnboardingDashboard;