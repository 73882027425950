import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import ButtonField from "../../../components/Button";
import Typography from "@mui/material/Typography";
import axios, { AxiosError } from "axios";
import { Applicant } from "./ApplicantContent";
import Grid from "@mui/material/Grid";
import TextInput from "../../../components/TextInput";
import CustomModal from "../../../components/Modal";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import CircularProgress from "@mui/material/CircularProgress";

interface OnboardApplicantProps {
  applicant: Applicant | null;
}

interface OnboardingData {
  userId: number;
  onboardingName: string;
  onBoardingId: string;
  // Add other properties as needed
}

export default function AddOnboarding({ applicant }: OnboardApplicantProps) {
  const apiKeyUser = process.env.REACT_APP_USER_SERVICE_API;
  const apiKey = process.env.REACT_APP_RECRUITMENT_API;
  const [formData, setFormData] = useState({
    selectedOnboarding: null as OnboardingData | null,
    jobOfferId: applicant?.jobOfferId || "",
  });
  const [formErrors, setFormErrors] = useState({
    onBoardingName: "",
    jobOfferId: "",
  });

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState({ message: "" });
  const [isLoading, setIsLoading] = useState(false);
  const [onboardingData, setOnboardingData] = useState<OnboardingData[]>([]);

  useEffect(() => {
    const token = localStorage.getItem("accessToken");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    axios
      .get(
        `${apiKeyUser}/api/user-service/onboarding-team`,
        {
          headers: config.headers,
        }
      )
      .then((response) => {
        if (response.status === 200) {
          setOnboardingData(response.data);

        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, []);

  const handleAssignOnboarding = async () => {
    const token = localStorage.getItem("accessToken");

    try {
      if (!formData.selectedOnboarding) {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          onBoardingName: "Please select an onboarding name.",
        }));
        return;
      }

      const { userId, onboardingName } = formData.selectedOnboarding;
      const requestData = {
        onBoardingId: userId,
        onBoardingName: onboardingName,
        jobOfferId: formData.jobOfferId,
      };

      const response = await axios.patch(
        `${apiKey}/api/recruitment/assign-onboarding`,
        requestData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        setIsModalOpen(true);
      }
    } catch (error) {
      console.log(error);
      if (axios.isAxiosError(error)) {
        const axiosError: AxiosError = error;

        if (axiosError.response) {
          const status = axiosError.response.status;

          if (status === 401) {
            setErrorMessage({
              message: "Unauthorized. Please provide a valid Bearer Token.",
            });
          } else if (status === 404) {
            setErrorMessage({
              message: "User not found.",
            });
          } else if (status === 409) {
            setErrorMessage({
              message:
                "Record already exist. / Job offer is not yet accepted. You cannot proceed to send the requirement.",
            });
          } else if (status === 413) {
            setErrorMessage({
              message: "File uploaded is too large.",
            });
          } else if (status === 415) {
            setErrorMessage({
              message: "Invalid file type. Please upload a PDF file.",
            });
          } else if (status === 422) {
            setErrorMessage({
              message:
                "Unprocessable Entity. Required fields are missing or file-related errors.",
            });
          } else if (status === 500) {
            setErrorMessage({
              message: "Internal Server Error.",
            });
          }
        }
      } else {
        console.error("Internal Server Error", error);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleSelectChange = (id: string) => (e: SelectChangeEvent<string>) => {
    const value = e.target.value;

    setFormData((prevData) => ({ ...prevData, [id]: value }));
    setFormErrors((prevErrors) => ({ ...prevErrors, [id]: "" }));
    setIsLoading(false);
  };

  return (
    <Box
      component="form"
      sx={{
        "& .MuiTextField-root": {},
      }}
      noValidate
      autoComplete="off"
    >
      <FormControl variant="outlined" sx={{ width: "100%", mt: 2 }}>
        <InputLabel id="subsidiary-label">Onboarding Name</InputLabel>
        <Select
          label="Onboarding Name"
          labelId="onboardingName-label"
          id="outlined-required"
          value={
            formData.selectedOnboarding
              ? formData.selectedOnboarding.onboardingName
              : ""
          }
          onChange={(e) => {
            const selectedOnboarding =
              onboardingData.find(
                (item) => item.onboardingName === e.target.value
              ) || null;
            setFormData((prevData) => ({ ...prevData, selectedOnboarding }));
            setFormErrors((prevErrors) => ({
              ...prevErrors,
              onBoardingName: "",
            }));
            setIsLoading(false);
          }}
          error={!!formErrors.onBoardingName}
          inputProps={{
            maxLength: 256,
          }}
          fullWidth
        >
          {onboardingData.map((item) => (
            <MenuItem key={item.userId} value={item.onboardingName}>
              {item.onboardingName}
            </MenuItem>
          ))}
        </Select>
        <Typography
          variant="caption"
          sx={{
            color: "#d32f2f",
            ml: 2,
            mt: 0.3,
          }}
        >
          {formErrors.onBoardingName}
        </Typography>
      </FormControl>

      {errorMessage.message && (
        <Typography
          variant="body2"
          sx={{
            fontFamily: "Montserrat, sans-serif",
            color: "#d32f2f",
            ml: 1,
            mt: 1,
          }}
        >
          {errorMessage.message}
        </Typography>
      )}
      <ButtonField
        variant="contained"
        sx={{
          width: "25ch",
          mt: 3,
          mb: 2,
          backgroundColor: isLoading ? "#666666" : "#7901A3",
          "&:hover": {
            backgroundColor: "#7901A3",
          },
        }}
        onClick={handleAssignOnboarding}
        disabled={isLoading}
      >
        {isLoading ? (
          <CircularProgress size={25} style={{ color: "white" }} />
        ) : (
          "Assign"
        )}
      </ButtonField>
      <CustomModal
        open={isModalOpen}
        onClose={() => {
          setIsModalOpen(false);
          window.location.reload(); // Reload the page
        }}
        title={"Successfully Assigned"}
        message={"The applicant was assigned to the onboarding team."}
        success={true}
      />
    </Box>
  );
}
