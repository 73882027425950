import React from 'react';
import Box from '@mui/material/Box';
import './Loader.css'; // Import the CSS file

const Loader = () => (
  <Box
    sx={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100%',

    }}
  >
    <span className="loader"></span>
  </Box>
);

export default Loader;
