import React, { useState } from "react";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import axios, { AxiosError } from "axios";
import { useNavigate } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import { Helmet } from "react-helmet";
import { styled } from "@mui/system";
import bestOfBoth from "../../assets/images/bestOfBoth.png";
import people from "../../assets/images/people.png";
import awards from "../../assets/images/awards.png";
import Header from "../../components/Header";
import agcLogo from "../../assets/images/agcLogo.png";
import backgroundImage from "../../assets/images/bgLogin.png";
import onboardingLogo from "../../assets/images/onBoardingLogo.png";
const ContainerWithHeader = styled("div")`
  position: relative;

`;

const BackgroundContainer = styled(Box)`
height: 100vh;
  background: url(${backgroundImage}) center/cover no-repeat;
  display: flex;
  align-items: center;
`;

const ForgotPassword: React.FC = () => {
  const apiKey = process.env.REACT_APP_USER_SERVICE_API;
  const navigate = useNavigate();
  const emailAddPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

  const [emailAdd, setEmailadd] = useState("");
  // Error Message
  const [emailAddError, setEmailaddError] = useState("");
  const [errorMessage, setErrorMessage] = useState({ message: "" });
  //Loader
  const [isLoading, setIsLoading] = useState(false);

  const handleVerify = async () => {
    setEmailaddError("");
    setIsLoading(true);

    if (!emailAdd) {
      setEmailaddError("Email Address is required");
      setIsLoading(false);
      return;
    }

    if (!emailAdd.match(emailAddPattern)) {
      setEmailaddError("Invalid Email Address");
      setIsLoading(false);
      return;
    }

    try {
      const response = await axios.post(
        `${apiKey}/api/user-service/forgot-password`,
        {
          emailAddress: emailAdd,
        }
      );

      if (response.status === 200) {
        localStorage.setItem("emailAddress", emailAdd);
        setErrorMessage({ message: "You are now logged in!" });
        navigate("/verifyotp", { replace: true });
        // setIsModalOpen(true);
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const axiosError: AxiosError = error;

        if (axiosError.response) {
          const status = axiosError.response.status;

          if (status === 404) {
            setErrorMessage({
              message: "The user was not found.",
            });
          } else if (status === 422) {
            setErrorMessage({
              message:
                "Unprocessable Entity. If you didn't follow the required fields or added an excess field",
            });
          } else if (status === 500) {
            setErrorMessage({
              message: "Internal Server Error. An unexpected error occurred.",
            });
          } else {
            console.error(
              "Internal Server Error. An unexpected error occurred:",
              axiosError
            );
          }
        }
      } else {
        console.error(
          "Internal Server Error. An unexpected error occurred.",
          error
        );
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Helmet>
        <title>Onboard Hub | Forgot Password</title>
      </Helmet>

      <React.Fragment>
        <CssBaseline />

        <ContainerWithHeader>
          <Header />

          <BackgroundContainer>
            <Grid container>
              {/* Left Column (Login Form) */}
              <Grid
                item
                xs={12}
                sm={6}
                md={6}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Paper
                  variant="outlined"
                  sx={{
                    p: { md: 2 },
                    borderRadius: { md: "20px" },
                    boxShadow: 3,
                  }}
                >
                  <Box
                    component="form"
                    sx={{
                      my: { xs: 12, sm: 0, md: 4 },
                      mx: 4,
                      display: "flex",
                      flexDirection: "column",
                      alignItems: 'center'
                    }}
                  >
     
                    <img src={onboardingLogo} alt="onboardingLogo"  style={{width: '120px'}}/>

                    <TextField
                      value={emailAdd}
                      margin="normal"
                      required
                      fullWidth
                      id="email"
                      label="Email Address"
                      name="email"
                      autoFocus
                      error={!!emailAddError}
                      helperText={emailAddError}
                      onChange={(e) => {
                        setEmailadd(e.target.value);
                        setEmailaddError("");
                        setErrorMessage({ message: "" });
                      }}
                      inputProps={{ maxLength: 64 }}
                    />

                    <Typography
                      variant="subtitle2"
                      sx={{
                        fontFamily: "Montserrat, sans-serif",
                        color: "#666666",
                        fontWeight: "400",
                      }}
                    >
                      You will receive the Password on your registered <br />{" "}
                      email address.
                    </Typography>
                    <Grid item xs>
                      {errorMessage.message && (
                        <Typography
                          variant="body2"
                          sx={{
                            fontFamily: "Montserrat, sans-serif",
                            color: "#d32f2f",
                          }}
                        >
                          {errorMessage.message}
                        </Typography>
                      )}
                    </Grid>

                    <Button
                      fullWidth
                      variant="contained"
                      sx={{
                        mt: 3,
                        mb: 2,
                        backgroundColor: "#3D005D",
                        color: "#FFF",
                        "&:hover": {
                          backgroundColor: "#2A003E",
                        },
                      }}
                      onClick={handleVerify}
                      disabled={isLoading} // Disable the button while loading
                    >
                      {isLoading ? (
                        <CircularProgress size={24} sx={{ color: "#FFF" }} />
                      ) : (
                        "Submit"
                      )}
                    </Button>

                    <Typography
                      variant="subtitle1"
                      sx={{
                        fontFamily: "Montserrat, sans-serif",
                        color: "#666666",
                        textAlign: "center",
                      }}
                    >
                      Already have an account? Click{" "}
                      <span
                        onClick={() => navigate("/")}
                        style={{
                          color: "#2074d4",
                          cursor: "pointer",
                          textDecoration: "underline",
                          fontFamily: "Montserrat, sans-serif",
                        }}
                      >
                        Here
                      </span>
                      <span
                        style={{
                          fontFamily: "Montserrat, sans-serif",
                          color: "#666666",
                          textAlign: "center",
                        }}
                      >
                        {" "}
                        to Sign In!
                      </span>
                    </Typography>
                  </Box>
                </Paper>
              </Grid>
              </Grid>

           
          </BackgroundContainer>
        </ContainerWithHeader>
      </React.Fragment>
    </>
  );
};
export default ForgotPassword;
