import React from 'react'
import CustomDrawer from '../../../layouts/drawer/CustomDrawer'
import {Helmet} from "react-helmet";
import InternalRequirementsContent from './InternalRequirementsContent';

const RequirementList = () => {
  return (
    <>
    <Helmet>
      <title>Onboard Hub | Internal Requirements</title>
    </Helmet>
    <CustomDrawer content={<InternalRequirementsContent/>} title='Internal Requirements' />
  </>
 
  )
}

export default RequirementList;