import React from "react";
import { Navigate } from "react-router-dom";

interface PrivateRouteProps {
  element: React.ReactNode;
  allowedUserTypes: string[];
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({
  element,
  allowedUserTypes,
}) => {
  const token = localStorage.getItem("accessToken");
  const userType =
    localStorage.getItem("RecruiterType") ||
    localStorage.getItem("ApplicantType") ||
    localStorage.getItem("SystemAdminType") ||
    localStorage.getItem("RecOpsHeadType") ||
    localStorage.getItem("OnboardingType");

  // Check if the user type is allowed for this route
  const isAllowed = allowedUserTypes.includes(userType || "");

  return token && isAllowed ? <>{element}</> : <Navigate to="/" />;
};

export default PrivateRoute;
