import React from 'react'
import CustomDrawer from '../../../layouts/drawer/CustomDrawer'
import {Helmet} from "react-helmet";
import ProfileContent from './ProfileContent';

const Profile = () => {
  return (
    <>
    <Helmet>
      <title>Onboarding Hub | Profile</title>
    </Helmet>
    <CustomDrawer content={<ProfileContent />} title='Applicant Requirements' />
  </>
 
  )
}

export default Profile