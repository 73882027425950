import React, { useState, ChangeEvent } from "react";
import Grid from "@mui/material/Grid";
import TextInput from "../../../components/TextInput";
import ButtonField from "../../../components/Button";
import axios, { AxiosError } from "axios";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import CircularProgress from "@mui/material/CircularProgress";
import CustomModal from "../../../components/Modal";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import { Box, Typography, Button } from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { styled } from "@mui/system";
import {Requirement} from "./SpecificInternalRequirementContent"

interface RequirementProps {
    requirementId: Requirement | null;
    
  }
  
const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
  });
  
export default function AddRequirement({ requirementId }: RequirementProps) {

  const apiKey = process.env.REACT_APP_RECRUITMENT_API;
  const [formData, setFormData] = useState({
    requirement: "",
    withTemplate: "",
    userType: "External",
    requirementId: requirementId
 
  });
  const [selectedFile, setSelectedFile] = useState<File | null>(null);

  const [formErrors, setFormErrors] = useState({

    requirement: "",
    withTemplate: "",
  });

  const [errorMessage, setErrorMessage] = useState({ message: "" });
  const [isLoading, setIsLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleAddGroup = async () => {
    setIsLoading(true);

    const requiredFields: (keyof typeof formData)[] = ["requirement","withTemplate",];

    const emptyFields: string[] = [];
    for (const field of requiredFields) {
      if (!formData[field]) {
        emptyFields.push(field);
      }
    }

    if (emptyFields.length > 0) {
      const errorMessages: Record<string, string> = {};
      emptyFields.forEach((field) => {
        errorMessages[field] = "This field is required";
      });
      setFormErrors((prevErrors) => ({ ...prevErrors, ...errorMessages }));
      setIsLoading(false);
      return;
    }

    const token = localStorage.getItem("accessToken");

   
  try {
    const formDataToSend = new FormData();
    formDataToSend.append("requirement", formData.requirement);
    formDataToSend.append("withTemplate", formData.withTemplate);
    formDataToSend.append("groupId", String(formData.requirementId));
    formDataToSend.append("userType", formData.userType);
    if (selectedFile) {
      formDataToSend.append("file", selectedFile);
    }

    const response = await axios.post(
      `${apiKey}/api/recops-head/special-requirement`,
      formDataToSend,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data", 
        },
      }
    );

    if (response.status === 200) {
      setIsModalOpen(true);
    }
  } catch (error) {
      console.log(error);
      if (axios.isAxiosError(error)) {
        const axiosError: AxiosError = error;

        if (axiosError.response) {
          const status = axiosError.response.status;

          if (status === 401) {
            setErrorMessage({
              message: "Unauthorized. Please provide a valid Bearer Token.",
            });
          } else if (status === 409) {
            setErrorMessage({
              message: "User already exists with the provided email address.",
            });
          } else if (status === 422) {
            setErrorMessage({
              message: 'Unprocessable Entity. "addressee" is not allowed.',
            });
          } else if (status === 500) {
            setErrorMessage({
              message: "Internal Server Error.",
            });
          }
        }
      } else {
        console.error("Internal Server Error", error);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleInputChange =
    (id: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
      let value = e.target.value;
      setFormData((prevData) => ({ ...prevData, [id]: value }));
      setFormErrors((prevErrors) => ({ ...prevErrors, [id]: "" }));
      setIsLoading(false);
    };

  const handleSelectChange = (id: string) => (e: SelectChangeEvent<string>) => {
    const value = e.target.value;

    setFormData((prevData) => ({ ...prevData, [id]: value }));
    setFormErrors((prevErrors) => ({ ...prevErrors, [id]: "" }));
    setIsLoading(false);
  };

  return (
    <Box
      component="form"
      sx={{
        "& .MuiTextField-root": {},
      }}
      noValidate
      autoComplete="off"
    >
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <TextInput
            required
            id="outlined-required"
            label="Requirement Name"
            value={formData.requirement}
            onChange={handleInputChange("requirement")}
            error={!!formErrors.requirement}
            helperText={formErrors.requirement}
            inputProps={{
              maxLength: 256,
            }}
            fullWidth
          />


        </Grid>
        <Grid item xs={12} md={6}>
            

          <FormControl variant="outlined" sx={{ mt: 2, width: "100%" }}>
            <InputLabel id="withTemplate-label">With Template</InputLabel>
            <Select
              label="With Template"
              labelId="withTemplate-label"
              id="outlined-required"
              value={formData.withTemplate}
              onChange={handleSelectChange("withTemplate")}
              error={!!formErrors.withTemplate}
              inputProps={{
                maxLength: 256,
              }}
              fullWidth
            >
              <MenuItem value="yes">Yes</MenuItem>
              <MenuItem value="no">No</MenuItem>
            </Select>
            <Typography
              variant="caption"
              sx={{
                color: "#d32f2f",
                ml: 2,
                mt: 0.3,
              }}
            >
              {formErrors.withTemplate}
            </Typography>
          </FormControl>
        </Grid>
        <Grid item >  
        <Button
            component="label"
            variant="contained"
            startIcon={<CloudUploadIcon />}
            sx={{      backgroundColor: "#7901A3",
            "&:hover": {
              backgroundColor: "#7901A3",
            },}}

          >
            Upload file
            <VisuallyHiddenInput
              type="file"
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                // Handle the file selection here
                const selected = e.target.files?.[0] ?? null;
                setSelectedFile(selected);
              }}
            />
          </Button>
          {selectedFile && (
            <Typography variant="body1" sx={{ marginTop: '5px'}}>
             File Uploaded: {selectedFile.name}
            </Typography>
          )}
        </Grid>
      </Grid>

      {errorMessage.message && (
        <Typography
          variant="body2"
          sx={{
            fontFamily: 'Montserrat, sans-serif',
            color: "#d32f2f",
            ml: 1,
            mt: 1,
          }}
        >
          {errorMessage.message}
        </Typography>
      )}
      <ButtonField
        variant="contained"
        sx={{
          width: "30ch",
          mt: 3,
          mb: 2,
          backgroundColor: isLoading ? "#666666" : "#7901A3",
          "&:hover": {
            backgroundColor: "#7901A3",
          },
        }}
        onClick={handleAddGroup}
      >
        {isLoading ? (
          <CircularProgress size={25} style={{ color: "white" }} />
        ) : (
          "Add Requirement"
        )}
      </ButtonField>
      <CustomModal
        open={isModalOpen}
        onClose={() => {
          setIsModalOpen(false);
          window.location.reload(); // Reload the page
        }}
        title={"Successfully Added"}
        message={"The requirement has been successfully added."}
        success={true}
      />
    </Box>
  );
}
