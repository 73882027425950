import React, { useEffect, useState, ChangeEvent } from "react";
import axios, { AxiosError } from "axios";
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
  Typography,
  Button,
  TableCell,
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import StyledTableCell from "../../../components/TableStyle/StyledTableCell";
import StyledTableRow from "../../../components/TableStyle/StyledTableRow";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { styled } from "@mui/system";

interface Applicant {
  applicantRequirementId: string;
  requirement: string;
  prioritization: string;
  deadline: string;
  dateCompleted: string;
  documentStatus: string;
  jobId: string;
  applicantId: string;
  prfId: string;
  jobOfferId: string;
  userType: string;
  templatePath: string;
  uploadedDocument: string;
  s3_path: string;
  completionPercentage: number;
  id: string;
  withTemplate: string;
}

interface ApiResponse {
  result: Applicant[];
}

const cellTextStyle = {
  fontFamily: "Montserrat, sans-serif",
  fontWeight: "400",
  fontSize: "13px",
  whiteSpace: "normal",
};
const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const RequirementListContent = () => {
  const apiKey = process.env.REACT_APP_RECRUITMENT_API;
  const [data, setData] = useState<Applicant[]>([]);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState({ message: "" });
  const [selectedRowId, setSelectedRowId] = useState<string | null>(null);
  const [isUploadModalOpen, setUploadModalOpen] = useState(false);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const columns = [
    { id: "no", label: "No." },
    { id: "requirement", label: "Requirement" },
    { id: "template", label: "Template" },
    { id: "actions", label: "Actions" },
  ];
  
  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    setLoading(true);
    try {
      const accessToken = localStorage.getItem("accessToken");
      const response = await axios.post(
        `${apiKey}/api/recops-head/default-requirement-list`,
        {},
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      setData(response.data.result);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleFileUpload = async () => {
    if (selectedFile) {
      try {
        const formData = new FormData();
        formData.append("file", selectedFile);

        const accessToken = localStorage.getItem("accessToken");

        // Include the file parameter in the FormData
        const requestBody = new FormData();
        requestBody.append("id", selectedRowId || "");
        requestBody.append("withTemplate", "yes");
        requestBody.append("file", selectedFile);

        const response = await axios.patch(
          `${apiKey}/api/recops-head/default-requirement`,
          requestBody,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
              "Content-Type": "multipart/form-data",
            },
          }
        );

        if (response.status === 200) {
          setUploadModalOpen(false);
          fetchData();
        }
      } catch (error) {
        console.error("Error uploading file:", error);
        if (axios.isAxiosError(error)) {
          const axiosError: AxiosError = error;
          if (axiosError.response) {
            const status = axiosError.response.status;

            switch (status) {
              case 200:
                setUploadModalOpen(false);
                fetchData();
                break;
              case 400:
                setErrorMessage({
                  message: "You cannot add a default template on contract.",
                });
                break;
              case 401:
                setErrorMessage({
                  message: "Unauthorized. Please provide a valid Bearer Token.",
                });
                break;
              case 404:
                setErrorMessage({
                  message: "No Data Found.",
                });
                break;
              case 413:
                setErrorMessage({
                  message: "File uploaded is too large.",
                });
                break;
              case 422:
                setErrorMessage({
                  message:
                    "Unprocessable Entity. Required fields are missing or file-related errors.",
                });
                break;
              case 500:
                setErrorMessage({
                  message: "Internal server error.",
                });
                break;

              default:
                setErrorMessage({
                  message: `Error with status code ${status}.`,
                });
            }
          }
        }
      }
    } // Closing brace for handleFileUpload
  };

  const handleYesClick = async (withTemplate: string) => {
    try {
      const accessToken = localStorage.getItem("accessToken");
      // Common request body for both cases
      const requestBody = new FormData();
      requestBody.append("id", selectedRowId || "");
      requestBody.append("withTemplate", withTemplate);

      // If withTemplate is "yes", add the file parameter to the body
      if (withTemplate === "yes") {
        setUploadModalOpen(true);
      } else {
        await axios.patch(
          `${apiKey}/api/recops-head/default-requirement`,
          requestBody,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
              "Content-Type": "multipart/form-data", // Important for file uploads
            },
          }
        );
        fetchData();
      }
    } catch (error) {
      console.error("Error performing action:", error);

      if (axios.isAxiosError(error)) {
        const axiosError: AxiosError = error;

        if (axiosError.response) {
          const status = axiosError.response.status;

          switch (status) {
            case 401:
              setErrorMessage({
                message: "Unauthorized. Please provide a valid Bearer Token.",
              });
              break;
            case 422:
              setErrorMessage({
                message: 'Unprocessable Entity. "addressee" is not allowed.',
              });
              break;
            case 500:
              setErrorMessage({
                message: "Internal Server Error. An unexpected error occurred.",
              });
              break;
            // Add more cases for other status codes if needed
            default:
              setErrorMessage({
                message: `Error with status code ${status}.`,
              });
          }
        }
      }
    }
  };


  return (
    <div>
      <Typography
        variant="h4"
        sx={{
          fontFamily: "Montserrat, sans-serif",
          fontWeight: "700",
          marginBottom: 3,
          marginTop: 1,
        }}
      ></Typography>

      <div style={{ display: "flex", justifyContent: "center" }}>
        <TableContainer component={Paper}>
          <Table  sx={{ minWidth: 650 }}>
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <StyledTableCell
                    key={column.id}
                    sx={{
                      fontFamily: "Montserrat, sans-serif",
                      fontWeight: "800",
                    }}
                  >
                    {column.label}
                  </StyledTableCell>
                ))}
              </TableRow>
            </TableHead>

            <TableBody>
              {loading ? (
                <TableRow>
                  <TableCell colSpan={columns.length} align="center">
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        height: "100%",
                      }}
                    >
                      <CircularProgress size={70} />
                    </Box>
                  </TableCell>
                </TableRow>
              ) : data.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={columns.length} align="center">
                    <Typography
                      variant="h5"
                      sx={{fontFamily: "Montserrat, sans-serif",}}
                    >
                      No data available in the table
                    </Typography>
                  </TableCell>
                </TableRow>
              ) : (
                data.map((row, index) => (
                  <StyledTableRow
                    key={row.applicantRequirementId}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell sx={cellTextStyle}>{index + 1}</TableCell>
                    <TableCell sx={cellTextStyle}>{row.requirement}</TableCell>
                    <TableCell
                      sx={{ ...cellTextStyle, textTransform: "capitalize" }}
                    >
                      {row.withTemplate}
                    </TableCell>
                    <TableCell sx={cellTextStyle}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                        }}
                      >
                        <Typography
                          sx={{
                            ...cellTextStyle,
                            textDecoration: "underline",
                            cursor: "pointer",
                            color: "#2196f3",
                          }}
                          onClick={() => {
                            handleYesClick("yes");
                            setSelectedRowId(row.id);
                          }}
                        >
                          Yes
                        </Typography>
                        <span style={{ margin: "0 5px" }}>or</span>
                        <Typography
                          sx={{
                            ...cellTextStyle,
                            textDecoration: "underline",
                            cursor: "pointer",
                            color: "#2196f3",
                          }}
                          onClick={() => {
                            handleYesClick("no");
                            setSelectedRowId(row.id);
                          }}
                        >
                          No
                        </Typography>
                      </div>
                    </TableCell>
                  </StyledTableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>

      <Dialog
        open={isUploadModalOpen}
        onClose={() => setUploadModalOpen(false)}
        PaperProps={{
          sx: {
            width: "40%", // Adjust the width as needed
          },
        }}
      >
        <DialogTitle>Upload File</DialogTitle>
        <DialogContent
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <Button
            component="label"
            variant="contained"
            startIcon={<CloudUploadIcon />}
          >
            Upload file
            <VisuallyHiddenInput
              type="file"
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                const selected = e.target.files?.[0] ?? null;
                setSelectedFile(selected);
              }}
            />
          </Button>
          {selectedFile && (
            <Typography variant="body1" sx={{ marginLeft: 2 }}>
              {selectedFile.name}
            </Typography>
          )}
        </DialogContent>
        {errorMessage.message && (
          <Typography
            variant="body2"
            sx={{
              fontFamily: "Montserrat, sans-serif",
              color: "#d32f2f",
              ml: 3,
            }}
          >
            {errorMessage.message}
          </Typography>
        )}
        <DialogActions>
          <Button
            onClick={() => {
              setUploadModalOpen(false);
              setErrorMessage({
                message: "",
              });
              setSelectedFile(null);
            }}
          >
            Cancel
          </Button>
          <Button onClick={handleFileUpload} autoFocus>
            Upload
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default RequirementListContent;
