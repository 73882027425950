import React from 'react';
import Box from '@mui/material/Box';
import { styled } from '@mui/system';

const ResponsiveIframeContainer = styled('div')({
  position: 'relative',
  width: '100%',
  paddingTop: '56.25%', // 16:9 aspect ratio (height/width * 100%)
});

const ResponsiveIframe = styled('iframe')({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
});

const OnboardingDashboardContent = () => {
  return (
    <ResponsiveIframeContainer>
      <ResponsiveIframe
        title="project_oa"
        src="https://app.powerbi.com/view?r=eyJrIjoiMjY2ODg2YmMtZDIzNy00NzVlLWJlY2EtMDk0MzYzODQ1MDc2IiwidCI6ImVlOWJmNDNiLTFkMTAtNDA0Mi1iODFiLWNiNjk5MTQyZjJlNCIsImMiOjEwfQ%3D%3D"
        frameBorder="0"
        allowFullScreen
      ></ResponsiveIframe>
    </ResponsiveIframeContainer>
  );
};

export default OnboardingDashboardContent;
