import React from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import imageUrl from "../../../assets/images/bobbie.png";
import { Typography, Button } from "@mui/material";

interface CustomModalProps {
  isOpen: boolean;
  handleClose: any;
}

const CongratulatoryMessage: React.FC<CustomModalProps> = ({
  isOpen,
  handleClose,
}) => {
  return (
    <Modal
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="custom-modal-title"
      aria-describedby="custom-modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          width: "80%",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          backgroundColor: "#FFF",
          borderRadius: "10px",
          boxShadow: 24,
          p: 4,
          "@media (min-width: 600px)": {
            width: "60%",
          },
          "@media (min-width: 960px)": {
            width: "25%",
          },
        }}
      >
        <IconButton
          edge="end"
          color="inherit"
          onClick={handleClose}
          sx={{
            position: "absolute",
            top: 0,
            right: 20,
          }}
        >
          <CloseIcon />
        </IconButton>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <img
            src={imageUrl}
            alt="Logo"
            style={{ width: "201px", height: "209px", marginBottom: "20px" }}
          />
          <Typography
            variant="h4"
            sx={{
              fontFamily: "Montserrat, sans-serif",
              fontWeight: "700",
              marginBottom: "10px",
              color: '#000'
            }}
          >
            Congratulations!
          </Typography>
          <Typography
            variant="subtitle1"
            sx={{
              fontFamily: "Montserrat, sans-serif",
              fontWeight: "500",
              marginBottom: "20px",
              textAlign: 'center',
              color: '#000'
            }}
          >
            Your contract has been Validated, you can now proceed
    
            in the requirement submission section to complete your
     
            onboarding requirements.
            <br />
    
            Thank you!
          </Typography>

          <Button
  onClick={() => handleClose()}
            sx={{
              backgroundColor: "#7B01A3",
              color: "#FFF",
              "&:hover": {
                backgroundColor: "#7B01A3",
              },
              borderRadius: '20px',
              width: '202px',
              height: '45px',
            }}
          >
            Close
          </Button>
        </div>
      </Box>
    </Modal>
  );
};

export default CongratulatoryMessage;
