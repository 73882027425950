import React from "react";

import CustomDrawer from "../../../layouts/drawer/CustomDrawer";

import { Helmet } from "react-helmet";
import ViewApplicantInformationContent from "./ViewApplicantInformationContent";

const ViewApplicantInformation: React.FC = () => {

  
  return (
    <>
      <Helmet>
        <title>Onboarding Hub | Employee Information Sheet</title>
      </Helmet>
      <CustomDrawer
        content={<ViewApplicantInformationContent />}
        title="Employee Information Sheet"
      />
    </>
  );
};

export default ViewApplicantInformation;
