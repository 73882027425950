import React from "react";
import { Modal, Button, Typography } from "@mui/material";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline"; // Import the success icon
import "./Modal.scss";

interface ModalProps {
  open: boolean;
  onClose: () => void;
  title: string;
  message?: string;
  subMessage?: string;
  success: boolean; // Add a success prop
}

const CustomModal: React.FC<ModalProps> = ({
  open,
  onClose,
  title,
  message,
  subMessage,
  success, // Include the success prop
}) => {
  return (
    <Modal open={open} onClose={onClose}>
      <div className="modal-container">
        <div className="modal-content">
          {success && (
            <CheckCircleOutlineIcon
              sx={{
                color: "green",
                fontSize: 150,
              }}
            />
          )}
          <Typography
            variant="h4"
            sx={{
              fontFamily: "Montserrat, sans-serif",
              textAlign: "center",
              fontWeight: '600',
              mb: 1,
            }}
          >
            {title}
          </Typography>
          <Typography
            variant="body1"
            sx={{
              fontFamily: "Montserrat, sans-serif",
              color: "#666666",
              textAlign: "center",
            }}
          >
            {message}
          </Typography>
          {subMessage && (
              <Typography
              variant="body1"
              sx={{
                fontFamily: "Montserrat, sans-serif",
                color: "#666666",
                textAlign: "center",
                mb: 1,
              }}
            >
              {subMessage}
            </Typography>
          )}
          <Button onClick={onClose} variant="contained"   sx={{
          width: "30ch",
          mt: 3,
          mb: 2,
          backgroundColor: "#7901A3",
          "&:hover": {
            backgroundColor: "#7901A3",
          },
        }}>
            Close
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default CustomModal;
