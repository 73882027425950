import React, { useState, useEffect, ChangeEvent } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import TextInput from "../../../components/TextInput";
import InputAdornment from "@mui/material/InputAdornment";
import ButtonField from "../../../components/Button";
import Typography from "@mui/material/Typography";
import axios, { AxiosError } from "axios";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import dayjs from "dayjs";
import CircularProgress from "@mui/material/CircularProgress";
import CustomModal from "../../../components/Modal";
import { v4 as uuidv4 } from "uuid";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { styled } from "@mui/system";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

interface JobData {
  prfId: string;
  jobId: string;
  jobTitle: string;
}

export default function AddApplicant() {
  const apiKey = process.env.REACT_APP_USER_SERVICE_API;
  const apiKeyRecruitment = process.env.REACT_APP_RECRUITMENT_API;
  const [jobTitlesList, setJobTitlesList] = useState<string[]>([]);
  const [jobDataList, setJobDataList] = useState<JobData[]>([]);
  const [talentSegmentList, setTalentSegmentList] = useState<string[]>([]);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [formData, setFormData] = useState({
    firstName: "",
    middleName: "",
    lastName: "",
    emailAddress: "",
    contactNumber: "",
    startDate: null as string | null,
    subsidiary: "",
    talentSegment: "",
    // applicantStatus: "",
    applicantId: uuidv4(),
    prfId: "",
    jobTitle: "",
    jobId: "",
    withdrawStatus: "",
    deadline: null as string | null,
  });

  const [formErrors, setFormErrors] = useState({
    firstName: "",
    middleName: "",
    lastName: "",
    emailAddress: "",
    contactNumber: "",
    jobTitle: "",
    startDate: "",
    subsidiary: "",
    talentSegment: "",
    // applicantStatus: "",
    prfId: "",
    withdrawStatus: "",
    deadline: "",
  });

  const [errorMessage, setErrorMessage] = useState({ message: "" });
  const [isLoading, setIsLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    fetchReferenceList();
    fetchTalentSegment()
  }, []);

  const fetchReferenceList = async () => {
    try {
      const response = await axios.post<JobData[]>(
        `${apiKeyRecruitment}/api/recruitment/ref-list`
      );

      setJobDataList(response.data);

      const jobTitles = response.data.map(
        (item: JobData) => `${item.jobTitle}`
      );
      setJobTitlesList(jobTitles);
    } catch (error) {
      console.error("Error fetching reference list:", error);
    }
  };

  
  const fetchTalentSegment = async () => {
    const token = localStorage.getItem("accessToken");
    try {
      const response = await axios.get( 
        `${apiKeyRecruitment}/api/recruitment/talent-segment`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
        
      const talentSegments = response.data.result.map((segment: any) => segment.talentSegment);
      setTalentSegmentList(talentSegments);


    } catch (error) {
      console.error("Error fetching user information:", error);
    }
  };

  const handleInputChange =
    (id: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
      let value = e.target.value;

      if (["firstName", "middleName", "lastName"].includes(id)) {
        const nameRegex = /^[A-Za-z ]+$/; 
        if (value !== "" && !nameRegex.test(value)) {
          setFormErrors((prevErrors) => ({
            ...prevErrors,
            [id]: "Only letters are allowed",
          }));
          return;
        }
      }

      if (id === "contactNumber") {
        value = value.replace(/\D/g, "");
      }

      setFormData((prevData) => ({ ...prevData, [id]: value }));
      setFormErrors((prevErrors) => ({ ...prevErrors, [id]: "" }));
      setIsLoading(false);
    };

  const setJobIdAndTitle = (selectedJobTitle: string) => {
    const selectedJobData = jobDataList.find(
      (jobData) => jobData.jobTitle === selectedJobTitle
    );

    if (selectedJobData) {
      setFormData((prevData) => ({
        ...prevData,
        jobId: selectedJobData.jobId,
        jobTitle: selectedJobTitle,
      }));
    }
  };

  const handleSelectChange = (id: string) => (e: SelectChangeEvent<string>) => {
    const value = e.target.value;

    if (id === "jobTitle") {
      setJobIdAndTitle(value);
    } else {
      setFormData((prevData) => ({ ...prevData, [id]: value }));
    }

    setFormData((prevData) => ({ ...prevData, [id]: value }));
    setFormErrors((prevErrors) => ({ ...prevErrors, [id]: "" }));
    setIsLoading(false);
  };

  const handleAddApplicant = async () => {

    setIsLoading(true);

    const requiredFields: (keyof typeof formData)[] = [
      "firstName",
      "lastName",
      "emailAddress",
      "contactNumber",
      "startDate",
      "subsidiary",
      "talentSegment",
      // "applicantStatus",
      "jobTitle",
      "prfId",
      "deadline",
    ];

    const emptyFields: string[] = [];
    for (const field of requiredFields) {
      if (!formData[field]) {
        emptyFields.push(field);
      }
    }

    if (emptyFields.length > 0) {
      const errorMessages: Record<string, string> = {};
      emptyFields.forEach((field) => {
        errorMessages[field] = "This field is required";
      });
      setFormErrors((prevErrors) => ({ ...prevErrors, ...errorMessages }));
      setIsLoading(false);
      return;
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const contactNumberRegex = /^\d{10}$/;

    if (!emailRegex.test(formData.emailAddress)) {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        emailAddress: "Invalid email format",
      }));
      setIsLoading(false);
      return;
    }

    if (!contactNumberRegex.test(formData.contactNumber)) {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        contactNumber: "Invalid contact number format",
      }));
      setIsLoading(false);
      return;
    }



    const token = localStorage.getItem("accessToken");
    const formattedContactNumber = `+63${formData.contactNumber}`;

    try {
      // First API request
      const firstApiRequestData = {
        applicantId: formData.applicantId,
        firstName: formData.firstName,
        middleName:
          formData.middleName.trim() !== "" ? formData.middleName : undefined,
        lastName: formData.lastName,
        emailAddress: formData.emailAddress,
        contactNumber: formattedContactNumber,
      };

      const response = await axios.post(
        `${apiKey}/api/user-service/add-applicant`,
        firstApiRequestData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        const responseData = response.data; 
        let applicantId = formData.applicantId;
        if(responseData.applicantId){
          applicantId = responseData.applicantId;
        }

        const secondApiRequestData = new FormData();
    
        if (selectedFile) {

          secondApiRequestData.append('file', selectedFile);
        }
    
        // secondApiRequestData.append('jobId', formData.jobId);
        secondApiRequestData.append('applicantId', applicantId);
        secondApiRequestData.append('prfId', formData.prfId);
        secondApiRequestData.append('jobTitle', formData.jobTitle);
        secondApiRequestData.append('deadline', formData.deadline || '');
        secondApiRequestData.append('talentSegment', formData.talentSegment);
        secondApiRequestData.append('subsidiary', formData.subsidiary);
        secondApiRequestData.append('applicantEmail', formData.emailAddress);
        secondApiRequestData.append('firstName', formData.firstName);
        if (formData.middleName.trim() !== "") {
          secondApiRequestData.append('middleName', formData.middleName.trim());
        }

        secondApiRequestData.append('lastName', formData.lastName);
        if (formData.withdrawStatus.trim() !== "") {
          secondApiRequestData.append('withdrawStatus', formData.withdrawStatus.trim());
        }
        secondApiRequestData.append('startDate', formData.startDate || '');
        // secondApiRequestData.append('applicantStatus', formData.applicantStatus); // Uncomment if needed
        secondApiRequestData.append('contactNumber', formattedContactNumber);
    

        const offerJobResponse = await axios.post(
          `${apiKeyRecruitment}/api/recruitment/offer-job`,
          secondApiRequestData,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "multipart/form-data", 
            },
          }
        );

        if (offerJobResponse.status === 200) {
          setIsModalOpen(true);
        }
      }
    } catch (error) {
      // console.log(error);
      if (axios.isAxiosError(error)) {
        const axiosError: AxiosError = error;

        if (axiosError.response) {
          const status = axiosError.response.status;

          if (status === 401) {
            setErrorMessage({
              message: "Unauthorized. Please provide a valid Bearer Token.",
            });
          } else if (status === 409) {
            setErrorMessage({
              message: "User already exists with the provided email address.",
            });
          } else if (status === 422) {
            setErrorMessage({
              message: 'Unprocessable Entity. "addressee" is not allowed.',
            });
          } else if (status === 500) {
            setErrorMessage({
              message: "Internal Server Error.",
            });
          }
        }
      } else {
        console.error("Internal Server Error", error);
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Box
      component="form"
      sx={{
        "& .MuiTextField-root": {},
      }}
      noValidate
      autoComplete="off"
    >
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
        <TextInput
            required
            id="outlined-required"
            label="PRF ID"
            value={formData.prfId}
            onChange={handleInputChange("prfId")}
            error={!!formErrors.prfId}
            helperText={formErrors.prfId}
            inputProps={{
              maxLength: 256,
            }}
            fullWidth
          />
         
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
          <TextInput
            required
            id="outlined-required"
            label="First Name"
            value={formData.firstName}
            onChange={handleInputChange("firstName")}
            error={!!formErrors.firstName}
            helperText={formErrors.firstName}
            inputProps={{
              maxLength: 256,
            }}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextInput
            id="outlined"
            label="Middle Name"
            value={formData.middleName}
            onChange={handleInputChange("middleName")}
            error={!!formErrors.middleName}
            helperText={formErrors.middleName}
            inputProps={{
              maxLength: 256,
            }}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextInput
            required
            id="outlined-required"
            label="Last Name"
            value={formData.lastName}
            onChange={handleInputChange("lastName")}
            error={!!formErrors.lastName}
            helperText={formErrors.lastName}
            inputProps={{
              maxLength: 256,
            }}
            fullWidth
          />
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <TextInput
            required
            id="outlined-required"
            label="Email Address"
            value={formData.emailAddress}
            onChange={handleInputChange("emailAddress")}
            error={!!formErrors.emailAddress}
            helperText={formErrors.emailAddress}
            inputProps={{
              maxLength: 256,
            }}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextInput
            required
            id="outlined-required"
            label="Contact Number"
            value={formData.contactNumber}
            onChange={handleInputChange("contactNumber")}
            error={!!formErrors.contactNumber}
            helperText={formErrors.contactNumber}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">+63</InputAdornment>
              ),
            }}
            inputProps={{
              maxLength: 10,
            }}
            fullWidth
          />
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
          <TextInput
            required
            id="outlined-required"
            label="Position"
            value={formData.jobTitle}
            onChange={handleInputChange("jobTitle")}
            error={!!formErrors.jobTitle}
            helperText={formErrors.jobTitle}
            inputProps={{
              maxLength: 256,
            }}
            fullWidth
          />
          {/* <FormControl variant="outlined" sx={{ mt: 2, width: "100%" }}>
            <InputLabel id="jobTitle-label">Position</InputLabel>
            <Select
              label="Position"
              labelId="jobTitle-label"
              id="outlined-required"
              value={formData.jobTitle}
              onChange={handleSelectChange("jobTitle")}
              error={!!formErrors.jobTitle}
              inputProps={{
                maxLength: 256,
              }}
              fullWidth
            >
              {jobTitlesList.map((title) => (
                <MenuItem key={title} value={title}>
                  {title}
                </MenuItem>
              ))}
            </Select>
            <Typography
              variant="caption"
              sx={{
                color: "#d32f2f",
                ml: 2,
                mt: 0.3,
              }}
            >
              {formErrors.jobTitle}
            </Typography>
          </FormControl> */}
        </Grid>
        <Grid item xs={12} md={4}>
          <FormControl variant="outlined" sx={{ width: "100%", mt: 2 }}>
            <InputLabel id="subsidiary-label">Subsidiary</InputLabel>
            <Select
              label="Subsidiary"
              labelId="subsidiary-label"
              id="outlined-required"
              value={formData.subsidiary}
              onChange={handleSelectChange("subsidiary")}
              error={!!formErrors.subsidiary}
              inputProps={{
                maxLength: 256,
              }}
              fullWidth
            >
              <MenuItem value="asticom">ASTICOM</MenuItem>
              <MenuItem value="absi">ABSI</MenuItem>
              <MenuItem value="finsi">FINSI</MenuItem>
              <MenuItem value="hcx">HCX</MenuItem>
              <MenuItem value="acquiro">ACQUIRO</MenuItem>
            </Select>
            <Typography
              variant="caption"
              sx={{
                color: "#d32f2f",
                ml: 2,
                mt: 0.3,
              }}
            >
              {formErrors.subsidiary}
            </Typography>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={4}>
          <FormControl variant="outlined" sx={{ mt: 2, width: "100%" }}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="Start Date"
                value={formData.startDate}
                slotProps={{ textField: { variant: "outlined" } }}
                onChange={(newValue) => {
                  if (newValue !== null && newValue !== "") {
                    const formattedDate = dayjs(newValue).format("YYYY-MM-DD");
                    setFormData({ ...formData, startDate: formattedDate });
                  } else {
                    setFormData({ ...formData, startDate: newValue });
                  }
                }}
    
              />
            </LocalizationProvider>
            <Typography
              variant="caption"
              sx={{
                color: "#d32f2f",
                ml: 2,
                mt: 0.2,
              }}
            >
              {formErrors.startDate}
            </Typography>
          </FormControl>
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        {/* <Grid item xs={12} md={6}>
          <FormControl variant="outlined" sx={{ width: "100%", mt: 2 }}>
            <InputLabel id="subsidiary-label">Applicant Status</InputLabel>
            <Select
              label="Applicant Status"
              labelId="subsidiary-label"
              id="outlined-required"
              value={formData.applicantStatus}
              onChange={handleSelectChange("applicantStatus")}
              error={!!formErrors.applicantStatus}
              inputProps={{
                maxLength: 256,
              }}
              fullWidth
            >
              <MenuItem value="Pending">Pending</MenuItem>
              <MenuItem value="Onboarded">Onboarded</MenuItem>
              <MenuItem value="Withdrawn/Declined">Withdrawn/Declined</MenuItem>
              <MenuItem value="For New Employee Orientation">
                For New Employee Orientation
              </MenuItem>
              <MenuItem value="Offcycle - Onboarded">
                Offcycle - Onboarded
              </MenuItem>
              <MenuItem value="Rehire - Onboarded">Rehire - Onboarded</MenuItem>
            </Select>
            <Typography
              variant="caption"
              sx={{
                color: "#d32f2f",
                ml: 2,
                mt: 0.3,
              }}
            >
              {formErrors.applicantStatus}
            </Typography>
          </FormControl>
        </Grid> */}

        <Grid item xs={12} md={6}>
        <FormControl variant="outlined" sx={{ width: "100%", mt: 2 }}>
      <InputLabel id="talentSegment-label">Talent Segment</InputLabel>
      <Select
        label="Talent Segment"
        labelId="talentSegment-label"
        id="outlined-required"
        value={formData.talentSegment}
        onChange={handleSelectChange("talentSegment")}
        error={!!formErrors.talentSegment}
        inputProps={{
          maxLength: 256,
        }}
        fullWidth
      >
        {talentSegmentList.map((segment) => (
          <MenuItem key={segment} value={segment}>
            {segment}
          </MenuItem>
        ))}
      </Select>
      <Typography
        variant="caption"
        sx={{
          color: "#d32f2f",
          ml: 2,
          mt: 0.3,
        }}
      >
        {formErrors.talentSegment}
      </Typography>
    </FormControl>
        </Grid>
        <Grid item xs={12} md={6}>
          <FormControl variant="outlined" sx={{ mt: 2, width: "100%" }}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="Deadline"
                value={formData.deadline}
                slotProps={{ textField: { variant: "outlined" } }}
                onChange={(newValue) => {
                  if (newValue !== null && newValue !== "") {
                    const formattedDate = dayjs(newValue).format("YYYY-MM-DD");
                    setFormData({ ...formData, deadline: formattedDate });
                  } else {
                    setFormData({ ...formData, deadline: newValue });
                  }
                }}
    
              />
            </LocalizationProvider>
            <Typography
              variant="caption"
              sx={{
                color: "#d32f2f",
                ml: 2,
                mt: 0.2,
              }}
            >
              {formErrors.deadline}
            </Typography>
          </FormControl>
        </Grid>
      </Grid>
      <Grid item xs={12} md={6}>
      <Typography variant="body1" sx={{  fontFamily: "Montserrat, sans-serif", mt: 2,}}>Job Offer</Typography>
      <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
        
      <Button
            component="label"
            variant="contained"
            startIcon={<CloudUploadIcon />}
            sx={{      mt: 0.5,backgroundColor: "#7901A3",
            "&:hover": {
              backgroundColor: "#7901A3", 
            },}}

          >
            Upload file
            <VisuallyHiddenInput
              type="file"
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                // Handle the file selection here
                const selected = e.target.files?.[0] ?? null;
                setSelectedFile(selected);
              }}
            />
          </Button>
          {selectedFile && (
            <Typography variant="body1" sx={{ marginTop: '5px', marginLeft: '10px' }}>
             {selectedFile.name}
            </Typography>
          )}
      </div>

      </Grid>
      {errorMessage.message && (
        <Typography
          variant="body2"
          sx={{
            fontFamily: "Montserrat, sans-serif",
            color: "#d32f2f",
            ml: 1,
            mt: 1,
          }}
        >
          {errorMessage.message}
        </Typography>
      )}
      <ButtonField
        variant="contained"
        sx={{
          width: "30ch",
          mt: 3,
          mb: 2,
          backgroundColor: isLoading ? "#666666" : '#7901A3',
          "&:hover": {
            backgroundColor: "#7901A3",
          }
        }}
        onClick={handleAddApplicant}
        disabled={isLoading}
      >
        {isLoading ? (
          <CircularProgress size={25} style={{ color: "white" }} />
        ) : (
          "Add Applicant"
        )}
      </ButtonField>
      <CustomModal
        open={isModalOpen}
        onClose={() => {
          setIsModalOpen(false);
          window.location.reload(); // Reload the page
        }}
        title={"Successfully Added"}
        message={"Applicant was successfully added."}
        success={true}
      />
    </Box>
  );
}
