import React from 'react'
import CustomDrawer from '../../../layouts/drawer/CustomDrawer'
import { Helmet } from 'react-helmet'
import JobOfferContent from './JobOfferContent'

const JobOffer = () => {
  return (
    <>
       <Helmet>
      <title>Onboard Hub | Applicant Requirements</title>
    </Helmet>
     <CustomDrawer content={<JobOfferContent />} title='Job Offer' />
    </>
  )
}

export default JobOffer;