import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

interface DotProps {
    color: string;
    size?: number;
    status?: string; // Add the status property
  }
  
  const Dot: React.FC<DotProps> = ({ color, size, status }) => {
    const theme = useTheme();
    let main;
    switch (color) {
      case 'secondary':
        main = theme.palette.secondary.main;
        break;
      case 'error':
        main = theme.palette.error.main;
        break;
      case 'warning':
        main = '#ffb014';
        break;
      case 'info':
        main = theme.palette.info.main;
        break;
      case 'success':
        main = theme.palette.success.main;
        break;
      case 'primary':
      default:
        main = theme.palette.primary.main;
    }
  
    return (
      <Box
        sx={{
          width: size || 8,
          height: size || 8,
          borderRadius: '50%',
          bgcolor: main
        }}
      />
    );
  };
  

Dot.propTypes = {
  color: PropTypes.string.isRequired, // Mark color as required
  size: PropTypes.number
};

export default Dot;
