import React from 'react';
import Box from '@mui/material/Box';
import { styled } from '@mui/system';

const ResponsiveIframeContainer = styled('div')({
  position: 'relative',
  width: '100%',
  paddingTop: '56.25%', // 16:9 aspect ratio (height/width * 100%)
});

const ResponsiveIframe = styled('iframe')({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
});

const DashboardContent = () => {
  return (
    <ResponsiveIframeContainer>
      <ResponsiveIframe
        title="project_oa"
        src="https://app.powerbi.com/view?r=eyJrIjoiMWI0ZDZjZWEtZTc5ZC00MmZiLTgwZWEtOTFkMDEyMjVhMTA1IiwidCI6ImVlOWJmNDNiLTFkMTAtNDA0Mi1iODFiLWNiNjk5MTQyZjJlNCIsImMiOjEwfQ%3D%3D"
        frameBorder="0"
        allowFullScreen
      ></ResponsiveIframe>
    </ResponsiveIframeContainer>
  );
};

export default DashboardContent;
