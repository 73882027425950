import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
  TextField,
  Button,
  Typography,
  Select,
  MenuItem,
  SelectChangeEvent,
  FormControl,
  IconButton,
} from "@mui/material";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { styled } from "@mui/material/styles";
import { Edit as EditIcon } from "@mui/icons-material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import moment from "moment";
import { Add as AddIcon } from "@mui/icons-material";
import CustomPaginationOutlined from "../../../components/CustomPagination";
import dayjs from "dayjs";
import CircularProgress from "@mui/material/CircularProgress";
import { Refresh as RefreshIcon } from "@mui/icons-material";
import SearchIcon from "@mui/icons-material/Search";
import RecruiterModal from "../../UserManagement/InternalList/InternalModal";
import AddGroup from "./AddGroup";
import { useNavigate } from "react-router-dom";
import EditGroup from "./EditGroup";
import StyledMenu from "../../../components/TableStyle/StyledMenu";
import StyledTableCell from "../../../components/TableStyle/StyledTableCell";
import StyledTableRow from "../../../components/TableStyle/StyledTableRow";
import MoreVertIcon from "@mui/icons-material/MoreVert";

export interface GroupRequirements {
  groupName: string;
  createdAt: string;
  updatedAt: string;
  id: string;
}


const InternalRequirementsContent = () => {
  const navigate = useNavigate();
  const apiKey = process.env.REACT_APP_RECRUITMENT_API;
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [data, setData] = useState<GroupRequirements[]>([]);
  const [totalCount, setTotalCount] = useState(0);
  const [startDateFilter, setStartDateFilter] = useState<string | null>(null);
  const [endDateFilter, setEndDateFilter] = useState<string | null>(null);
  const [selectedRowsPerPage, setSelectedRowsPerPage] = useState(10);
  const [isAddApplicantModalOpen, setIsAddApplicantModalOpen] = useState(false);
  const [isEditApplicantModalOpen, setIsEditApplicantModalOpen] =
    useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingFilter, setLoadingFilter] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedGroupRequirements, setSelectedGroupRequirements] = useState<GroupRequirements | null>(
    null
  );
  const open = Boolean(anchorEl);
  const [filters, setFilters] = useState({
    groupName: "",
  });
  const columns = [
    { id: "no", label: "No." },
    { id: "groupName", label: "Group Name" },
    { id: "createdAt", label: "Created At" },
    { id: "updatedAt", label: "Updated At" },
    { id: "actions", label: "Actions" },
  ];

  useEffect(() => {
    fetchData(page + 1, rowsPerPage);
  }, [page, rowsPerPage]);

  const fetchData = async (page: number, limit: number) => {
    try {
      setLoading(true);
      const accessToken = localStorage.getItem("accessToken");

      const response = await axios.post(
        `${apiKey}/api/recops-head/fetch-special-group`,
        {
          page,
          limit,
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      if (response.status === 200) {
        setData(response.data.result);
        setTotalCount(response.data.total);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleApplyFilter = async () => {
    try {
      setLoadingFilter(true);
      const accessToken = localStorage.getItem("accessToken");

      const nonEmptyFilters: Record<string, string> = {};
      Object.entries(filters).forEach(([key, value]) => {
        if (value !== "") {
          nonEmptyFilters[key] = value;
        }
      });

      const requestPayload: Record<string, any> = {
        page: 1,
        limit: rowsPerPage,
        ...(Object.keys(nonEmptyFilters).length > 0 && {
          filters: nonEmptyFilters,
        }),
      };

      if (startDateFilter && endDateFilter) {
        requestPayload.dateFilter = {
          field: "createdAt",
          start: startDateFilter,
          end: endDateFilter,
        };
      }

      const response = await axios.post(
        `${apiKey}/api/recops-head/fetch-special-group`,
        requestPayload,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      setData(response.data.result);
      setTotalCount(response.data.total);
      setPage(0);
    } catch (error) {
      console.error("Error applying filter:", error);
    } finally {
      setLoadingFilter(false);
    }
  };

  const handleRefresh = () => {
    setFilters({
      groupName: "",
    });

    setStartDateFilter(null);
    setEndDateFilter(null);

    fetchData(page + 1, rowsPerPage);
  };

  const handleOpenAddApplicantModal = () => {
    setIsAddApplicantModalOpen(true);
  };

  const handleCloseAddApplicantModal = () => {
    setIsAddApplicantModalOpen(false);
  };

  const handleOpenEditApplicantModal = () => {
    setIsEditApplicantModalOpen(true);
  };

  const handleCloseEditApplicantModal = () => {
    setIsEditApplicantModalOpen(false);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick = (
    event: React.MouseEvent<HTMLElement>,
    groupRequirements: GroupRequirements
  ) => {
    setAnchorEl(event.currentTarget);
    setSelectedGroupRequirements(groupRequirements);
  };

  const handleFilterChange = (field: string, value: string) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [field]: value,
    }));
  };

  const handleRowClick = (id: string) => {
    navigate(`/requirement-progress/${id}`, { state: { id } });
  };

  const handleChangeRowsPerPageSelect = (event: SelectChangeEvent<number>) => {
    const newRowsPerPage = Number(event.target.value);
    setSelectedRowsPerPage(newRowsPerPage);
    setRowsPerPage(newRowsPerPage);
    setPage(0);
  };

  const handleChangePageOutlined = (
    event: React.ChangeEvent<unknown>,
    newPage: number
  ) => {
    setPage(newPage - 1);
  };

  const cellTextStyle = {
    fontFamily: "Montserrat, sans-serif",
    fontWeight: "400",
    fontSize: "13px",
  };

  return (
    <div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          marginBottom: "10px",
          justifyContent: "space-between",
        }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <TextField
            label="Group Name"
            variant="outlined"
            value={filters.groupName}
            onChange={(e) => handleFilterChange("groupName", e.target.value)}
          />
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label="Start Date"
              value={startDateFilter}
              slotProps={{ textField: { variant: "outlined" } }}
              onChange={(newValue) => {
                if (newValue !== null && newValue !== "") {
                  const formattedDate = dayjs(newValue).format("YYYY-MM-DD");
                  setStartDateFilter(formattedDate);
                } else {
                  setStartDateFilter(newValue);
                }
              }}
              sx={{ marginLeft: "10px", marginRight: "10px" }}
            />
            <DatePicker
              label="End Date"
              value={endDateFilter}
              slotProps={{ textField: { variant: "outlined" } }}
              onChange={(newValue) => {
                if (newValue !== null && newValue !== "") {
                  const formattedDate = dayjs(newValue).format("YYYY-MM-DD");
                  setEndDateFilter(formattedDate);
                } else {
                  setEndDateFilter(newValue);
                }
              }}
              sx={{ marginRight: "10px" }}
            />
          </LocalizationProvider>
          <Button
            variant="contained"
            onClick={handleApplyFilter}
            disabled={loadingFilter}
            sx={{ width: "25ch", marginLeft: "10px", backgroundColor: '#7901A3',
            "&:hover": {
              backgroundColor: "#7901A3",
            }, }}
          >
            <SearchIcon />
            <div style={{ visibility: loadingFilter ? "hidden" : "visible" }}>
              Search
            </div>
            {loadingFilter && (
              <CircularProgress
                size={24}
                sx={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  marginTop: "-12px",
                  marginLeft: "-12px",
                }}
              />
            )}
          </Button>

          <Button
            variant="contained"
            onClick={handleRefresh}
            size="medium"
            sx={{ ml: 1, backgroundColor: '#7901A3',
            "&:hover": {
              backgroundColor: "#7901A3",
            },  }}
          >
            <RefreshIcon />
          </Button>
        </div>
        <Button
          variant="contained"
          onClick={handleOpenAddApplicantModal}
          startIcon={<AddIcon />}
          sx={{ marginLeft: 2, width: "30ch", backgroundColor: '#7901A3' ,
          "&:hover": {
            backgroundColor: "#7901A3",
          } }}
          
        >
          Add Group
        </Button>
      </div>

      <div style={{ display: "flex", justifyContent: "center" }}>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }}>
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <StyledTableCell
                    key={column.id}
                    sx={{
                      fontFamily: "Montserrat, sans-serif",
                      fontWeight: "800",
                    }}
                  >
                    {column.label}
                  </StyledTableCell>
                ))}
              </TableRow>
            </TableHead>

            <TableBody>
              {loading ? (
                <TableRow>
                  <TableCell colSpan={columns.length} align="center">
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        height: "100%",
                      }}
                    >
                      <CircularProgress size={70} />
                    </Box>
                  </TableCell>
                </TableRow>
              ) : data.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={columns.length} align="center">
                    <Typography
                      variant="h5"
                      sx={{ fontFamily: "Montserrat, sans-serif",}}
                    >
                      No data available in the table
                    </Typography>
                  </TableCell>
                </TableRow>
              ) : (
                data.map((row, index) => (
                  <StyledTableRow
                    key={index}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    // onClick={() => handleRowClick(row.id)}
                  >
                    <TableCell sx={cellTextStyle}>{index + 1}</TableCell>
                    <TableCell
                      sx={{
                        ...cellTextStyle,
                        color: "#7901A3",
                        textDecoration: "underline",
                        cursor: "pointer",
                      }}
                      onClick={() => handleRowClick(row.id)}
                    >
                      {row.groupName}
                    </TableCell>
                    <TableCell sx={{ ...cellTextStyle }}>
                      {moment(row.createdAt).format("MMM DD, YYYY")}
                    </TableCell>
                    <TableCell sx={{ ...cellTextStyle }}>
                      {moment(row.updatedAt).format("MMM DD, YYYY")}
                    </TableCell>
                    <TableCell>
                      <div>
                        <Button
                          id="demo-customized-button"
                          aria-controls={
                            open ? "demo-customized-menu" : undefined
                          }
                          aria-haspopup="true"
                          aria-expanded={open ? "true" : undefined}
                          disableElevation
                          onClick={(event) => handleClick(event, row)}
                        >
                          <MoreVertIcon color="primary" />
                        </Button>
                        <StyledMenu
                          id="demo-customized-menu"
                          MenuListProps={{
                            "aria-labelledby": "demo-customized-button",
                          }}
                          anchorEl={anchorEl}
                          open={open}
                          onClose={handleClose}
                        >
                          <MenuItem
                            onClick={() => handleOpenEditApplicantModal()}
                            disableRipple
                          >
                            <EditIcon sx={{ marginRight: 1 }} />
                            Edit
                          </MenuItem>
                        </StyledMenu>
                      </div>
                    </TableCell>
                  </StyledTableRow>
                ))
              )}
            </TableBody>
          </Table>

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              marginBottom: 2,
            }}
          >
            <Typography
              sx={{
                fontFamily: "Montserrat, sans-serif",
                fontWeight: "600",
                fontSize: 12,
              }}
            >
              Rows Per Page:
            </Typography>
            <FormControl variant="standard">
              <Select
                value={selectedRowsPerPage}
                label="Rows Per Page"
                onChange={handleChangeRowsPerPageSelect}
                sx={{ marginLeft: 1 }}
              >
                <MenuItem value={5}>5</MenuItem>
                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={25}>25</MenuItem>
              </Select>
            </FormControl>
            <Typography
              sx={{
                fontFamily: "Montserrat, sans-serif",
                fontWeight: "600",
                fontSize: 12,
                marginLeft: 2,
              }}
            >
              {` ${page * rowsPerPage + 1} - ${Math.min(
                (page + 1) * rowsPerPage,
                totalCount
              )} of ${totalCount}`}
            </Typography>
            <CustomPaginationOutlined
              count={Math.ceil(totalCount / rowsPerPage)}
              page={page + 1}
              onChange={handleChangePageOutlined}
            />
          </Box>
        </TableContainer>
      </div>
      <RecruiterModal
        isOpen={isAddApplicantModalOpen}
        handleClose={handleCloseAddApplicantModal}
        title={"Add Group Form"}
        content={<AddGroup />}
      />
      <RecruiterModal
        isOpen={isEditApplicantModalOpen}
        handleClose={handleCloseEditApplicantModal}
        title={"Edit Group Form"}
        content={<EditGroup groupRequirements={selectedGroupRequirements} />}
      />
    </div>
  );
};

export default InternalRequirementsContent;
