import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import useTheme from "@mui/system/useTheme";

interface DialogComponentProps {
  open: boolean;
  handleClose: () => void;
}

const DialogComponent: React.FC<DialogComponentProps> = ({
  open,
  handleClose,
}) => {
  const theme = useTheme();

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      sx={{

        [theme.breakpoints.down("sm")]: {
          width: "90%",
        },
        [theme.breakpoints.up("md")]: {
          width: "50%",
        },
        [theme.breakpoints.up("lg")]: {
          width: "100%",
        },
      }}
      PaperProps={{ sx: { borderTopLeftRadius: "20px", borderTopRightRadius: '20px' } }}
    >
      <DialogTitle
        sx={{
          backgroundColor: "#7A01A4",
          color: "#FFF",
          padding: "15px",
          fontFamily: 'Inter, sans-serif',
          fontWeight: '800'
        }}
      >
 Data Privacy Statement 
      </DialogTitle>
      <DialogContent>

        <Typography
          sx={{    fontFamily: "Montserrat, sans-serif", textAlign: "justify",mb: 1, mt: 2, }}
        >
         I hereby declare under penalty of perjury that the foregoing
          declarations are true and correct to the best of my knowledge and
          belief.
        </Typography>
        <Typography
          sx={{    fontFamily: "Montserrat, sans-serif", textAlign: "justify", mb: 1 }}
        >
          I also understand that the accuracy and completeness of my
          declaration/s will be relied upon by the company in the event that
          employment is offered and that any misstatement or omission can cause
          my dismissal from employment.
        </Typography>
        <Typography
          sx={{    fontFamily: "Montserrat, sans-serif", textAlign: "justify", mb: 1 }}
        >
          I understand that it is necessary for Asticom Technology Inc. to
          confirm my identity and it may be necessary to obtain more detailed
          information from me in order to locate the correct information.
        </Typography>
        <Typography
          sx={{    fontFamily: "Montserrat, sans-serif", textAlign: "justify", mb: 1 }}
        >
          Under the terms of the Data Privacy Act of 2012 (R.A. No. 10173), I
          hereby request and consent for Asticom Technology Inc. to search the
          records identified above, to contact the relevant companies/staff, to
          inform me whether it holds any Personal Data as requested above.
        </Typography>
        <Typography
          sx={{    fontFamily: "Montserrat, sans-serif", textAlign: "justify", mb: 1 }}
        >
          I understand that Asticom Technology Inc. will process my sensitive
          personal data strictly in accordance with the Data Privacy Act of 2012
          and that in an emergency, my data may be disclosed to others without
          my consent for medical reasons or to protect my or another person’s
          vital interests.
        </Typography>
        <Typography
          sx={{    fontFamily: "Montserrat, sans-serif", textAlign: "justify" }}
        >
          I hereby give my consent to the disclosure of my personal data for the
          above purposes, to other persons as may be necessary.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleClose}
          sx={{
            width: "15vh",
            backgroundColor: "#7900A3",
            borderRadius: "20px",
            marginRight: "20px",
            marginBottom: "10px",
            color: "#FFF",
            "&:hover": {
              backgroundColor: "#590082",
            },
          }}
        >
         Confirm
        </Button>
        {/* <Button
          onClick={handleClose}
          sx={{
            width: "10vh",
            backgroundColor: "grey",
            borderRadius: "20px",
            color: "#7900A3",
            "&:hover": {
              backgroundColor: "#7900A3",
            },
          }}
        >
          No
        </Button> */}
      </DialogActions>
    </Dialog>
  );
};

export default DialogComponent;
