import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
  TextField,
  Button,
  Typography,
  TableCell,
  FormControl,
  Select,
  SelectChangeEvent,
  MenuItem,
} from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import CircularProgress from "@mui/material/CircularProgress";
import { Refresh as RefreshIcon } from "@mui/icons-material";
import SearchIcon from "@mui/icons-material/Search";
import { useNavigate } from "react-router-dom";
import StyledTableCell from "../../../components/TableStyle/StyledTableCell";
import StyledTableRow from "../../../components/TableStyle/StyledTableRow";
import CustomPaginationOutlined from "../../../components/CustomPagination";
import moment from "moment";
import Loader from "../../../components/Loader";

export interface Applicant {
  applicantLastName: string;
  applicantMiddleName: string;
  applicantFirstName: string;
  startDate: string;
  endDate: string;
  createdAt: string;
  applicantId: string;
  applicantStatus: string;
  prfId: string;
  offerStatus: string;
  jobOfferId: string;
  deadline: string;
  applicantRequirementId: string;
  completionPercentage: string;
  overallStatus: string;
  applicantEmail: string;
  subsidiary: string;
  jobTitle: string;
}

interface ApiResponse {
  result: Applicant[];
}

const AssignedApplicantContent = () => {
  const apiKey = process.env.REACT_APP_RECRUITMENT_API;
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const [selectedRowsPerPage, setSelectedRowsPerPage] = useState(10);

  const [data, setData] = useState<Applicant[]>([]);
  const [filters, setFilters] = useState({
    applicantLastName: "",
    applicantFirstName: "",
  });
  const [startDateFilter, setStartDateFilter] = useState<string | null>(null);
  const [endDateFilter, setEndDateFilter] = useState<string | null>(null);

  const columns = [
    { id: "jobId", label: "PRF ID" },
    { id: "firstName", label: "First Name" },
    { id: "lastName", label: "Last Name" },
    { id: "applicantEmail", label: "Email Address" },
    { id: "subsidiary", label: "Subsidiary" },
    { id: "jobTitle", label: "Job Title" },
    { id: "startDate", label: "Start Date" },
    { id: "deadline", label: "Deadline" },
    { id: "offerStatus", label: "Percentage" },
    { id: "applicantStatus", label: "Overall Status" },
  ];
  const [loading, setLoading] = useState(false);
  const [loadingFilter, setLoadingFilter] = useState(false);

  useEffect(() => {
    fetchData(page + 1, rowsPerPage);
  }, [page, rowsPerPage]);

  const fetchData = async (page: number, limit: number) => {
    try {
      setLoading(true);

      const accessToken = localStorage.getItem("accessToken");

      const response = await axios.post(
        `${apiKey}/api/recruitment/group-requirements`,
        {
          page,
          limit,
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      setData(response.data.result);  
      setTotalCount(response.data.total);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };
  const handleApplyFilter = async () => {
    try {
      setLoadingFilter(true);
      const accessToken = localStorage.getItem("accessToken");

      const nonEmptyFilters: Record<string, string> = {};
      Object.entries(filters).forEach(([key, value]) => {
        if (value !== "") {
          nonEmptyFilters[key] = value;
        }
      });

      const requestPayload: Record<string, any> = {
        page: 1,
        limit: rowsPerPage,
        ...(Object.keys(nonEmptyFilters).length > 0 && {
          filters: nonEmptyFilters,
        }),
      };

      if (startDateFilter && endDateFilter) {
        requestPayload.dateFilter = {
          field: "startDate",
          start: startDateFilter,
          end: endDateFilter,
        };
      }

      const response = await axios.post(
        `${apiKey}/api/recruitment/group-requirements`,
        requestPayload,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      setData(response.data.result);
      setTotalCount(response.data.total);
      setPage(0);
    } catch (error) {
      console.error("Error applying filter:", error);
    } finally {
      setLoadingFilter(false);
    }
  };

  const handleRefresh = () => {
    setFilters({
      applicantFirstName: "",
      applicantLastName: "",
    });

    setStartDateFilter(null);
    setEndDateFilter(null);

    fetchData(page + 1, rowsPerPage);
  };

  const handleFilterChange = (field: string, value: string) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [field]: value,
    }));
  };

  const handleRowClick = (applicant: Applicant) => {
    navigate(
      `/assigned-applicant/${applicant.applicantId}/${applicant.jobOfferId}`,
      { state: { applicant } }
    );
  };

  const handleChangeRowsPerPageSelect = (event: SelectChangeEvent<number>) => {
    const newRowsPerPage = Number(event.target.value);
    setSelectedRowsPerPage(newRowsPerPage);
    setRowsPerPage(newRowsPerPage);
    setPage(0);
  };

  const handleChangePageOutlined = (
    event: React.ChangeEvent<unknown>,
    newPage: number
  ) => {
    setPage(newPage - 1);
  };

  const cellTextStyle = {
    fontFamily: "Montserrat, sans-serif",
    fontWeight: "400",
    fontSize: "13px",
  };

  return (
    <div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          marginBottom: "10px",
          justifyContent: "space-between",
        }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <TextField
            label="First Name"
            variant="outlined"
            value={filters.applicantFirstName}
            onChange={(e) =>
              handleFilterChange("applicantFirstName", e.target.value)
            }
          />
          <TextField
            label="Last Name"
            variant="outlined"
            value={filters.applicantLastName}
            onChange={(e) =>
              handleFilterChange("applicantLastName", e.target.value)
            }
            sx={{ margin: "0 20px" }}
          />
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label="Start Date"
              value={startDateFilter}
              slotProps={{ textField: { variant: "outlined" } }}
              onChange={(newValue) => {
                if (newValue !== null && newValue !== "") {
                  const formattedDate = dayjs(newValue).format("YYYY-MM-DD");
                  setStartDateFilter(formattedDate);
                } else {
                  setStartDateFilter(newValue);
                }
              }}
            />
            <DatePicker
              label="End Date"
              value={endDateFilter}
              slotProps={{ textField: { variant: "outlined" } }}
              onChange={(newValue) => {
                if (newValue !== null && newValue !== "") {
                  const formattedDate = dayjs(newValue).format("YYYY-MM-DD");
                  setEndDateFilter(formattedDate);
                } else {
                  setEndDateFilter(newValue);
                }
              }}
              sx={{ margin: "0 20px" }}
            />
          </LocalizationProvider>
          <Button
            variant="contained"
            onClick={handleApplyFilter}
            disabled={loadingFilter}
            sx={{
              width: "25ch",
              backgroundColor: "#7901A3",
              "&:hover": {
                backgroundColor: "#7901A3",
              },
            }}
          >
            <SearchIcon />
            <div style={{ visibility: loadingFilter ? "hidden" : "visible" }}>
              Search
            </div>
            {loadingFilter && (
              <CircularProgress
                size={24}
                sx={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  marginTop: "-12px",
                  marginLeft: "-12px",
                }}
              />
            )}
          </Button>

          <Button
            variant="contained"
            onClick={handleRefresh}
            size="medium"
            sx={{
              ml: 1,
              backgroundColor: "#7901A3",
              "&:hover": {
                backgroundColor: "#7901A3",
              },
            }}
          >
            <RefreshIcon />
          </Button>
        </div>
      </div>

      <div style={{ display: "flex", justifyContent: "center" }}>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }}>
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <StyledTableCell
                    key={column.id}
                    sx={{
                      fontFamily: "Montserrat, sans-serif",
                      fontWeight: "800",
                    }}
                  >
                    {column.label}
                  </StyledTableCell>
                ))}
              </TableRow>
            </TableHead>

            <TableBody>
              {loading ? (
                <TableRow>
                  <TableCell colSpan={columns.length} align="center">
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        height: "100%", // Ensure that the container takes the full height
                      }}
                    >
                      <Loader/>
                    </Box>
                  </TableCell>
                </TableRow>
              ) : data.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={columns.length} align="center">
                    <Typography
                      variant="h5"
                      sx={{ fontFamily: "Montserrat, sans-serif", }}
                    >
                      No data available in the table
                    </Typography>
                  </TableCell>
                </TableRow>
              ) : (
                data.map((row, index) => (
                  <StyledTableRow
                    key={index}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    onClick={() => handleRowClick(row)}
                  >
                    <TableCell sx={cellTextStyle}>{row.prfId}</TableCell>
                    <TableCell sx={cellTextStyle}>
                      {row.applicantFirstName}
                    </TableCell>
                    <TableCell sx={cellTextStyle}>
                      {row.applicantLastName}
                    </TableCell>
                    <TableCell sx={cellTextStyle}>
                      {row.applicantEmail}
                    </TableCell>
                    <TableCell sx={cellTextStyle}>
                      {row.subsidiary.toUpperCase()}
                    </TableCell>
                    <TableCell sx={cellTextStyle}>
                      {row.jobTitle}
                    </TableCell>
                    <TableCell sx={cellTextStyle}>
                    {row.startDate && moment(row.startDate).format('MMM DD, YYYY')}
                    </TableCell>
                    <TableCell sx={cellTextStyle}>
                    {row.deadline && moment(row.deadline).format('MMM DD, YYYY')}
                    </TableCell>
                    <TableCell sx={cellTextStyle}>
                      {row.completionPercentage}%
                    </TableCell>
                    <TableCell sx={cellTextStyle}>
                      {row.overallStatus}
                    </TableCell>
                  </StyledTableRow>
                ))
              )}
            </TableBody>
          </Table>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              marginBottom: 2,
            }}
          >
            <Typography
              sx={{
                fontFamily: "Montserrat, sans-serif",
                fontWeight: "600",
                fontSize: 12,
              }}
            >
              Rows Per Page:
            </Typography>
            <FormControl variant="standard">
              <Select
                value={selectedRowsPerPage}
                label="Rows Per Page"
                onChange={handleChangeRowsPerPageSelect}
                sx={{ marginLeft: 1 }}
              >
                <MenuItem value={5}>5</MenuItem>
                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={25}>25</MenuItem>
              </Select>
            </FormControl>
            <Typography
              sx={{
                fontFamily: "Montserrat, sans-serif",
                fontWeight: "600",
                fontSize: 12,
                marginLeft: 2,
              }}
            >
              {` ${page * rowsPerPage + 1} - ${Math.min(
                (page + 1) * rowsPerPage,
                totalCount
              )} of ${totalCount}`}
            </Typography>
            <CustomPaginationOutlined
              count={Math.ceil(totalCount / rowsPerPage)}
              page={page + 1}
              onChange={handleChangePageOutlined}
            />
          </Box>
        </TableContainer>
      </div>
    </div>
  );
};

export default AssignedApplicantContent;
