import React, { useState } from "react";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import axios, { AxiosError } from "axios";
import { useNavigate } from "react-router-dom";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { IconButton, InputAdornment } from "@mui/material";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { GoogleLogin } from "@react-oauth/google";
import backgroundImage from "../../assets/images/bgLogin.png";
import CircularProgress from "@mui/material/CircularProgress";
import { handleKeyPress } from "../../utilities/keyPressUtils";
import { Helmet } from "react-helmet";
import { styled } from "@mui/system";

import Header from "../../components/Header";
import agcLogo from "../../assets/images/agcLogo.png";
import onboardingLogo from "../../assets/images/onBoardingLogo.png";
import DialogComponent from "../../components/DialogComponent";

const BackgroundContainer = styled(Box)`
  height: 100vh;
  background: url(${backgroundImage}) center/cover no-repeat;
  display: flex;
  align-items: center;
`;

const Login: React.FC = () => {
  const apiKeyUser = process.env.REACT_APP_USER_SERVICE_API;
  const [emailAdd, setEmailadd] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  // Error
  const [emailAddError, setEmailaddError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [errorMessage, setErrorMessage] = useState({ message: "" });
  const [googleErrorMessage, setGoogleErrorMessage] = useState({ message: "" });
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingGoogle, setIsLoadingGoogle] = useState(false);

  const emailAddPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  const [openDialog, setOpenDialog] = useState(false);

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleLogin = async () => {
    setEmailaddError("");
    setPasswordError("");
    setIsLoading(true);

    if (!emailAdd) {
      setEmailaddError("Email Address is required");
      setIsLoading(false);
      return;
    }

    if (!emailAdd.match(emailAddPattern)) {
      setEmailaddError("Invalid Email Address");
      setIsLoading(false);
      return;
    }

    if (!password) {
      setPasswordError("Password is required");
      setIsLoading(false);
      return;
    }

    try {
      const response = await axios.post(`${apiKeyUser}/api/user-service/login`, {
        emailAddress: emailAdd,
        password: password,
      });

      if (response.status === 200) {
        localStorage.setItem("emailAddress", emailAdd);
        navigate("/verifyotp");
        setErrorMessage({ message: "You are now logged in!" });
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const axiosError: AxiosError = error;

        if (axiosError.response) {
          const status = axiosError.response.status;

          if (status === 401) {
            setErrorMessage({
              message:
                "Unauthorized. The provided password is incorrect or Not Verified User.",
            });
          } else if (status === 404) {
            setErrorMessage({
              message: "The user was not found.",
            });
          } else if (status === 422) {
            setErrorMessage({
              message:
                "Unprocessable Entity. If you didn't follow the required fields or added an excess field",
            });
          } else if (status === 500) {
            setErrorMessage({
              message:
                "Internal Server Error. An unexpected error occurred during login.",
            });
          } else {
            console.error("Login failed with an unknown error:", axiosError);
          }
        } else {
          console.error("Login failed with an unknown error:", axiosError);
        }
      } else {
        console.error("Login failed with an unknown error:", error);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleLoginGoogle = async (token: string) => {
    try {
      setIsLoadingGoogle(true);
      const response = await axios.post(
        `${apiKeyUser}/api/user-service/google-login`,
        {
          token: token,
        }
      );
      if (response.status === 200) {
        localStorage.setItem("accessToken", response.data.result.token);
        if (response.data.result.isPasswordSet === "not-set")
          navigate("/change-password", { replace: true });
        else {
          if (response.data.result.userType === "Recruiter") {
            localStorage.setItem(
              "RecruiterType",
              response.data.result.userType
            );
            navigate("/recruiter-dashboard", { replace: true });
          } else if (response.data.result.userType === "Selected Applicant") {
            localStorage.setItem(
              "ApplicantType",
              response.data.result.userType
            );
            navigate("/home", { replace: true });
          } else if (response.data.result.userType === "System Admin") {
            localStorage.setItem(
              "SystemAdminType",
              response.data.result.userType
            );

            navigate("/recruiter-list", { replace: true });
          } else if (response.data.result.userType === "RECOPS Head") {
            localStorage.setItem(
              "RecOpsHeadType",
              response.data.result.userType
            );
            navigate("/recops-dashboard", { replace: true });
          } else if (response.data.result.userType === "Onboarding Team") {
            localStorage.setItem(
              "OnboardingType",
              response.data.result.userType
            );
            navigate("/assigned-applicant", { replace: true });
          }
        }
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const axiosError: AxiosError = error;

        if (axiosError.response) {
          const status = axiosError.response.status;

          if (status === 401) {
            setGoogleErrorMessage({
              message:
                "Unauthorized. The provided password is incorrect or Not Verified User.",
            });
          } else if (status === 404) {
            setGoogleErrorMessage({
              message: "The user was not found.",
            });
          } else if (status === 422) {
            setGoogleErrorMessage({
              message:
                "Unprocessable Entity. If you didn't follow the required fields or added an excess field",
            });
          } else if (status === 500) {
            setGoogleErrorMessage({
              message: "Internal Server Error",
            });
          }
        }
      } else {
        console.error("Login failed with an unknown error:", error);
      }
    } finally {
      setIsLoadingGoogle(false);
    }
  };

  const handleForgotpassword = () => {
    navigate("/forgotpassword");
  };

  return (
    <>
      <Helmet>
        <title>Onboard Hub | Login</title>
      </Helmet>

      <React.Fragment>
        <CssBaseline />
        <Header />
        <BackgroundContainer>
          {/* <Header /> */}
          <Grid container>
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Paper
                variant="outlined"
                sx={{
                  p: { md: 2 },
                  borderRadius: { md: "20px" },
                  boxShadow: 3,
                }}
              >
                <Box
                  component="form"
                  sx={{
                    my: { xs: 12, sm: 0, md: 4 },
                    mx: 4,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <img
                    src={onboardingLogo}
                    alt="onboradingLogo"
                    style={{ width: "120px" }}
                  />

                  <TextField
                    value={emailAdd}
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    label="Email Address"
                    name="email"
                    autoComplete="off"
                    autoFocus
                    error={!!emailAddError}
                    helperText={emailAddError}
                    onChange={(e) => {
                      setEmailadd(e.target.value);
                      setEmailaddError("");
                      setErrorMessage({ message: "" });
                    }}
                    inputProps={{ maxLength: 64 }}
                    onKeyPress={(e) => handleKeyPress(e, handleLogin)}
                  />
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    name="password"
                    label="Password"
                    type={showPassword ? "text" : "password"}
                    value={password}
                    onChange={(e) => {
                      setPassword(e.target.value);
                      setPasswordError("");
                      setErrorMessage({ message: "" });
                    }}
                    id="password"
                    error={!!passwordError}
                    helperText={passwordError}
                    className="custom-text-input"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={togglePasswordVisibility}
                            edge="end"
                          >
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    inputProps={{ maxLength: 64 }}
                    onKeyPress={(e) => handleKeyPress(e, handleLogin)}
                  />

                  <Grid item xs>
                    {errorMessage.message && (
                      <Typography
                        variant="body2"
                        sx={{
                          fontFamily: "Montserrat, sans-serif",
                          color: "#d32f2f",
                          mb: 2,
                        }}
                      >
                        {errorMessage.message}
                      </Typography>
                    )}
                  </Grid>

                  <Typography
                    variant="subtitle2"
                    sx={{
                      fontFamily: "Montserrat, sans-serif",
                      color: "#3D005D",
                      fontWeight: "400",
                    }}
                  >
                    By proceeding, you agree to our terms and have read our{" "}
                    <br />
                    <span
                      onClick={handleOpenDialog}
                      style={{
                        fontFamily: "Montserrat, sans-serif",
                        color: "#3D005D",
                        fontWeight: "800",
                        cursor: "pointer",
                        textDecoration: "underline",
                      }}
                    >
                      Data Privacy Statement.
                    </span>
                  </Typography>

                  <Button
                    fullWidth
                    sx={{
                      mt: 3,
                      mb: 2,
                      backgroundColor: "#3D005D",
                      color: "#FFF",
                      "&:hover": {
                        backgroundColor: "#2A003E",
                      },
                    }}
                    onClick={handleLogin}
                    disabled={isLoading}
                  >
                    {isLoading ? (
                      <CircularProgress size={24} sx={{ color: "#FFF" }} />
                    ) : (
                      "Login"
                    )}
                  </Button>
                  <Grid container>
                    <Grid item xs>
                      <Typography
                        onClick={handleForgotpassword}
                        variant="body2"
                        sx={{
                          color: "3D005D",
                          fontFamily: "Montserrat, sans-serif",
                          fontWeight: "400",
                          textDecoration: "underline",
                        }}
                      >
                        Forgot password?
                      </Typography>
                    </Grid>
                  </Grid>

                  <Grid
                    sx={{
                      mt: 3,
                      mb: 2,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <GoogleOAuthProvider
                      clientId={`${process.env.REACT_APP_GOOGLE_API_TOKEN}`}
                    >
                      {isLoadingGoogle ? (
                        <CircularProgress size={24} color="primary" />
                      ) : (
                        <>
                          <GoogleLogin
                            onSuccess={(credentialResponse) => {
                              const token = credentialResponse.credential ?? "";
                              handleLoginGoogle(token);
                            }}
                            onError={() => {
                              console.log("Login Failed");
                            }}
                          />
                        </>
                      )}
                    </GoogleOAuthProvider>
                  </Grid>

                  {googleErrorMessage && (
                    <Typography
                      variant="body2"
                      sx={{
                        fontFamily: "Montserrat, sans-serif",
                        color: "#d32f2f",
                        textAlign: "center",
                      }}
                    >
                      {googleErrorMessage.message}
                    </Typography>
                  )}
                </Box>
              </Paper>
            </Grid>
          </Grid>
        </BackgroundContainer>
      </React.Fragment>

      <DialogComponent open={openDialog} handleClose={handleCloseDialog} />
    </>
  );
};
export default Login;
