import React, { useState, ChangeEvent } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import TextInput from "../../../components/TextInput";
import ButtonField from "../../../components/Button";
import Typography from "@mui/material/Typography";
import axios, { AxiosError } from "axios";

import CircularProgress from "@mui/material/CircularProgress";
import CustomModal from "../../../components/Modal";

export default function AddGroup() {
const apiKey = process.env.REACT_APP_RECRUITMENT_API
  const [formData, setFormData] = useState({
    groupName: "",
  });

  const [formErrors, setFormErrors] = useState({
    groupName: "",
  });

  const [errorMessage, setErrorMessage] = useState({ message: "" });
  const [isLoading, setIsLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleAddGroup = async () => {
    setIsLoading(true);

    const requiredFields: (keyof typeof formData)[] = ["groupName"];

    const emptyFields: string[] = [];
    for (const field of requiredFields) {
      if (!formData[field]) {
        emptyFields.push(field);
      }
    }

    if (emptyFields.length > 0) {
      const errorMessages: Record<string, string> = {};
      emptyFields.forEach((field) => {
        errorMessages[field] = "This field is required";
      });
      setFormErrors((prevErrors) => ({ ...prevErrors, ...errorMessages }));
      setIsLoading(false);
      return;
    }

    const token = localStorage.getItem("accessToken");

    try {
      const response = await axios.post(
        `${apiKey}/api/recops-head/special-group`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        setIsModalOpen(true);
      }
    } catch (error) {
      console.log(error);
      if (axios.isAxiosError(error)) {
        const axiosError: AxiosError = error;

        if (axiosError.response) {
          const status = axiosError.response.status;

          if (status === 401) {
            setErrorMessage({
              message: "Unauthorized. Please provide a valid Bearer Token.",
            });
          } else if (status === 409) {
            setErrorMessage({
              message: "User already exists with the provided email address.",
            });
          } else if (status === 422) {
            setErrorMessage({
              message: 'Unprocessable Entity. "addressee" is not allowed.',
            });
          } else if (status === 500) {
            setErrorMessage({
              message: "Internal Server Error.",
            });
          }
        }
      } else {
        console.error("Internal Server Error", error);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleGroupNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      groupName: value,
    }));
    // Optionally, you can clear the error when the user starts typing
    setFormErrors((prevErrors) => ({ ...prevErrors, groupName: "" }));
  };

  return (
    <Box
      component="form"
      sx={{
        "& .MuiTextField-root": {width: 400},
      }}
      noValidate
      autoComplete="off"
    >
      <Grid container>
     
          <TextInput
            required
            id="outlined-required"
            label="Group Name"
            value={formData.groupName}
            onChange={handleGroupNameChange}
            error={!!formErrors.groupName}
            helperText={formErrors.groupName}
            inputProps={{
              maxLength: 256,
            }}
            fullWidth
          />

      </Grid>

      {errorMessage.message && (
        <Typography
          variant="body2"
          sx={{
            fontFamily: 'Montserrat, sans-serif',
            color: "#d32f2f",
            ml: 1,
            mt: 1,
          }}
        >
          {errorMessage.message}
        </Typography>
      )}
      <ButtonField
        variant="contained"
        sx={{
          width: "30ch",
          mt: 3,
          mb: 2,
          backgroundColor: isLoading ? "#666666" : '#7901A3',
          "&:hover": {
            backgroundColor: "#7901A3",
          },
        }}
        onClick={handleAddGroup}
      >
        {isLoading ? (
          <CircularProgress size={25} style={{ color: "white" }} />
        ) : (
          "Add Group"
        )}
      </ButtonField>
      <CustomModal
        open={isModalOpen}
        onClose={() => {
          setIsModalOpen(false);
          window.location.reload(); // Reload the page
        }}
        title={"Successfully Added"}
        message={"The group has been successfully added."}
        success={true}
      />
    </Box>
  );
}
