import React from 'react'
import CustomDrawer from '../../../layouts/drawer/CustomDrawer'
import {Helmet} from "react-helmet";
import DashBoardContent from './DashBoardContent';

const DashBoard = () => {
  
  return (
    <>
         <Helmet>
        <title>Onboard Hub | Dashboard</title>
      </Helmet>
     <CustomDrawer content={<DashBoardContent/>} title='Dashboard' />
    </>
  )
}

export default DashBoard;