import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
  TextField,
  Button,
  Typography,
  Select,
  MenuItem,
  SelectChangeEvent,
  FormControl,
  Divider,
  TableCell,
} from "@mui/material";
import { Edit as EditIcon } from "@mui/icons-material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import moment from "moment";
import { Add as AddIcon } from "@mui/icons-material";

import CustomPaginationOutlined from "../../../components/CustomPagination";
import dayjs from "dayjs";
import CircularProgress from "@mui/material/CircularProgress";
import { Refresh as RefreshIcon } from "@mui/icons-material";

import SearchIcon from "@mui/icons-material/Search";
import CustomModal from "../../../components/CustomModal";
import Menu, { MenuProps } from "@mui/material/Menu";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import OfferIcon from "@mui/icons-material/EmojiEvents";
import InfoIcon from "@mui/icons-material/Info";
import StyledMenu from "../../../components/TableStyle/StyledMenu";
import StyledTableCell from "../../../components/TableStyle/StyledTableCell";
import StyledTableRow from "../../../components/TableStyle/StyledTableRow";

import AssignmentReturnedIcon from "@mui/icons-material/AssignmentReturned";
import Loader from "../../../components/Loader";
import AddApplicantContent from "../../Recruiter/ApplicantList/AddApplicant"
import EditApplicant from "../../Recruiter/ApplicantList/EditApplicant";
import JobOfferApplicant from "../../Recruiter/ApplicantList/JobOfferApplicant";
import AddOnboarding from "../../Recruiter/ApplicantList/AddOnboarding";
import AddApplicantModal from "../../Recruiter/ApplicantList/ApplicantModal";

export interface Applicant {
  jobId: string;
  lastName: string;
  middleName: string;
  firstName: string;
  contactNumber: string;
  applicantEmail: string;
  subsidiary: string;
  talentSegment: string;
  jobTitle: string;
  startDate: string;
  endDate: string;
  isVerified: string;
  createdAt: string;
  applicantId: string;
  applicantStatus: string;
  prfId: string;
  offerStatus: string;
  withdrawStatus: string;
  jobOfferId: string;
  deadline: string;
  applicantSubStatus: string;
  onBoardingId: string;
}

interface ApiResponse {
  result: Applicant[];
}

const SelectedCandidatesContent = () => {
  const apiKey = process.env.REACT_APP_RECRUITMENT_API;
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [data, setData] = useState<Applicant[]>([]);
  const [totalCount, setTotalCount] = useState(0);
  const [filters, setFilters] = useState({
    firstName: "",
    lastName: "",
  });

  const [startDateFilter, setStartDateFilter] = useState<string | null>(null);
  const [endDateFilter, setEndDateFilter] = useState<string | null>(null);
  const [selectedRowsPerPage, setSelectedRowsPerPage] = useState(10);
  const [selectedApplicant, setSelectedApplicant] = useState<Applicant | null>(
    null
  );
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const columns = [
    { id: "jobId", label: "PRF ID" },
    { id: "jobTitle", label: "Job Title" },
    { id: "firstName", label: "First Name" },
    { id: "lastName", label: "Last Name" },
    { id: "applicantEmail", label: "Email Address" },
    { id: "startDate", label: "Start Date" },
    { id: "deadline", label: "Deadline Date" },
    { id: "subsidiary", label: "Subsidiary" },
    { id: "offerStatus", label: "Job Offer Status" },
    { id: "applicantStatus", label: "Status" },
    // { id: "action", label: "Actions" },
  ];

  const [isAddApplicantModalOpen, setIsAddApplicantModalOpen] = useState(false);
  const [isEditApplicantModalOpen, setIsEditApplicantModalOpen] =
    useState(false);
  const [isJobOfferApplicantModalOpen, setIsJobOfferApplicantModalOpen] =
    useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingFilter, setLoadingFilter] = useState(false);
  const open = Boolean(anchorEl);

  const [isAssigneeOnboardingModalOpen, setIsAssigneeOnboardingModalOpen] =
    useState(false);

  const recOpsHeadType = localStorage.getItem("RecOpsHeadType");

  useEffect(() => {
    fetchData(page + 1, rowsPerPage);
  }, [page, rowsPerPage]);

  const fetchData = async (page: number, limit: number) => {
    try {
      setLoading(true);

      const accessToken = localStorage.getItem("accessToken");
  
      const response = await axios.post(
        `${apiKey}/api/recruitment/applicant-list`,
        {
          page,
          limit,
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      setData(response.data.result)
      setTotalCount(response.data.total);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };
  const handleApplyFilter = async () => {
    try {
      setLoadingFilter(true);
      const accessToken = localStorage.getItem("accessToken");

      const nonEmptyFilters: Record<string, string> = {};
      Object.entries(filters).forEach(([key, value]) => {
        if (value !== "") {
          nonEmptyFilters[key] = value;
        }
      });

      const requestPayload: Record<string, any> = {
        page: 1,
        limit: rowsPerPage,
        ...(Object.keys(nonEmptyFilters).length > 0 && {
          filters: nonEmptyFilters,
        }),
      };

      if (startDateFilter && endDateFilter) {
        requestPayload.dateFilter = {
          field: "startDate",
          start: startDateFilter,
          end: endDateFilter,
        };
      }

      const response = await axios.post(
        `${apiKey}/api/recruitment/applicant-list`,
        requestPayload,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      setData(response.data.result);
      setTotalCount(response.data.total);
      setPage(0);
    } catch (error) {
      console.error("Error applying filter:", error);
    } finally {
      setLoadingFilter(false);
    }
  };

  const handleRefresh = () => {
    setFilters({
      firstName: "",
      lastName: "",
    });

    setStartDateFilter(null);
    setEndDateFilter(null);

    fetchData(page + 1, rowsPerPage);
  };

  const handleClick = (
    event: React.MouseEvent<HTMLElement>,
    applicant: Applicant
  ) => {
    setAnchorEl(event.currentTarget);
    setSelectedApplicant(applicant);
  };

  const handleOpenAddApplicantModal = () => {
    setIsAddApplicantModalOpen(true);
  };

  const handleCloseAddApplicantModal = () => {
    setIsAddApplicantModalOpen(false);
  };

  const handleOpenEditApplicantModal = () => {
    setIsEditApplicantModalOpen(true);
  };

  const handleOpenJobOfferApplicantModal = () => {
    setIsJobOfferApplicantModalOpen(true);
  };
  const handleOpenAssigneeOnboardingModal = () => {
    setIsAssigneeOnboardingModalOpen(true);
  };

  const handleCloseEditApplicantModal = () => {
    setIsEditApplicantModalOpen(false);
  };
  const handleCloseJobOfferApplicantModal = () => {
    setIsJobOfferApplicantModalOpen(false);
  };
  const handleCloseAssigneeOnboardingModal = () => {
    setIsAssigneeOnboardingModalOpen(false);
  };

  const handleFilterChange = (field: string, value: string) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [field]: value,
    }));
  };

  const handleChangeRowsPerPageSelect = (event: SelectChangeEvent<number>) => {
    const newRowsPerPage = Number(event.target.value);
    setSelectedRowsPerPage(newRowsPerPage);
    setRowsPerPage(newRowsPerPage);
    setPage(0);
  };

  const handleChangePageOutlined = (
    event: React.ChangeEvent<unknown>,
    newPage: number
  ) => {
    setPage(newPage - 1);
  };

  const cellTextStyle = {
    fontFamily: "Montserrat, sans-serif",
    fontWeight: "400",
    fontSize: "13px",
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          marginBottom: "10px",
          justifyContent: "space-between",
        }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <TextField
            label="First Name"
            variant="outlined"
            value={filters.firstName}
            onChange={(e) => handleFilterChange("firstName", e.target.value)}
            style={{ width: "200px" }}
          />
          <TextField
            label="Last Name"
            variant="outlined"
            value={filters.lastName}
            onChange={(e) => handleFilterChange("lastName", e.target.value)}
            sx={{ margin: "0 20px", width: "200px" }}
          />
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label="Start Date"
              value={startDateFilter}
              slotProps={{
                textField: { variant: "outlined", sx: { width: "200px" } },
              }}
              onChange={(newValue) => {
                if (newValue !== null && newValue !== "") {
                  const formattedDate = dayjs(newValue).format("YYYY-MM-DD");
                  setStartDateFilter(formattedDate);
                } else {
                  setStartDateFilter(newValue);
                }
              }}
            />
            <DatePicker
              label="End Date"
              value={endDateFilter}
              slotProps={{
                textField: {
                  variant: "outlined",
                  sx: { width: "200px", margin: "0 20px" },
                },
              }}
              onChange={(newValue) => {
                if (newValue !== null && newValue !== "") {
                  const formattedDate = dayjs(newValue).format("YYYY-MM-DD");
                  setEndDateFilter(formattedDate);
                } else {
                  setEndDateFilter(newValue);
                }
              }}
              sx={{ margin: "0 20px" }}
            />
          </LocalizationProvider>
          <Button
            variant="contained"
            onClick={handleApplyFilter}
            disabled={loadingFilter}
            sx={{
              width: "25ch",
              backgroundColor: "#7901A3",
              "&:hover": {
                backgroundColor: "#7901A3",
              },
            }}
          >
            <SearchIcon />
            <div style={{ visibility: loadingFilter ? "hidden" : "visible" }}>
              Search
            </div>
            {loadingFilter && <CircularProgress sx={{ color: "#FFF" }} />}
          </Button>

          <Button
            variant="contained"
            onClick={handleRefresh}
            size="medium"
            sx={{
              ml: 1,
              backgroundColor: "#7901A3",
              "&:hover": {
                backgroundColor: "#7901A3",
              },
            }}
          >
            <RefreshIcon />
          </Button>
        </div>
        {/* <Button
          variant="contained"
          onClick={() => {}}
          startIcon={<AddIcon />}
          sx={{
            marginLeft: 2,
            width: "25ch",
            backgroundColor: "#7901A3",
            "&:hover": {
              backgroundColor: "#7901A3",
            },
          }}
        >
          Bulk Upload
        </Button> */}
        {!recOpsHeadType && (
          <Button
            variant="contained"
            onClick={handleOpenAddApplicantModal}
            startIcon={<AddIcon />}
            sx={{
              marginLeft: 2,
              width: "30ch",
              backgroundColor: "#7901A3",
              "&:hover": {
                backgroundColor: "#7901A3",
              },
            }}
          >
            Add Applicant
          </Button>
        )}
      </div>

      <div style={{ display: "flex", justifyContent: "center" }}>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }}>
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <StyledTableCell
                    key={column.id}
                    sx={{
                      fontFamily: "Montserrat, sans-serif",
                      fontWeight: "800",
                    }}
                  >
                    {column.label}
                  </StyledTableCell>
                ))}
              </TableRow>
            </TableHead>

            <TableBody>
              {loading ? (
                <TableRow>
                  <TableCell colSpan={columns.length} align="center">
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        height: "100%", // Ensure that the container takes the full height
                      }}
                    >
                      <Loader />
                    </Box>
                  </TableCell>
                </TableRow>
              ) : data.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={columns.length} align="center">
                    <Typography
                      variant="h5"
                      sx={{ fontFamily: "Montserrat, sans-serif" }}
                    >
                      No data available in the table
                    </Typography>
                  </TableCell>
                </TableRow>
              ) : (
                data.map((row, index) => (
                  <StyledTableRow
                    key={index}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell sx={cellTextStyle}>{row.prfId}</TableCell>
                    <TableCell sx={cellTextStyle}>{row.jobTitle}</TableCell>
                    <TableCell sx={cellTextStyle}>{row.firstName}</TableCell>
                    <TableCell sx={cellTextStyle}>{row.lastName}</TableCell>
                    <TableCell sx={cellTextStyle}>
                      {row.applicantEmail}
                    </TableCell>

                    <TableCell sx={{ ...cellTextStyle }}>
                      {moment(row.startDate).format("MMM DD, YYYY")}
                    </TableCell>
                    <TableCell sx={{ ...cellTextStyle }}>
                      {moment(row.deadline).format("MMM DD, YYYY")}
                    </TableCell>
                    <TableCell sx={cellTextStyle}>
                      {row.subsidiary.toUpperCase()}
                    </TableCell>
                    <TableCell sx={cellTextStyle}>{row.offerStatus}</TableCell>
                    <TableCell sx={cellTextStyle}>
                      {row.applicantStatus}
                    </TableCell>
     
                  </StyledTableRow>
                ))
              )}
            </TableBody>
          </Table>

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              marginBottom: 2,
              mt: 2,
            }}
          >
            <Typography
              sx={{
                fontFamily: "Montserrat, sans-serif",
                fontWeight: "600",
                fontSize: 12,
              }}
            >
              Rows Per Page:
            </Typography>
            <FormControl variant="standard">
              <Select
                value={selectedRowsPerPage}
                label="Rows Per Page"
                onChange={handleChangeRowsPerPageSelect}
                sx={{ marginLeft: 1 }}
              >
                <MenuItem value={5}>5</MenuItem>
                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={25}>25</MenuItem>
              </Select>
            </FormControl>
            <Typography
              sx={{
                fontFamily: "Montserrat, sans-serif",
                fontWeight: "600",
                fontSize: 12,
                marginLeft: 2,
              }}
            >
              {` ${page * rowsPerPage + 1} - ${Math.min(
                (page + 1) * rowsPerPage,
                totalCount
              )} of ${totalCount}`}
            </Typography>
            <CustomPaginationOutlined
              count={Math.ceil(totalCount / rowsPerPage)}
              page={page + 1}
              onChange={handleChangePageOutlined}
            />
          </Box>
        </TableContainer>
        <AddApplicantModal
          isOpen={isAddApplicantModalOpen}
          handleClose={handleCloseAddApplicantModal}
          title={"Applicant Form"}
          content={<AddApplicantContent />}
        />
        <AddApplicantModal
          isOpen={isEditApplicantModalOpen}
          handleClose={handleCloseEditApplicantModal}
          title={"Edit Applicant Form"}
          content={<EditApplicant applicant={selectedApplicant} />}
        />
        <CustomModal
          isOpen={isJobOfferApplicantModalOpen}
          handleClose={handleCloseJobOfferApplicantModal}
          content={<JobOfferApplicant applicant={selectedApplicant} />}
        />
        <AddApplicantModal
          isOpen={isAssigneeOnboardingModalOpen}
          handleClose={handleCloseAssigneeOnboardingModal}
          title={"Assign Onboarding Form"}
          content={<AddOnboarding applicant={selectedApplicant} />}
        />
      </div>
    </div>
  );
};

export default SelectedCandidatesContent;
